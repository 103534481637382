import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./styles";

const Paginate = ({ onPageChange, currentPage }) => {
  let numberOfPages = useSelector(
    (state) => state.notifications?.numberOfPages
  );
  const handleChange = (event, page) => {
    page === currentPage ? console.log("") : onPageChange(page);
  };
  numberOfPages = numberOfPages ? numberOfPages : 1;
  const classes = useStyles();

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={currentPage}
      onChange={handleChange}
      variant="outlined"
      color="secondary"
      renderItem={(item) => <PaginationItem component="button" {...item} />}
    />
  );
};

export default Paginate;
