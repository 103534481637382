import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  cardFirst: {
    minHeight: "20rem",
    background:
      "linear-gradient(90deg, rgba(236,98,98,1) 0%, rgba(9,9,121,1) 100%, rgba(0,212,255,1) 100%)",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      padding: "0.5rem",
      flexDirection: "column",
    },
  },
  card: {
    display: "flex",
    alignItems: "left",
    borderRadius: "15px",
    position: "relative",
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
  },
  supportCard: {
    padding: "10px",
    border: "1px solid",
    borderRadius: "5px",
    borderColor: "rgba(236,98,98,1)",
  },
  supportAsk: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  supportTitle: {
    fontSize: "30px",
  },
  supportText: {
    fontSize: "16px",
  },
  supportTextName: {
    fontWeight: "bold",
  },
  supportImg: {
    maxWidth: "90%",
    maxHeight: "300px",
    "@media (min-width: 1200px)": {
      maxHeight: "450px",
    },
    borderRadius: "20px",
    margin: "0px auto 8px auto",
  },
  rangs: {
    display: "flex",
    flexDirection: "column",
    background:
      "linear-gradient(90deg, rgba(236,98,98,1) 0%, rgba(9,9,121,1) 100%, rgba(0,212,255,1) 100%)",
    color: "rgba(255,255,255,1)",
    borderRadius: "15px",
    padding: "5px 1rem",
    marginBottom: "1.5rem",
  },
  rangsWrap: {
    display: "flex",
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  rangsDevelopment: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "30%",
    margin: "5px 0 5px 0",
    padding: "5px 0",
    "@media (max-width: 1100px)": {
      width: "45%",
    },
    "@media (max-width: 800px)": {
      width: "100%",
    },
  },
  rangsAuthorCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "50%",
    margin: "5px 0 5px 0",
    padding: "5px 0",
    "@media (max-width: 1100px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      width: "100%",
    },
  },
  rangsButton: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    margin: "5px 0 5px auto",
    backgroundColor: "rgba(255,255,255,1)",
    justifyContent: "space-around",
    "@media (max-width: 800px)": {
      width: "100%",
    },
    "@media (min-width: 1300px)": {
      width: "40%",
    },
    "&:hover, &:focus": {
      backgroundColor: "rgba(220,220,220,1)",
    },
  },
  rangsTitle: {
    fontSize: "30px",
  },
  boostyImg: {
    maxHeight: "2rem",
  },
  quotationImg: {
    maxHeight: "1.5rem",
  },
  moneyImg: {
    maxHeight: "2rem",
    margin: "auto 0",
  },
  rangsButtonText: {
    margin: "auto 0",
    fontWeight: "bold",
  },
  rangsAuthor: {
    margin: "auto 10px",
    fontWeight: "bold",
  },
}));
