import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	gridContainer: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column-reverse",
		},
	},
	flex: {
		display: "flex",
		alignItems: "center",
	},
	pagination: {
		borderRadius: "15px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		marginTop: "10px",
		padding: "4px",
	},
	searchContainerText: {
		margin: "5px 0 10px 0",
	},
}));
