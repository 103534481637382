import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		borderRadius: "20px",
		backgroundColor: "rgb(9,9,121)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	width100: {
		width: "100%",
	},
	margin0: {
		margin: 0,
	},
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		borderRadius: "15px",
		position: "relative",
		borderColor: "rgba(236,98,98,1)",
		border: "solid 1px",
	},
	successText: {
		textAlign: "center",
	},
}));
