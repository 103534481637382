import React from "react";
import { Typography, Card, Avatar, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom"; // Import Link
import useStyles from "./styles";

const PostProfile = ({ profile }) => {
	const classes = useStyles();

	return (
		<Card className={classes.profileCard}>
			<Link to={`/user/${profile.login}`} className={classes.profileLink}>
				<div className={classes.profileTop}>
					<Avatar
						className={classes.profilePicture}
						alt={profile.name}
						src={profile.picture}
					>
						{profile.name ? profile.name.charAt(0) : ""}
					</Avatar>
					<div className={classes.profileName}>
						<Typography variant="h6" className={classes.profileNameText}>
							{profile.name}
						</Typography>
						<Typography className={classes.profileNameLogin}>
							@{profile.login}
						</Typography>
					</div>
					{profile?.userType && (
						<>
							<Hidden smUp>
								<Typography className={classes.adminSponsorLabel}>
									{profile?.userType === "admin"
										? "Админ"
										: profile?.userType === "sponsor"
										? "Спонсор"
										: profile?.userType === "traveler"
										? "Путешественник"
										: profile?.userType === "author"
										? "Автор"
										: ""}
								</Typography>
							</Hidden>
							<Hidden xsDown>
								<Typography className={classes.adminSponsorLabel}>
									{profile?.userType === "admin"
										? "Администратор"
										: profile?.userType === "sponsor"
										? "Спонсор"
										: profile?.userType === "traveler"
										? "Путешественник"
										: profile?.userType === "author"
										? "Автор"
										: ""}
								</Typography>
							</Hidden>
						</>
					)}
				</div>
				<div>
					<div className={classes.numberReportsFlex}>
						<Typography className={classes.profileNameText}>
							Количество отчетов:
						</Typography>
						<div className={classes.numberReportsBox}>
							<Typography>{profile?.reportsId.length}</Typography>
						</div>
					</div>
					{profile?.reportsId.length > 0 ? (
						<div className={classes.numberReportsFlex}>
							<Typography className={classes.profileNameText}>
								Отчеты о странах:
							</Typography>
							{profile?.countriesReportList.length > 0
								? profile?.countriesReportList.map((c) => (
										<Typography>{c.split(" ")[0]}</Typography>
								  ))
								: null}
						</div>
					) : null}
				</div>
			</Link>
		</Card>
	);
};

export default PostProfile;
