import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    border: "1px solid rgba(236,98,98,1)",
    borderRadius: "15px",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "rgba(236,98,98,1)",
  },
  buttonPrimary: {
    borderRadius: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    margin: "7px 0",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  textEmail: {
    textAlign: "center",
    marginBottom: "10px",
  },
}));
