import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PostLegend from "./PostLegend/PostLegend";
import PostUpdate from "./PostUpdate/PostUpdate";
import Calendar from "../Calendar/Calendar";
import { useLocation, useHistory } from "react-router-dom";
import {
	Typography,
	Button,
	CardMedia,
	Paper,
	Grid,
	Modal,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { batch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import PostComment from "./PostComment/PostComment";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import parse from "html-react-parser";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import SelectGeo from "../Maps/SelectGeo/SelectGeo";
import RoomIcon from "@material-ui/icons/Room";
import { getPlace } from "../../actions/place.js";

import useStyles from "./styles";
import {
	getPostContent,
	likePostReport,
	deletePostReport,
} from "../../actions/posts";
import { getPostUserInfo, getPostUserImg } from "../../actions/users";
import PostProfile from "./PostProfile/PostProfile";
import ShowGeo from "../Maps/ShowGeo/ShowGeo.jsx";

const PostContent = () => {
	const classes = useStyles();
	const location = useLocation().pathname.split("/")[2];
	const history = useHistory();
	const dispatch = useDispatch();

	const user = JSON.parse(localStorage.getItem("profile"));

	{
		/*useEffect(() => {
    async function fetchData() {
      await batch(() => {
        dispatch(getPostContent(location));
        dispatch(getPostUserInfo(location));
        dispatch(getPostUserImg(location));
      });
    }
    fetchData();
  }, []);*/
	}
	useEffect(() => {
		dispatch(getPostContent(location));
		dispatch(getPostUserInfo(location));
		dispatch(getPostUserImg(location));
		appendYaMetrix();
	}, []);

	const appendYaMetrix = () => {
		const yaScript = document.createElement("script");
		yaScript.setAttribute("type", "text/javascript");
		yaScript.innerHTML = `window.yaContextCb.push(()=>{
			Ya.Context.AdvManager.render({
				"blockId": "R-A-6633212-1",
				"renderTo": "yandex_rtb_R-A-6633212-1"
			})
		})`;

		document.head.appendChild(yaScript);
	};

	const postContentFull = useSelector((state) => {
		return state.posts.postContent;
	});
	const postsInfo = useSelector((state) => {
		return state.posts.postsInfo;
	});
	const numberOfPosts = postsInfo !== undefined ? postsInfo.length : null;
	const positionPost = postsInfo?.findIndex((object) => {
		return object._id === postContentFull[0]._id;
	});
	const postContent = postContentFull ? postContentFull[0] : 0;
	const [likes, setLikes] = useState(postContent?.likes);
	useEffect(() => {
		setLikes(postContent?.likes);
	}, [postContent?.likes]);
	const postsInfoId = postsInfo ? postsInfo.map((c) => c._id) : 0;
	const indexPost = postsInfoId ? postsInfoId.indexOf(postContent?._id) : 0;
	const [deleteAsk, setDeleteAsk] = useState(false);
	const [isUpdatePost, setIsUpdatePost] = useState(false);
	const [openImage, setOpenImage] = useState("");
	const [geoChoose, setGeoChoose] = useState(false);
	const [geoShow, setGeoShow] = useState(false);

	let place = useSelector((state) => state?.place?.place);

	const profileInfo = useSelector((state) => {
		return state.users.result;
	});
	const profileImg = useSelector((state) => {
		return state.users.userImg;
	});
	profileInfo.picture = profileImg;

	const legendProps = {
		postsInfo: postsInfo,
		postContentFull: postContentFull,
	};

	const reportLink = `/posts/${postContent?.reportId}`;
	const [legend, setLegend] = useState(0);
	const previousPost = () => {
		const prevPostLoc = postsInfoId[indexPost - 1]
			? postsInfoId[indexPost - 1]
			: postsInfoId[postsInfoId.length - 1];
		window.location.href = `/post/${prevPostLoc}`;
	};
	const nextPost = () => {
		const nextPostLoc = postsInfoId[indexPost + 1]
			? postsInfoId[indexPost + 1]
			: postsInfoId[0];
		window.location.href = `/post/${nextPostLoc}`;
	};

	const toggleLegend = () => {
		setLegend((legend) => !legend);
	};

	const handleLike = async () => {
		dispatch(likePostReport(postContent._id));

		if (postContent?.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				postContent?.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...postContent?.likes, user?.result?._id]);
		}
	};

	const handleDeletePost = async () => {
		await dispatch(deletePostReport(postContent?._id));
		history.push(`/posts`);
	};

	const handleUpdatePost = async () => {
		setIsUpdatePost(!isUpdatePost);
	};

	const handleOpenImage = (a) => {
		setOpenImage(a);
	};

	const handleCloseImage = () => {
		setOpenImage("");
	};

	const handleButtonGeoClick = async () => {
		try {
			await dispatch(getPlace(postContent.place[0]));
			setGeoShow(!geoShow);
		} catch (error) {
			console.error("Error fetching place:", error);
		}
	};

	const Likes = () => {
		if (likes?.length > 0) {
			return likes.find((like) => like === user?.result?._id) ? (
				<>
					<ThumbUpAltIcon fontSize="small" sx={{ mr: "4px" }} />
					&nbsp;
					{likes?.length > 2
						? `Вы и ${likes.length - 1} других`
						: `${likes.length} лайк${likes.length > 1 ? "а" : ""}`}
				</>
			) : (
				<>
					<ThumbUpAltOutlined fontSize="small" />
					&nbsp;{likes.length} {likes.length === 1 ? "Лайк" : "Лайка"}
				</>
			);
		}
		return (
			<>
				<ThumbUpAltOutlined fontSize="small" />
				&nbsp;Лайк
			</>
		);
	};

	/*const modifyInitialPosts = async () => {
    const dispatchPosts = await dispatch(
      getInitialPosts(post?.post?._id)
    );
    setInitialPosts(dispatchPosts);
  };*/

	if (geoShow) {
		return (
			<ShowGeo
				place={place}
				postId={postContent?._id}
				setGeoShow={setGeoShow}
				geoShow={geoShow}
			/>
		);
	}

	return (
		<div>
			<Helmet>
				<title>{`${postContent?.title}`} - пост, ToTravelRu</title>
				<meta
					name="description"
					content={`${postContent?.title}, пост в рамках отчета о путешествии - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${postContent?.title}, пост, посты, отчет, отчеты, путешествия, travel, totravel, ToTravelRu, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия`}
				/>
			</Helmet>
			{geoChoose ? (
				<SelectGeo
					postContent={postContent}
					geoChoose={geoChoose}
					setGeoChoose={setGeoChoose}
				/>
			) : (
				<>
					<div className={classes.postsOuterContainer}>
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={2}
							className={classes.gridContainer}
						>
							{postContent ? (
								<Grid item xs={12} sm={3} md={2}>
									<PostProfile profile={profileInfo} />
									<div className={classes.calendarLike}>
										<div className={classes.calendarOuterContainer}>
											<Calendar createdAt={postContent.createdAt} />
										</div>
										{user?.result ? (
											<Button
												className={
													postContent?.likes.find(
														(like) => like === user?.result?._id
													)
														? classes.likeButtonClicked
														: classes.likeButtonNotClicked
												}
												onClick={handleLike}
											>
												<Likes />
											</Button>
										) : (
											<Button
												className={
													postContent?.likes.find(
														(like) => like === user?.result?._id
													)
														? classes.likeButtonClicked
														: classes.likeButtonNotClicked
												}
												disabled
											>
												<Likes />
											</Button>
										)}
									</div>
									{user?.result?._id === postContent?.creator && (
										<>
											{deleteAsk ? (
												<>
													<Paper className={classes.postCardDelete}>
														<Typography className={classes.writePostText}>
															Вы точно хотите удалить пост?
														</Typography>
														<div className={classes.yesNo}>
															<Button
																onClick={handleDeletePost}
																className={`${classes.buttonYes} ${classes.writePost} `}
															>
																<Typography className={classes.writePostText}>
																	Да
																</Typography>
															</Button>
															<Button
																onClick={() => setDeleteAsk(!deleteAsk)}
																className={`${classes.buttonNo} ${classes.writePost} `}
															>
																<Typography className={classes.writePostText}>
																	Нет
																</Typography>
															</Button>
														</div>
													</Paper>
												</>
											) : (
												<Button
													onClick={() => setDeleteAsk(!deleteAsk)}
													className={`${classes.buttonSecondary} ${classes.deletePost} `}
												>
													<DeleteIcon className={classes.writePostImg} />
													<Typography className={classes.writePostText}>
														Удалить пост
													</Typography>
												</Button>
											)}
											<Button
												className={classes.buttonPrimaryWidth}
												onClick={handleUpdatePost}
											>
												<UpdateIcon className={classes.writePostImg} />
												{isUpdatePost ? (
													<Typography className={classes.writePostText}>
														Вернуться
													</Typography>
												) : (
													<Typography className={classes.writePostText}>
														Изменить пост
													</Typography>
												)}
											</Button>
											{postContent?.place.length !== 0 ? null : (
												<Button
													onClick={() => setGeoChoose(!geoChoose)}
													className={`${classes.geographyButton}`}
												>
													<RoomIcon className={classes.writePostImg} />
													<Typography className={classes.writePostText}>
														Разместить на карте
													</Typography>
												</Button>
											)}
											{postContent?.place.length !== 0 ? (
												<Button
													onClick={() => setGeoChoose(!geoChoose)}
													className={`${classes.geographyButtonChange}`}
												>
													<RoomIcon className={classes.writePostImg} />
													<Typography className={classes.writePostText}>
														Изменить место на карте
													</Typography>
												</Button>
											) : null}
										</>
									)}
									{postContent?.place.length !== 0 ? (
										<Button
											onClick={handleButtonGeoClick}
											className={`${classes.geographyButton}`}
										>
											<RoomIcon className={classes.writePostImg} />
											<Typography className={classes.writePostText}>
												Показать на карте
											</Typography>
										</Button>
									) : null}
								</Grid>
							) : (
								<Grid item xs={12} sm={3} md={2}>
									<Paper
										className={`${classes.postCard} ${classes.CircularProfile}`}
									>
										<CircularProgress color="secondary" size="3rem" />
									</Paper>
								</Grid>
							)}
							<Grid item xs={12} sm={9} md={10}>
								{isUpdatePost ? (
									<div className={classes.postCard}>
										<PostUpdate post={postContent} />
									</div>
								) : postContent ? (
									<>
										<div className={classes.postCard}>
											<div className={classes.upperContainer}>
												<Typography
													gutterBottom
													variant="h4"
													className={classes.postTitlePost}
												>
													{postContent.title}
												</Typography>
												<Button
													variant="outlined"
													color="secondary"
													onClick={toggleLegend}
													className={classes.legendButtonOpen}
												>
													Оглавление
												</Button>
												{legend ? (
													<div className={classes.legend}>
														<Button
															className={classes.legendButton}
															onClick={toggleLegend}
														>
															Х
														</Button>
														<PostLegend legendProps={legendProps} />
													</div>
												) : (
													<div></div>
												)}
											</div>
											{postContent.textImage.map((o, a) => (
												<div>
													{o.image ? (
														o.image.toString().split(":")[0] === "data" ||
														o.image
															.toString()
															.split("storage.yandexcloud")[0] ===
															"https://totravelru." ? (
															<>
																<div
																	className={classes.imageBox}
																	onClick={() => {
																		handleOpenImage(a);
																	}}
																>
																	<CardMedia
																		className={classes.media}
																		image={o.image}
																	/>
																	<Typography>{o.description}</Typography>
																</div>
																<Modal
																	className={classes.modal}
																	open={openImage === a}
																	onClose={handleCloseImage}
																	aria-labelledby="enlarged-image-modal"
																	aria-describedby="enlarged-image-description"
																>
																	<div className={classes.modalContent}>
																		<Typography
																			className={classes.closeButton}
																			onClick={handleCloseImage}
																		>
																			X
																		</Typography>
																		<img
																			src={o.image}
																			alt="Enlarged"
																			className={classes.enlargedImage}
																		/>
																	</div>
																</Modal>
															</>
														) : (
															<Typography gutterBottom variant="subtitle1">
																{o.image}
															</Typography>
														)
													) : (
														<div className={classes.textWrap}>{parse(o)}</div>
													)}
												</div>
											))}
										</div>
									</>
								) : (
									<Paper
										className={`${classes.postCard} ${classes.CircularPost}`}
									>
										<CircularProgress color="secondary" size="7rem" />
									</Paper>
								)}
								<div className={classes.postContentButtons}>
									<Button
										onClick={previousPost}
										variant="contained"
										className={classes.buttonPrimary}
										disabled={positionPost === 0}
									>
										Предыдущий пост
									</Button>
									<Button
										component={Link}
										to={reportLink}
										variant="contained"
										className={classes.buttonPrimary}
									>
										<KeyboardReturnIcon className={classes.writePostImg} />
										Вернуться в оглавление
									</Button>
									<Button
										onClick={nextPost}
										variant="contained"
										className={classes.buttonPrimary}
										disabled={numberOfPosts === positionPost + 1}
									>
										Следующий пост
									</Button>
								</div>
								<div id="yandex_rtb_R-A-6633212-1"></div>
								<div>
									<PostComment postContentFull={postContentFull} />
								</div>
							</Grid>
						</Grid>
					</div>
				</>
			)}
		</div>
	);
};

export default PostContent;
