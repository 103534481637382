import {
	AUTH_LOGIN,
	AUTH_SIGNUP,
	LOGOUT,
	VERIFY_TOKEN,
	RESEND_EMAIL,
	SEND_CHANGE_PASSWORD,
	CHANGE_PASSWORD,
} from "../constants/actionTypes";

const authReducer = (state = { authData: null }, action) => {
	switch (action.type) {
		case AUTH_LOGIN:
			localStorage.setItem(
				"profile",
				JSON.stringify({ result: action?.payload.result })
			);
			return {
				...state,
				authData: action.payload,
				loading: false,
				errors: null,
			};
		case AUTH_SIGNUP:
			return {
				...state,
				info: action.payload,
			};
		case LOGOUT:
			localStorage.clear();
			return { ...state, authData: null, loading: false, errors: null };
		case VERIFY_TOKEN:
			return { ...state, msg: action.payload };
		case RESEND_EMAIL:
			return { ...state, msg: action.payload };
		case SEND_CHANGE_PASSWORD:
			return { ...state, msg: action.payload };
		case CHANGE_PASSWORD:
			return { ...state, msg: action.payload };
		default:
			return state;
	}
};

export default authReducer;
