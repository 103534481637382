import React, { useState, useEffect, useCallback } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import useStyles from "./styles.js";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Portal } from "../Portal";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import SearchGeo from "../SearchGeo/SearchGeo";
import { useDispatch, useSelector } from "react-redux";
import { addPlace, addPostToPlace } from "../../../../actions/place.js";
import ConfirmBoxSmall from "../ConfirmBoxSmall/ConfirmBoxSmall.jsx";

const SelectGeoMap = ({ postContent, mapInPost, addPlaceOnMap }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const mapRef = React.useRef(null);
	const [chosenCoords, setChosenCoords] = useState([0, 0]);
	const [confirmChosenCoords, setConfirmChosenCoords] = useState();
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const [activePortal, setActivePortal] = useState(false);
	const [isConfirmed, setIsConfirmed] = useState(false);

	const iconContentLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-content" class="driver-card"></div>`,
		{}
	);

	const addedPlaceSelector = useSelector((state) => {
		return state?.place?.place;
	});

	const handleConfirm = async (place) => {
		const addedPlace = await dispatch(addPlace(place));
		setIsConfirmed((prev) => true);
	};

	useEffect(() => {
		if (
			addedPlaceSelector &&
			addedPlaceSelector._id &&
			addPlaceOnMap &&
			isConfirmed
		) {
			window.location.href = `/place/${addedPlaceSelector._id}`;
		} else if (isConfirmed) {
			window.location.reload();
		} else {
			console.log("Кек");
		}
	}, [addedPlaceSelector, isConfirmed]);

	const handleConfirmExisting = async (placeId, rate) => {
		const addedPlace = mapInPost
			? await dispatch(addPostToPlace(placeId, rate, postContent?._id))
			: null;
		window.location.reload();
	};

	const [selectedPlaceMap, setSelectedPlaceMap] = useState(null);

	const handleSelectedPlace = (selectedPlace) => {
		setSelectedPlaceMap(selectedPlace);
		setActivePortal(true);
	};

	return (
		<>
			{mapInPost ? (
				<>
					<SearchGeo onSelectedPlace={handleSelectedPlace} />
					<Typography className={classes.textChoose}>
						Если поиск не дал результатов, укажите на карте место, которому
						посвящен пост:
					</Typography>
				</>
			) : null}
			<div></div>
			<div>
				{/*confirmChosenCoords && (
				<div
					className="box"
					style={{
						top: confirmChosenCoords?.top,
						left: confirmChosenCoords?.left,
					}}
				>
					Подтверждено
				</div>
			)*/}
				<YMaps
					query={{
						load: "package.full",
						apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
					}}
				>
					<Map
						defaultState={{
							center: selectedPlaceMap
								? selectedPlaceMap?.geoposition
								: [55.75, 37.57],
							zoom: 2,
							controls: ["zoomControl", "searchControl"],
						}}
						width="100%"
						height="83vh"
						onClick={(event) => {
							const coords = event.get("coords");
							setChosenCoords(() => coords);
							if (iconContentLayout) {
								setActivePortal(true);
							}
						}}
						instanceRef={mapRef}
						onLoad={(ymaps) => setYmaps(ymaps)}
						modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
					>
						<Placemark
							geometry={
								selectedPlaceMap ? selectedPlaceMap?.geoposition : chosenCoords
							}
							options={{
								iconLayout: iconContentLayout,
								iconOffset: selectedPlaceMap ? [0, -109.5] : [0, -285.5],
								iconImageHref:
									"https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png",
								iconColor: "rgb(236,98,98)",
								balloonCloseButton: "false",
								pane: "outerBalloon",
								cursor: "balloon",
								interactivityModel: "default#opaque",
							}}
							properties={{
								iconContent: "Геолокация",
							}}
						/>
					</Map>
				</YMaps>
				{activePortal && selectedPlaceMap === null && (
					<Portal
						getHTMLElementId={"placemark-content"}
						chosenCoords={chosenCoords}
					>
						<ConfirmBox
							handleConfirm={handleConfirm}
							chosenCoords={chosenCoords}
							postContent={postContent}
						/>
					</Portal>
				)}
				{activePortal && selectedPlaceMap !== null && (
					<Portal
						getHTMLElementId={"placemark-content"}
						selectedPlaceMap={selectedPlaceMap}
					>
						<ConfirmBoxSmall
							handleConfirmExisting={handleConfirmExisting}
							place={selectedPlaceMap}
						/>
					</Portal>
				)}
			</div>
		</>
	);
};

export default SelectGeoMap;
