import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	betaTest: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "10px",
		marginBottom: "10px",
		display: "flex",
		alignItems: "center",
		"@media (max-width: 600px)": {
			marginBottom: "10px",
		},
	},
	betaTestButton: {
		color: "rgba(236,98,98,1)",
		margin: "0 0 0 auto",
		minWidth: "30px",
	},
}));
