import {
	GET_PLACES,
	CHECK_PLACES_EXIST,
	START_LOADING_PLACES,
	END_LOADING_PLACES,
	START_LOADING_PLACES_SEARCH,
	END_LOADING_PLACES_SEARCH,
	SEARCH_PLACES,
	GET_PLACES_BY_CITY,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const getPlaces = (limit) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PLACES });
		const { data } = await api.getPlaces(limit);
		dispatch({ type: GET_PLACES, payload: data });
		dispatch({ type: END_LOADING_PLACES });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPlacesByPostsId = (postsId) => async (dispatch) => {
	try {
		const { data } = await api.getPlacesByPostsId(postsId);
		dispatch({ type: GET_PLACES, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const checkPlacesByPostsId = (postsId) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PLACES });
		const { data } = await api.checkPlacesByPostsId(postsId);
		dispatch({ type: CHECK_PLACES_EXIST, payload: data });
		dispatch({ type: END_LOADING_PLACES });
	} catch (error) {
		console.log(error.message);
	}
};

export const searchPosts = (searchString, filter) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PLACES_SEARCH });
		const { data } = await api.searchPosts(searchString, filter);
		dispatch({ type: SEARCH_PLACES, payload: data });
		dispatch({ type: END_LOADING_PLACES_SEARCH });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPlacesByBorders =
	(limit, firstBorder, secondBorder) => async (dispatch) => {
		try {
			dispatch({ type: START_LOADING_PLACES });
			const { data } = await api.getPlacesByBorders(
				limit,
				firstBorder,
				secondBorder
			);
			dispatch({ type: GET_PLACES, payload: data });
			dispatch({ type: END_LOADING_PLACES });
		} catch (error) {
			console.log(error.message);
		}
	};

export const findPlacesByCity =
	(city, page, filter) => async (dispatch) => {
		try {
			dispatch({ type: START_LOADING_PLACES });
			console.log(city);
			const { data } = await api.findPlacesByCity(
				city,
				page,
				filter
			);
			dispatch({ type: GET_PLACES_BY_CITY, payload: data });
			dispatch({ type: END_LOADING_PLACES });
		} catch (error) {
			console.log(error.message);
		}
	};
