import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import MapIcon from "@material-ui/icons/Map";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GroupIcon from "@material-ui/icons/Group";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import { Link } from "react-router-dom";

const iconStyle = {
	marginRight: "8px", // Add space between icon and text
	color: "rgb(236, 98, 98)", // Set color to rgb(236,98,98)
};

const CustomMenu = ({ anchorEl, handleClose, user }) => {
	return (
		<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
			<MenuItem component={Link} to="/feed" onClick={handleClose}>
				<DynamicFeedIcon style={iconStyle} /> Лента постов
			</MenuItem>
			<MenuItem component={Link} to="/posts" onClick={handleClose}>
				<SearchIcon style={iconStyle} /> Поиск отчетов
			</MenuItem>
			<MenuItem component={Link} to="/map" onClick={handleClose}>
				<MapIcon style={iconStyle} /> Карта
			</MenuItem>
			{user ? (
				<MenuItem
					component={Link}
					to={`/user/${user?.result?.login}`}
					onClick={handleClose}
				>
					<AccountCircleIcon style={iconStyle} /> Профиль
				</MenuItem>
			) : null}
			<MenuItem component={Link} to="/users" onClick={handleClose}>
				<GroupIcon style={iconStyle} /> Пользователи
			</MenuItem>
			<MenuItem component={Link} to="/support" onClick={handleClose}>
				<AccountBalanceWalletIcon style={iconStyle} /> Поддержать проект
			</MenuItem>
		</Menu>
	);
};

export default CustomMenu;
