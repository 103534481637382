import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    border: "1px solid",
    borderRadius: "15px",
    borderColor: "rgba(236,98,98,1)",
    padding: "20px 0",
  },
  boxImg: {
    color: "rgba(236,98,98,1)",
    width: "75px",
    height: "75px",
  },
  boxText: {
    textAlign: "center",
  },
  CircularPost: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  },
  CircularProfile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15vh",
  },
}));
