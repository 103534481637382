import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Typography,
  Container,
  TextField,
  Card,
  Grid,
  Box,
  CardMedia,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Post from "../../Posts/Post/Post";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./styles";

const Region = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [region, setRegion] = useState({
    title: "Тест",
    image: null,
    about: "Откройте для себя Тест - страну бескрайних просторов",
    reports: [],
  });

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" className={classes.regionTitle}>
        {region?.title}
      </Typography>
      {region?.image ? (
        <CardMedia className={classes.media} image={region?.image} />
      ) : (
        <div className={classes.media}></div>
      )}
      <Typography variant="body1" className={classes.regionText}>
        {region.about}
      </Typography>
      <Typography variant="h6" className={classes.reportsTitle}>
        Отчеты о стране
      </Typography>
      <div className={classes.reportsBox}>
        {region?.reports === undefined ? (
          <div className={classes.circularProfile}>
            <CircularProgress color="secondary" />
          </div>
        ) : region?.reports.length === 0 ? (
          <div className={classes.cardPost}>
            <Typography>Отчетов пока нет</Typography>
            <Typography>
              Вы можете написать отчет, кликнув на кнопку ниже
            </Typography>
            <Button className={classes.buttonPrimary} href="/posts">
              <Typography>Написать отчет</Typography>
            </Button>
          </div>
        ) : (
          <div>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={3}
              className={classes.gridContainerPosts}
            >
              {region?.reports.map((post) => (
                <Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
                  <Post post={post} setCurrentId={0}></Post>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default Region;
