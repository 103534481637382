import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

import Post from "./Post/Post.js";
import useStyles from "./styles.js";

const Posts = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);

  const classes = useStyles();

  if (!posts.length && !isLoading)
    return (
      <Box className={classes.box}>
        <SentimentVeryDissatisfiedIcon className={classes.boxImg} />
        <Typography className={classes.boxText}>Нет отчетов</Typography>
        <Typography className={classes.boxText}>
          Вы можете написать отчет об этой стране
        </Typography>
      </Box>
    );

  return isLoading ? (
    <Paper className={classes.CircularPost}>
      <CircularProgress color="secondary" size="7rem" />
    </Paper>
  ) : (
    <Grid
      className={classes.container}
      container
      alignItems="stretch"
      spacing={3}
    >
      {posts.map((post) => (
        <Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
          <Post post={post} setCurrentId={setCurrentId}></Post>
        </Grid>
      ))}
    </Grid>
  );
};

export default Posts;
