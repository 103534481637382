import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    borderRadius: "15px",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    padding: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  fileInput: {
    width: "100%",
    margin: "8px 0",
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  buttonPrimary: {
    marginBottom: 10,
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  buttonSecondary: {
    marginBottom: 10,
    backgroundColor: "rgba(70,70,70,1)",
    color: "rgba(255,255,255,1)",
    "&:hover, &:focus": {
      backgroundColor: "rgba(100,100,100,1)",
    },
  },
  formType: {
    margin: "8px 0 0 0",
  },
  imageInput: {
    display: "none",
  },
  labelInput: {
    border: "1.5px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 12px",
    cursor: "pointer",
    margin: "0",
    height: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  fileNameBox: {
    border: "1.5px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "5px",
    minHeight: "25px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    padding: "0 5px",
    marginTop: "8px",
  },
  supportLink: {
    textDecoration: "none",
    color: "rgba(0,0,0,1)",
  },
}));
