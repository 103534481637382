import {
	ADD_PLACE,
	GET_PLACE,
	ADD_POST_TO_PLACE,
	START_LOADING_PLACES,
	END_LOADING_PLACES,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addPlace = (place) => async (dispatch) => {
	try {
		const { data } = await api.addPlace(place);
		dispatch({ type: ADD_PLACE, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getPlace = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_PLACES });
		const { data } = await api.getPlace(id);
		dispatch({ type: GET_PLACE, payload: data });
		dispatch({ type: END_LOADING_PLACES });
	} catch (error) {
		console.log(error.message);
	}
};

export const addPostToPlace = (placeId, rate, postId) => async (dispatch) => {
	try {
		const { data } = await api.addPostToPlace(placeId, rate, postId);
		dispatch({ type: ADD_POST_TO_PLACE, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const addCityToPlace = (placeId, cityId) => async (dispatch) => {
	try {
		const { data } = await api.addCityToPlace(placeId, cityId);
		dispatch({ type: ADD_POST_TO_PLACE, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
