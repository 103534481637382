import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	Typography,
	Button,
	CircularProgress,
	Box,
	Grid,
	Link,
	Card,
	CardMedia,
	CardActionArea,
	CardContent,
} from "@material-ui/core";
import useStyles from "./styles.js";
import StarRating from "../../../../Maps/ShowGeo/PlaceBox/StarRating";
import {
	switchIcons,
	translatePlaceTypePlural,
} from "../../../../../utils/placeUtils.js";
import PlaceCard from "./PlaceCard/PlaceCard.jsx";

const PlacesInCityList = ({ placesType, placesForList, place }) => {
	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={4}>
				<Box className={classes.boxType}>
					<img src={switchIcons(placesType)} className={classes.boxTypeImg} />
					<Typography variant="subtitle1" className={classes.boxTypeText}>
						{translatePlaceTypePlural(placesType)}
					</Typography>
				</Box>
				{placesForList
					? placesForList.map((p) => {
							return <PlaceCard p={p} />;
					  })
					: null}
				{placesForList && placesForList.length === 0 ? (
					<Card className={classes.card}>
						<CardContent className={classes.CardContent}>
							<Typography className={classes.titleFull}>
								Места пока отсутствуют. Вы можете добавить их на карте.
							</Typography>
						</CardContent>
					</Card>
				) : null}
				{placesForList && placesForList.length > 0 ? (
					<Button
						className={classes.buttonPrimarySmall}
						onClick={() =>
							(window.location.href = `/place/${place._id}/${placesType}`)
						}
					>
						Посмотреть больше мест
					</Button>
				) : null}
			</Grid>
		</>
	);
};

export default PlacesInCityList;
