import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	margin: {
		margin: "5px 0",
	},
	mapTitleButton: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		"@media (max-width: 600px)": {
			flexDirection: "column",
			alignItems: "start",
		},
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
}));
