import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	profileCard: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		display: "flex",
		justifyContent: "center",
	},

	gridContainer: {
		[theme.breakpoints.down("s")]: {
			flexDirection: "column",
		},
	},
	profilePicture: {
		width: "60px",
		height: "60px",
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		fontSize: "2rem",
		marginRight: "5px",
		"@media (max-width: 600px)": {
			width: "50px",
			height: "50px",
			fontSize: "1.2rem",
			margin: "0",
		},
		"@media (max-width: 400px)": {
			width: "40px",
			height: "40px",
			fontSize: "1.2rem",
			margin: "0",
		},
	},
	profileLink: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		margin: "10px",
		textDecoration: "none",
		width: "100%",
		"@media (max-width: 600px)": {
			margin: "5px",
		},
	},
	profileTop: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	profileName: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
		textAlign: "center",
		marginRight: "5px",
	},
	adminSponsorLabel: {
		borderRadius: "20px",
		backgroundColor: "rgba(9,9,121,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		padding: "0 10px",
		"@media (max-width: 400px)": {
			fontSize: "0.9rem",
		},
	},
	profileNameText: {
		lineHeight: "1.3",
		color: "rgb(0,0,0)",
		"@media (max-width: 400px)": {
			fontSize: "0.9rem",
			lineHeight: "1.2",
		},
	},
	profileNameLogin: {
		lineHeight: "1.3",
		color: "rgb(0,0,0)",
		"@media (max-width: 400px)": {
			fontSize: "1rem",
		},
	},
	numberReportsFlex: {
		display: "flex",
	},
	numberReportsBox: {
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		marginLeft: "3px",
		minWidth: "20px",
		height: "20px",
		padding: "1px 3px",
		borderRadius: "12px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));
