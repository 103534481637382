import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
	},
	title: {
		marginBottom: theme.spacing(1),
	},
	avatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
		marginRight: theme.spacing(1),
	},
	rootNoFriends: {
		marginBottom: 0,
	},
	titleNoFriends: {
		marginBottom: 0,
	},
}));
