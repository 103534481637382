import React, { useState } from "react";
import {
  Typography,
  Card,
  Grid,
  Box,
  ButtonBase,
  CardMedia,
  CardContent,
} from "@material-ui/core";

import useStyles from "./styles";

const RegionsCard = ({
  region,
  numberReportsRegion,
  image,
  message,
  refRegion,
}) => {
  const classes = useStyles();
  const openRegion = () => (window.location.href = `/region/${refRegion}`);

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={classes.card}>
        <ButtonBase
          component="span"
          name="test"
          className={classes.cardAction}
          onClick={openRegion}
        >
          {image ? (
            <CardMedia className={classes.media} image={image} />
          ) : (
            <div className={classes.media}></div>
          )}
          <div className={classes.overlay}>
            <Typography>{region}</Typography>
            <div className={classes.numberFlex}>
              <Typography className={classes.numberReports}>
                Количество отчетов:
              </Typography>
              <Box>
                <Typography>{numberReportsRegion}</Typography>
              </Box>
            </div>
          </div>
          {/*<CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {message}
            </Typography>
          </CardContent>*/}
        </ButtonBase>
      </Card>
    </Grid>
  );
};

export default RegionsCard;
