import React, { useState, useEffect } from "react";
import useStyles from "./styles.js";
import { YMaps, Map, Placemark, Clusterer } from "@pbe/react-yandex-maps";
import { Typography, Button } from "@material-ui/core";
import { PortalShowGeo } from "./PortalShowGeo";
import PlaceBox from "./PlaceBox/PlaceBox"; // Import ConfirmBox component

const ShowGeo = ({ place, postId, geoShow, setGeoShow, placeComponent }) => {
	const classes = useStyles();

	const images = [...Array(1)].map((n, i) => {
		return `https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png`;
	});

	const mapRef = React.useRef(null);
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const placeBoxRef = React.useRef(null); // Ref for PlaceBox
	const [activePortal, setActivePortal] = useState(false);
	const [iconOffset, setIconOffset] = useState([0, 0]); // Initial offset
	const iconContentLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-show-geo" class="driver-card"></div>`,
		{}
	);

	useEffect(() => {
		if (placeBoxRef.current) {
			const placeBoxHeight = placeBoxRef.current.clientHeight;
			setIconOffset([0, -placeBoxHeight]);
		}
	}, [placeBoxRef.current]);

	{
		/*useEffect(() => {
		setTimeout(() => {
			const placemarkElement = document.getElementById("placemark-show-geo");
			console.log(placemarkElement);
			if (placemarkElement) {
				setActivePortal(true);
			}
		}, 1000);
	}, []);*/
	}

	useEffect(() => {
		const interval = setInterval(() => {
			const placemarkElement = document.getElementById("placemark-show-geo");
			if (placemarkElement) {
				clearInterval(interval);
				setActivePortal(true);
			}
		}, 700);
		return () => clearInterval(interval);
	}, []);

	const [parentWrapBoxHeight, setParentWrapBoxHeight] = useState(0);

	const handleWrapBoxHeightChange = (height) => {
		setParentWrapBoxHeight(height);
	};

	return (
		<>
			{geoShow ? (
				<Button
					className={classes.betaTest}
					onClick={() => setGeoShow(!geoShow)}
					style={{ backgroundColor: "rgba(236,98,98,1)" }}
				>
					Закрыть карту
				</Button>
			) : null}
			<YMaps
				query={{
					load: "package.full",
					apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
				}}
			>
				<Map
					defaultState={{
						center: place?.geoposition,
						zoom: 9,
						controls: ["zoomControl"],
					}}
					width="100%"
					height={placeComponent ? "250px" : "83vh"}
					instanceRef={mapRef}
					onLoad={(ymaps) => {
						setYmaps(ymaps);
					}}
					onClick={(event) => {
						if (iconContentLayout) {
							setActivePortal(true);
						}
					}}
				>
					<Placemark
						geometry={place?.geoposition}
						options={{
							iconLayout: iconContentLayout,
							iconOffset: iconOffset, // Use dynamic iconOffset
							iconColor: "rgb(236,98,98)",
						}}
						properties={{
							hintContent: "Hello",
						}}
					/>
				</Map>
			</YMaps>
			{activePortal && (
				<PortalShowGeo
					getHTMLElementId={"placemark-show-geo"}
					iconContentLayout={iconContentLayout}
				>
					<div ref={placeBoxRef}>
						<PlaceBox
							place={place}
							placeComponent={placeComponent}
							postId={postId ? postId : null}
							onWrapBoxHeightChange={handleWrapBoxHeightChange}
						/>
					</div>
				</PortalShowGeo>
			)}
			<Typography>
				Icons by{" "}
				<a className={classes.icons8} href="https://icons8.com">
					icons8
				</a>
			</Typography>
		</>
	);
};

export default ShowGeo;
