import {
	FETCH_ALL,
	CREATE,
	UPDATE,
	DELETE,
	LIKE,
	FETCH_BY_SEARCH,
	START_LOADING,
	END_LOADING,
	FETCH_POST,
	COMMENT,
	ADD_POST,
	FETCH_POST_CONTENT,
	FETCH_ADJ_POST,
	FETCH_POSTS_BY_USER,
	LIKE_POST,
	LIKE_COMMENT_POST,
	LIKE_POST_IN_REPORT,
	LIKE_COMMENT_REPORT,
	DELETE_POST,
	FETCH_UPDATED_POST,
	ADD_YANDEX_IMAGE,
	START_LOADING_PHOTO,
	END_LOADING_PHOTO,
	SEND_RESPONSE_COMMENT_REPORT,
	SEND_RESPONSE_COMMENT_POST,
	DELETE_YANDEX_IMAGE,
	GET_POST_SMALL,
} from "../constants/actionTypes";

export default (
	state = { isLoading: true, isLoadingPhoto: false, photoUrl: null, posts: [] },
	action
) => {
	switch (action.type) {
		case START_LOADING:
			return { ...state, isLoading: true };
		case END_LOADING:
			return { ...state, isLoading: false };
		case START_LOADING_PHOTO:
			return { ...state, isLoadingPhoto: true };
		case END_LOADING_PHOTO:
			return { ...state, isLoadingPhoto: false };
		case FETCH_ALL:
			return {
				...state,
				posts: action.payload.data,
				currentPage: action.payload.currentPage,
				numberOfPages: action.payload.numberOfPages,
			};
		case FETCH_BY_SEARCH:
			return { ...state, posts: action.payload.data };
		case FETCH_POST:
			return {
				...state,
				post: action.payload.data,
				initialPosts: action.payload.initialPosts,
			};
		case GET_POST_SMALL:
			return {
				...state,
				post: action.payload,
			};
		case FETCH_POSTS_BY_USER:
			return {
				...state,
				post: action.payload.data,
				initialPosts: action.payload.initialPosts,
				currentPage: action.payload.currentPage,
				numberOfPages: action.payload.numberOfPages,
			};
		case FETCH_POST_CONTENT:
			return {
				...state,
				postContent: action.payload.data.reportPost,
				postsInfo: action.payload.data.postsInfo,
			};
		case FETCH_UPDATED_POST:
			return {
				...state,
				postContent: action.payload.data.reportPost,
			};

		case FETCH_ADJ_POST:
			return {
				...state,
				adjPostId: action.payload.data,
			};
		case CREATE:
			return { ...state, posts: [...state.posts, action.payload] };
		case UPDATE:
		case LIKE:
			return {
				...state,
				posts: state.posts.map((post) =>
					post._id === action.payload._id ? action.payload : post
				),
			};
		case LIKE_POST:
			return {
				...state,
				postContent: [action.payload],
			};
		case LIKE_COMMENT_POST:
			const newComments = [...state.postContent[0].comments];
			newComments[action.payload.number] = action.payload.comment;
			return {
				...state,
				postContent: [
					{
						...state.postContent[0],
						comments: newComments,
					},
				],
			};
		case LIKE_COMMENT_REPORT:
			const newCommentsReport = [...state.post.comments];
			newCommentsReport[action.payload.number] = action.payload.comment;
			return {
				...state,
				post: { ...state.post, comments: newCommentsReport },
			};
		case LIKE_POST_IN_REPORT:
			const newArrPosts = [...state.initialPosts];
			const newLikes = action.payload.postLikes;
			newArrPosts[action.payload.number].likes = newLikes;

			return {
				...state,
				initialPosts: newArrPosts,
			};
		case COMMENT:
			return {
				...state,
				posts: state.posts.map((post) => {
					if (post._id === action.payload.reportId) {
						return action.payload;
					}
					return post;
				}),
			};
		case SEND_RESPONSE_COMMENT_REPORT:
			return state;
		case SEND_RESPONSE_COMMENT_POST:
			return state;
		case ADD_POST:
			return {
				...state,
				posts: state.posts.map((post) => {
					if (post._id === action.payload._id) {
						return action.payload;
					}
					return post;
				}),
			};
		case DELETE:
			return {
				...state,
				posts: state.posts.filter((post) => post._id !== action.payload),
			};
		case DELETE_POST:
			return {
				...state,
				initialPosts: state.initialPosts.filter(
					(post) => post._id !== action.payload
				),
			};
		case ADD_YANDEX_IMAGE:
			return state;
		case DELETE_YANDEX_IMAGE:
			return state;
		default:
			return state;
	}
};
