import {
	END_LOADING_FEED,
	START_LOADING_FEED,
	FETCH_POSTS_FEED,
	FETCH_MORE_POSTS,
	START_LOADING_MORE,
	END_LOADING_MORE,
} from "../constants/actionTypes";

const feedReducer = (
	state = { isLoading: true, isLoadingMore: false, postsFeed: [] },
	action
) => {
	switch (action.type) {
		case START_LOADING_FEED:
			return { ...state, isLoading: true };
		case END_LOADING_FEED:
			return { ...state, isLoading: false };
		case START_LOADING_MORE:
			return { ...state, isLoadingMore: true };
		case END_LOADING_MORE:
			return { ...state, isLoadingMore: false };
		case FETCH_POSTS_FEED:
			return { ...state, postsFeed: action.payload };
		case FETCH_MORE_POSTS:
			return { ...state, postsFeed: [...state.postsFeed, ...action.payload] };
		default:
			return state;
	}
};

export default feedReducer;
