import {
	CREATE_PLACE_REVIEW,
	FETCH_PLACE_REVIEWS,
} from "../constants/actionTypes";

const placeReviewReducer = (
	state = {
		placeReviews: [],
	},
	action
) => {
	switch (action.type) {
		case CREATE_PLACE_REVIEW:
			let review = action.payload.newPlaceReview;
			review.user = action.payload.user;
			return {
				...state,
				placeReviews: [...state.placeReviews, review],
			};
		case FETCH_PLACE_REVIEWS:
			return {
				...state,
				placeReviews: action.payload, // Replace the current placeReviews with the action payload
			};
		default:
			return state;
	}
};

export default placeReviewReducer;
