import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	searchResultsContainer: {
		position: "relative",
	},
	searchResults: {
		position: "absolute",
		zIndex: 10000000, // Ensure it's above other content
		backgroundColor: "#fff", // Optional: Set background color
		border: "1px solid #ccc", // Optional: Add a border
		//boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow
		//width: "calc(100% - 34px)",
		width: "100%",
		padding: 0,
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0 7px auto",
		paddingLeft: "30px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	placeTypeText: {
		fontSize: "0.85rem",
	},
}));
