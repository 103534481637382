import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	gridContainer: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column-reverse",
		},
	},
	flex: {
		display: "flex",
		alignItems: "center",
	},
	pagination: {
		borderRadius: "15px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		marginTop: "10px",
		padding: "4px",
	},
	searchContainerText: {
		margin: "5px 0 10px 0",
	},
	buttonPrimary: {
		height: "30px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		lineHeight: "1.3",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	titlePlaces: {
		position: "relative",
	},
	titlePlacesBox: {
		display: "flex",
		flexWrap: "wrap",
	},
	titlePlacesText: {
		marginRight: "10px",
	},
}));
