import React, { useState } from "react";
import { Typography, Button, Card } from "@material-ui/core";
import useStyles from "./styles.js";
import {
	addFriend,
	removeFriend,
	unsubscribe,
} from "../../../actions/users.js";
import { useDispatch, useSelector } from "react-redux";

const HandleFriend = ({ userCurrent, user, type }) => {
	const classes = useStyles();
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const dispatch = useDispatch();

	const handleAddFriend = async () => {
		// Call the addFriend function and await its result
		const result = await dispatch(
			addFriend(userCurrent?.result?._id, user?._id)
		);

		// If the result is successful
		if (result) {
			// Update the profile object in localStorage
			const profile = JSON.parse(localStorage.getItem("profile")) || {};

			// Ensure that result exists
			if (profile.result) {
				// If subscriptions property doesn't exist, create it
				if (!profile.result.subscriptions) {
					profile.result.subscriptions = [];
				}

				// Push user?._id to subscriptions
				profile.result.subscriptions.push(user?._id);

				// Save the updated profile back to localStorage
				localStorage.setItem("profile", JSON.stringify(profile));

				// Set isSuccess to true
				setIsSuccess(true);
			} else {
				setIsError(true);
			}
		} else {
			setIsError(true);
		}
	};

	const handleUnsubscribe = async () => {
		// Call the unsubscribe function and await its result
		const result = await dispatch(
			unsubscribe(userCurrent?.result?._id, user?._id)
		);

		// If the result is successful
		if (result) {
			// Update the profile object in localStorage
			const profile = JSON.parse(localStorage.getItem("profile")) || {};

			// Ensure that result exists and subscriptions is an array
			if (profile.result && Array.isArray(profile.result.subscriptions)) {
				// Remove user?._id from subscriptions if it exists
				const index = profile.result.subscriptions.indexOf(user?._id);
				if (index !== -1) {
					profile.result.subscriptions.splice(index, 1);
				}

				// Save the updated profile back to localStorage
				localStorage.setItem("profile", JSON.stringify(profile));

				// Set isSuccess to true
				setIsSuccess(true);
			} else {
				setIsError(true);
			}
		} else {
			setIsError(true);
		}
	};

	const handleRemoveFriend = async () => {
		// Call the unsubscribe function and await its result
		const result = await dispatch(
			removeFriend(userCurrent?.result?._id, user?._id)
		);

		// If the result is successful
		if (result) {
			// Update the profile object in localStorage
			const profile = JSON.parse(localStorage.getItem("profile")) || {};

			// Ensure that result exists and subscriptions is an array
			if (profile.result && Array.isArray(profile.result.friends)) {
				// Remove user?._id from subscriptions if it exists
				const index = profile.result.friends.indexOf(user?._id);
				if (index !== -1) {
					profile.result.friends.splice(index, 1);
				}

				// Save the updated profile back to localStorage
				localStorage.setItem("profile", JSON.stringify(profile));

				// Set isSuccess to true
				setIsSuccess(true);
			} else {
				setIsError(true);
			}
		} else {
			setIsError(true);
		}
	};

	if (isSuccess) {
		return (
			<>
				{type === "add" ? (
					<Card className={classes.card}>
						<Typography className={classes.successText}>
							Друг добавлен, дождитесь ответа ползователя
						</Typography>
					</Card>
				) : type === "unsubscribe" ? (
					<Card className={classes.card}>
						<Typography>Вы отписались от пользователя</Typography>
					</Card>
				) : type === "removeFriend" ? (
					<Card className={classes.card}>
						<Typography>Вы удалили пользователя из друзей</Typography>
					</Card>
				) : null}
			</>
		);
	}

	if (isError) {
		return (
			<Card className={classes.card}>
				<Typography>Что-то пошло не так</Typography>
			</Card>
		);
	}

	return (
		<Button
			className={
				type === "add"
					? `${classes.buttonPrimary} ${classes.width100} ${classes.margin0}`
					: `${classes.buttonSecondary} ${classes.width100} ${classes.margin0}`
			}
			onClick={
				type === "add"
					? handleAddFriend
					: type === "unsubscribe"
					? handleUnsubscribe
					: type === "removeFriend"
					? handleRemoveFriend
					: null
			}
		>
			<Typography variant="body1">
				{type === "add"
					? "Добавить в друзья"
					: type === "unsubscribe"
					? "Отписаться"
					: type === "removeFriend"
					? "Удалить из друзей"
					: "Тест"}
			</Typography>
		</Button>
	);
};

export default HandleFriend;
