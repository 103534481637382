import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
	Button,
	Grid,
	Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { batch } from "react-redux";
import CommentSection from "./CommentSection";
import {
	getPost,
	getPostsBySearch,
	likePost,
	deletePost,
} from "../../actions/posts";
import { getReportUserInfo } from "../../actions/users.js";
import PostReport from "../PostReport/PostReport";
import useStyles from "./styles";
import PostCreate from "../PostReport/PostCreate/PostCreate";
import PostProfile from "../PostContent/PostProfile/PostProfile";
import Calendar from "../Calendar/Calendar";
import CreateIcon from "@material-ui/icons/Create";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import RoomIcon from "@material-ui/icons/Room";
import UpdateIcon from "@material-ui/icons/Update";
import ReportUpdate from "./ReportUpdate/ReportUpdate";
import MapReport from "../Maps/MapReport/MapReport.jsx";
import { checkPlacesByPostsId } from "../../actions/places.js";

const ReportDetails = () => {
	const { post, posts, isLoading } = useSelector((state) => state.posts);
	const profileInfo = useSelector((state) => {
		return state.users.result;
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const [createPost, setCreatePost] = useState(false);
	const [deleteAsk, setDeleteAsk] = useState(false);
	const [isUpdateReport, setIsUpdateReport] = useState(false);
	const [geoShow, setGeoShow] = useState(false);

	const [likes, setLikes] = useState(post?.likes);
	useEffect(() => {
		setLikes(post?.likes);
	}, [post]);
	const { id } = useParams();
	const user = JSON.parse(localStorage.getItem("profile"));

	const postsArray = useSelector((state) => {
		return state?.posts?.initialPosts;
	});

	const postsArrayIds = postsArray?.map((post) => post._id);
	const queryParams = postsArrayIds?.join(",");

	const isPlacesExist = useSelector((state) => {
		return state?.place?.placesExist;
	});

	useEffect(() => {
		appendYaMetrix2();
	}, []);

	useEffect(() => {
		postsArrayIds?.length > 0
			? dispatch(checkPlacesByPostsId(queryParams))
			: console.log("Постов нет");
	}, [queryParams]);

	useEffect(() => {
		async function fetchData() {
			await batch(() => {
				dispatch(getPost(id));
				dispatch(getReportUserInfo(id));
			});
		}
		fetchData();
	}, [id]);

	useEffect(() => {
		if (post) {
			dispatch(
				getPostsBySearch({ search: "none", tags: post?.tags.join(",") })
			);
		}
	}, [post]);

	const recommendedPosts = posts?.filter(({ _id }) => _id !== post?._id);

	const openPost = (_id) => history.push(`/posts/${_id}`);

	const handleLike = async () => {
		dispatch(likePost(post._id));
		if (post.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				post.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...post.likes, user?.result?._id]);
		}
	};

	const handleDeleteReport = async () => {
		await dispatch(deletePost(post?._id));
		history.push(`/posts`);
	};

	const Likes = () => {
		if (likes?.length > 0) {
			return likes.find((like) => like === user?.result?._id) ? (
				<>
					<ThumbUpAltIcon fontSize="small" sx={{ mr: "4px" }} />
					&nbsp;
					{likes?.length > 2
						? `Вы и ${likes.length - 1} других`
						: `${likes.length} лайк${likes.length > 1 ? "а" : ""}`}
				</>
			) : (
				<>
					<ThumbUpAltOutlined fontSize="small" />
					&nbsp;{likes.length} {likes.length === 1 ? "Лайк" : "Лайка"}
				</>
			);
		}
		return (
			<>
				<ThumbUpAltOutlined fontSize="small" />
				&nbsp;Лайк
			</>
		);
	};

	const handleCreatePost = () => {
		setCreatePost(!createPost);
	};

	const handleButtonGeoClick = async () => {
		try {
			setGeoShow(!geoShow);
		} catch (error) {
			console.error("Error fetching place:", error);
		}
	};

	if (geoShow) {
		return <MapReport setGeoShow={setGeoShow} geoShow={geoShow} />;
	}

	const appendYaMetrix2 = () => {
		const yaScript2 = document.createElement("script");
		yaScript2.setAttribute("type", "text/javascript");
		yaScript2.innerHTML = `window.yaContextCb.push(()=>{
			Ya.Context.AdvManager.render({
				"blockId": "R-A-6633212-2",
				"renderTo": "yandex_rtb_R-A-6633212-2"
			})
		})`;

		document.head.appendChild(yaScript2);
	};

	return (
		<>
			<Helmet>
				<title>
					{`${post?.title ? post?.title : ""}`} - отчет о поездке в страну{" "}
					{`${post?.tags[0]}`}, ToTravelRu
				</title>
				<meta
					name="description"
					content={`${
						post?.title ? post?.title : ""
					}, отчет о поездке в страну ${post?.tags[0]}- ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${post?.title}, ${post?.tags[0]}, отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu`}
				/>
			</Helmet>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={2}
				className={classes.gridContainer}
			>
				{profileInfo?.login && post ? (
					<Grid item xs={12} sm={12} md={2}>
						<PostProfile profile={profileInfo} />
						<div className={classes.calendarLike}>
							<div className={classes.calendarOuterContainer}>
								<Calendar createdAt={post?.createdAt} />
							</div>
							{user?.result ? (
								<Button
									className={
										post?.likes.find((like) => like === user?.result?._id)
											? classes.likeButtonClicked
											: classes.likeButtonNotClicked
									}
									onClick={handleLike}
								>
									<Likes />
								</Button>
							) : (
								<Button
									className={
										post?.likes.find((like) => like === user?.result?._id)
											? classes.likeButtonClicked
											: classes.likeButtonNotClicked
									}
									disabled
								>
									<Likes />
								</Button>
							)}
						</div>
						{user?.result?._id === post?.creator && (
							<>
								{deleteAsk ? (
									<>
										<Paper className={classes.postCardDelete}>
											<Typography className={classes.writePostText}>
												Вы точно хотите удалить отчет?
											</Typography>
											<div className={classes.yesNo}>
												<Button
													onClick={handleDeleteReport}
													className={`${classes.buttonYes} ${classes.writePost} `}
												>
													<Typography className={classes.writePostText}>
														Да
													</Typography>
												</Button>
												<Button
													onClick={() => setDeleteAsk(!deleteAsk)}
													className={`${classes.buttonNo} ${classes.writePost} `}
												>
													<Typography className={classes.writePostText}>
														Нет
													</Typography>
												</Button>
											</div>
										</Paper>
									</>
								) : (
									<>
										<Button
											onClick={() => setDeleteAsk(!deleteAsk)}
											className={`${classes.buttonSecondary} ${classes.deletePost} `}
										>
											<DeleteIcon className={classes.writePostImg} />
											<Typography className={classes.writePostText}>
												Удалить отчет
											</Typography>
										</Button>
										<Button
											className={classes.buttonPrimaryWidth}
											onClick={() => setIsUpdateReport(!isUpdateReport)}
										>
											<UpdateIcon className={classes.writePostImg} />
											{isUpdateReport ? (
												<Typography className={classes.writePostText}>
													Вернуться
												</Typography>
											) : (
												<Typography className={classes.writePostText}>
													Изменить отчет
												</Typography>
											)}
										</Button>
									</>
								)}
							</>
						)}
						{isPlacesExist ? (
							<Button
								onClick={handleButtonGeoClick}
								className={`${classes.geographyButton}`}
							>
								<RoomIcon className={classes.writePostImg} />
								<Typography className={classes.writePostText}>
									Показать отчет на карте
								</Typography>
							</Button>
						) : null}
					</Grid>
				) : (
					<Grid item xs={12} sm={12} md={2}>
						<Paper className={`${classes.postCard} ${classes.CircularProfile}`}>
							<CircularProgress color="secondary" size="3rem" />
						</Paper>
					</Grid>
				)}
				<Grid item xs={12} sm={12} md={10}>
					{post ? (
						isUpdateReport ? (
							<ReportUpdate post={post} />
						) : createPost ? (
							<Paper className={classes.paperPost}>
								<PostCreate
									post={post}
									createPost={createPost}
									setCreatePost={setCreatePost}
									handleCreatePost={handleCreatePost}
								/>
							</Paper>
						) : (
							<>
								<Paper className={classes.paperPost}>
									<div className={classes.card}>
										<div className={classes.section}>
											<Typography
												variant="h4"
												component="h2"
												className={classes.sectionTitle}
											>
												{post.title}
											</Typography>
											<Box className={classes.flexTagBox}>
												{post.tags.map((tag) => (
													<Box className={classes.tagBox}>
														<Typography variant="subtitle2" component="h2">
															{tag}
														</Typography>
													</Box>
												))}
											</Box>
											<Typography
												gutterBottom
												variant="body1"
												component="p"
												className={classes.sectionMessage}
											>
												{post.message}
											</Typography>
										</div>
										<div className={classes.imageSection}>
											<img
												className={classes.media}
												src={
													post.selectedFile ||
													"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
												}
												alt={post.title}
											/>
										</div>
									</div>
								</Paper>
								<Paper className={classes.paperPost}>
									<PostReport post={post} />
									{user?.result?._id === post?.creator && (
										<Button
											onClick={() => setCreatePost(!createPost)}
											className={`${classes.buttonPrimary} ${classes.writePost} `}
										>
											<CreateIcon className={classes.writePostImg} />
											<Typography className={classes.writePostText}>
												Написать пост
											</Typography>
										</Button>
									)}
								</Paper>
								<div id="yandex_rtb_R-A-6633212-2"></div>
								<CommentSection post={post} />
								{recommendedPosts.length ? (
									<Paper className={classes.paperPost}>
										<div className={classes.section}>
											<Typography gutterBottom variant="h5">
												Вам также могут понравиться
											</Typography>
											<div className={classes.recommendedPosts}>
												{recommendedPosts.map(
													({
														title,
														message,
														name,
														likes,
														selectedFile,
														_id,
													}) => (
														<div
															className={classes.recommendedPostsBox}
															onClick={() => openPost(_id)}
															key="_id"
														>
															<img
																alt=""
																src={
																	selectedFile ||
																	"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
																}
																width="100%"
																className={classes.recommendedImg}
															/>
															<Typography gutterBottom variant="h6">
																{title}
															</Typography>
															<Typography gutterBottom variant="subtitle2">
																{name}
															</Typography>
															<div className={classes.recommendedLikes}>
																<ThumbUpAltOutlined
																	fontSize="small"
																	sx={{ mr: "4px" }}
																	className={classes.recommendedLikesImg}
																/>
																<Typography gutterBottom variant="subtitle1">
																	{likes.length}
																</Typography>
															</div>
														</div>
													)
												)}
											</div>
										</div>
									</Paper>
								) : null}
							</>
						)
					) : (
						<Paper className={`${classes.postCard} ${classes.CircularPost}`}>
							<CircularProgress color="secondary" size="7rem" />
						</Paper>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default ReportDetails;
