import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Typography,
  Container,
  TextField,
  Card,
  Grid,
  Box,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import useStyles from "./styles";
import RegionsCard from "./RegionsCard/RegionsCard.jsx";

const Regions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [numberReports, setNumberReports] = useState({
    russia: 0,
    europe: 0,
    asia: 0,
    africa: 0,
    northAmerica: 0,
    southAmerica: 0,
    australia: 0,
    antarctica: 0,
    other: 0,
  });

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" className={classes.regionTitle}>
        Выберите регион
      </Typography>
      <Grid
        className={classes.container}
        container
        alignItems="stretch"
        spacing={3}
      >
        <RegionsCard
          region="Россия"
          numberReportsRegion={numberReports?.russia}
          refRegion="russia"
        />
        <RegionsCard
          region="Европа"
          numberReportsRegion={numberReports?.europe}
          refRegion="europe"
        />
        <RegionsCard region="Азия" numberReportsRegion={numberReports?.asia} />
        <RegionsCard
          region="Африка"
          numberReportsRegion={numberReports?.africa}
          refRegion="africa"
        />
        <RegionsCard
          region="Северная Америка"
          numberReportsRegion={numberReports?.northAmerica}
          refRegion="northAmerica"
        />
        <RegionsCard
          region="Южная Америка"
          numberReportsRegion={numberReports?.southAmerica}
          refRegion="southAmerica"
        />
        <RegionsCard
          region="Австралия и Океания"
          numberReportsRegion={numberReports?.australia}
          refRegion="australia"
        />
        <RegionsCard
          region="Антарктида"
          numberReportsRegion={numberReports?.antarctica}
          refRegion="antarctica"
        />
        <RegionsCard
          region="Другое"
          numberReportsRegion={numberReports?.other}
          refRegion="other"
        />
      </Grid>
    </Paper>
  );
};

export default Regions;
