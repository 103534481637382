import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Typography, Button, Card, Link, CardContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles.js";
import { switchIcons } from "../../../../../utils/placeUtils.js";

const PlacesListMenu = ({ city }) => {
	const classes = useStyles();

	return (
		<Card className={classes.legend}>
			<div className={classes.cardContent}>
				<Button
					className={classes.numberLinkTitle}
					href={`/place/${city?._id}`}
				>
					<img className={classes.numberText} src={switchIcons("city")} />
					<div className={classes.legendTitle}>{city?.placeName}</div>
				</Button>
				<Typography>Места в городе {city?.placeName}</Typography>
				<Card className={classes.postsLinkContainer}>
					<Button
						className={classes.numberLink}
						href={`/place/${city?._id}/museum`}
					>
						<div className={classes.numberContainer}>
							<img className={classes.numberText} src={switchIcons("museum")} />
						</div>
						<div className={classes.linkList}>Музеи</div>
					</Button>
				</Card>
				<Card className={classes.postsLinkContainer}>
					<Button
						className={classes.numberLink}
						href={`/place/${city?._id}/restaurant`}
					>
						<div className={classes.numberContainer}>
							<img
								className={classes.numberText}
								src={switchIcons("restaurant")}
							/>
						</div>
						<div className={classes.linkList}>Рестораны</div>
					</Button>
				</Card>
        <Card className={classes.postsLinkContainer}>
					<Button
						className={classes.numberLink}
						href={`/place/${city?._id}/site`}
					>
						<div className={classes.numberContainer}>
							<img className={classes.numberText} src={switchIcons("site")} />
						</div>
						<div className={classes.linkList}>Достопримечательности</div>
					</Button>
				</Card>
				<Card className={classes.postsLinkContainer}>
					<Button
						className={classes.numberLink}
						href={`/place/${city?._id}/hotel`}
					>
						<div className={classes.numberContainer}>
							<img className={classes.numberText} src={switchIcons("hotel")} />
						</div>
						<div className={classes.linkList}>Отели</div>
					</Button>
				</Card>
			</div>
		</Card>
	);
};

export default PlacesListMenu;
