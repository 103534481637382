import {
  FETCH_RANDOM,
  START_LOADING_MAIN,
  END_LOADING_MAIN,
} from "../constants/actionTypes";

const mainReducer = (state = { isLoading: true, randomPost: [] }, action) => {
  switch (action.type) {
    case START_LOADING_MAIN:
      return { ...state, isLoading: true };
    case END_LOADING_MAIN:
      return { ...state, isLoading: false };
    case FETCH_RANDOM:
      return {
        ...state,
        randomPost: action.payload,
      };
    default:
      return state;
  }
};

export default mainReducer;
