import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	map: {
		width: "500px",
		height: "500px",
		backgroundColor: "#333333",
	},
	betaTest: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		display: "flex",
		alignItems: "center",
		backgroundColor: "rgba(236,98,98,1)",
		color: "white",
		margin: "0 0 0 auto",
		"@media (max-width: 600px)": {
			width: "100%",
		},
	},
	textMapBox: {
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width: 600px)": {
			flexDirection: "column",
		},
	},
	textChoose: {},
	returnButton: {},
	searchResultsContainer: {
		position: "relative",
	},
	searchResults: {
		position: "absolute",
		top: "100%", // Position below the TextField
		left: 0,
		zIndex: 999, // Ensure it's above other content
		backgroundColor: "#fff", // Optional: Set background color
		border: "1px solid #ccc", // Optional: Add a border
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow
	},
}));
