import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  legend: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "15px",
  },
  legendTitle: {
    fontSize: "14px",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  postContainer: {
    display: "flex",
  },
  postsLinkOuterContainer: {
    display: "flex",
  },
  postsLinkContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "45px",
    margin: "0",
    width: "100%",
    margin: "0 5px 10px 5px",
  },
  numberContainer: {
    backgroundColor: "rgba(236,98,98,1)",
    minWidth: "30px",
    height: "30px",
    marginLeft: "5px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  numberText: {
    color: "rgba(255,255,255,1)",
  },
  numberLink: {
    textDecoration: "none",
    padding: "0",
  },
  numberLinkTitle: {
    textDecoration: "none",

    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    height: "40px",
    width: "70%",
    margin: "10px auto 10px 5px",
    borderRadius: "20px",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "72%",
    },
  },
  linkList: {
    margin: "0 10px",
  },
}));
