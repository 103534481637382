import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles.js";
import {
	getFriends,
	getSubscribers,
	getUsers,
} from "../../../actions/users.js";

const PaginationProfileSmall = ({ page, search, urlType, urlLogin }) => {
	let numberOfPages = useSelector((state) => state.users?.numberOfPages);
	numberOfPages = numberOfPages ? numberOfPages : 1;
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (urlType === "friends" && page) {
			dispatch(getFriends(page, search, urlLogin));
		} else if (urlType === "subscribers" && page) {
			dispatch(getSubscribers(page, search, urlLogin));
		} else if (page) {
			dispatch(getUsers(page, search));
		}
	}, [page]);

	return (
		<Pagination
			classes={{ ul: classes.ul }}
			count={numberOfPages}
			page={Number(page) || 1}
			variant="outlined"
			color="secondary"
			renderItem={(item) => (
				<PaginationItem
					{...item}
					component={Link}
					to={`/users?page=${item.page}`}
				/>
			)}
		/>
	);
};

export default PaginationProfileSmall;
