import React from "react";
import { Typography, Button, Card, Link } from "@material-ui/core";

import useStyles from "./styles";

const PostLegend = (legendProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.legend}>
      <Button
        className={classes.numberLinkTitle}
        href={`/posts/${legendProps?.legendProps?.postContentFull[0].reportId}`}
      >
        <div className={classes.legendTitle}>Вернуться в отчет</div>
      </Button>
      {legendProps?.legendProps?.postsInfo.map((c, i) => (
        <Card className={classes.postsLinkContainer}>
          <Button className={classes.numberLink} href={`/post/${c._id}`}>
            <div className={classes.numberContainer}>
              <Typography className={classes.numberText}>{i + 1}</Typography>
            </div>
            <div className={classes.linkList}>{c.title}</div>
          </Button>
        </Card>
      ))}
    </Card>
  );
};

export default PostLegend;
