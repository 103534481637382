import React, { Component } from "react";
import ErrorPage from "../ErrorPages/ErrorPage"; // Import your ErrorPage component

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// You can log the error to an error reporting service here
		console.error(error, errorInfo);
		this.setState({ errorInfo });
	}

	render() {
		if (this.state.hasError) {
			const errorDetails = this.state.errorInfo
				? this.state.errorInfo.componentStack
				: "No additional information available";
			return (
				<ErrorPage
					errorMessage={this.state.error.message}
					errorDetails={errorDetails}
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
