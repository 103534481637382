import React, { useState, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	Container,
	Grow,
	Grid,
	Paper,
	TextField,
	Button,
	Box,
	Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPostsBySearch } from "../../actions/posts";
import useStyles from "./styles";
import Select from "react-select";
import countryList from "react-select-country-list";
import SearchIcon from "@material-ui/icons/Search";

import Posts from "../Posts/Posts";
import Pagination from "../Pagination";
import Form from "../Form/Form";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Home = () => {
	const classes = useStyles();
	const [currentId, setCurrentId] = useState(0);
	const dispatch = useDispatch();
	const query = useQuery();
	const history = useHistory();
	const page = query.get("page") || 1;
	const searchQuery = query.get("searchQuery");
	const options = useMemo(() => countryList().getData(), []);
	const [search, setSearch] = useState("");
	const [tags, setTags] = useState([]);
	const [country, setCountry] = useState("");
	const [showText, setShowText] = useState(false);
	const [isWriteReport, setIsWriteReport] = useState(false);

	const searchCountries = (country) => {
		setCountry(country);
		let countryArr = country.map((c) => (c = c.label));
		setTags(countryArr);
	};

	const searchPost = () => {
		if (search.trim() || tags) {
			dispatch(getPostsBySearch({ search, tags: tags.join(",") }));
			history.push(
				`/posts/search?searchQuery=${search} || "none"}&tags=${tags.join(",")}`
			);
		} else {
			history.push("/");
		}
	};

	const handleKeyPress = (e) => {
		if (e.keyCode === 13) {
			searchPost();
		}
	};

	const handleAdd = (tag) => setTags([...tags, tag]);

	const handleDelete = (tagToDelete) =>
		setTags(tags.filter((tag) => tag !== tagToDelete));

	return (
		<>
			<Helmet>
				<title>Поиск по отчетам - ToTravelRu</title>
				<meta
					name="description"
					content="Поиск по отчетам о путешествиях по всему миру: Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия - ToTravelRu"
				/>
				<meta
					name="keywords"
					content="ToTravelRu, путешествия, отчеты, посты, отчеты о путешествиях, поиск, поиск отчетов, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия"
				/>
			</Helmet>
			<Grow in>
				<Container className={classes.container} maxWidth="xl">
					<Box className={classes.appBarSearch} elevation={0}>
						<div className={classes.searchContainer}>
							<TextField
								size="small"
								name="search"
								variant="outlined"
								label="Поиск"
								onKeyPress={handleKeyPress}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className={classes.searchContainerText}
							/>
							<Select
								options={options}
								value={country}
								onChange={searchCountries}
								styles={{
									container: (styles) => ({
										...styles,
										minHeight: "40px",
										marginRight: "10px",
										minWidth: "190px",
										"@media (max-width: 600px)": {
											marginRight: "0",
											marginBottom: "5px",
										},
									}),
									control: (styles) => ({
										...styles,
										fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
										minHeight: "40px",
									}),
									menu: (styles) => ({
										...styles,
										zIndex: "999",
									}),
									option: (styles) => ({
										...styles,
										fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
									}),
								}}
								isMulti
								placeholder="Выберите страну"
							/>
							<Button
								onClick={searchPost}
								className={classes.searchButton}
								color="primary"
								variant="contained"
							>
								<SearchIcon />
								<Typography className={classes.searchButtonText}>
									Поиск
								</Typography>
							</Button>
						</div>
					</Box>
					<Grid
						container
						justifyContent="space-between"
						alignItems="stretch"
						spacing={2}
						className={classes.gridContainer}
					>
						<Grid item xs={12} sm={6} md={3}>
							<Form currentId={currentId} setCurrentId={setCurrentId} />
							{!searchQuery && !tags.length && (
								<Paper elevation={0} className={classes.pagination}>
									<Pagination page={page} />
								</Paper>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={9} className={classes.postGrid}>
							<Posts setCurrentId={setCurrentId} />
							{/*<div className={classes.sticky}>
              <Button
                className={classes.buttonWrite}
                onMouseOver={() => setShowText(true)}
                onMouseOut={() => setShowText(false)}
                onClick={() => setIsWriteReport(true)}
              >
                {showText ? (
                  <Typography className={classes.hiddenText}>
                    Написать отчет
                  </Typography>
                ) : null}
                <CreateIcon className={classes.buttonWriteImg} />
                </Button>
            </div>*/}
						</Grid>
					</Grid>
				</Container>
			</Grow>
		</>
	);
};

export default Home;
