import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box"; // Import Box component

const telegramLink = "https://t.me/totravelrubot";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "84vh",
		backgroundColor: theme.palette.background.default,
	},
	heading: {
		fontSize: "2rem",
		color: theme.palette.error.main,
		marginBottom: theme.spacing(2),
	},
	text: {
		fontSize: "1.2rem",
		color: theme.palette.text.primary,
	},
	link: {
		textDecoration: "none",
		color: "black",
		fontWeight: "bold",
	},
	box: {
		border: "1px solid black",
		borderColor: "rgba(236,98,98,1)",
		padding: theme.spacing(1),
		marginTop: theme.spacing(1),
		borderRadius: "15px",
	},
}));

const ErrorPage = ({ errorMessage, errorDetails }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography variant="h1" className={classes.heading}>
				Что-то пошло не так. Попробуйте перезагрузить страницу.
			</Typography>
			<Typography variant="body1" className={classes.text}>
				{errorMessage}
			</Typography>
			{errorDetails && (
				<Typography variant="body1" className={classes.text}>
					{errorDetails}
				</Typography>
			)}
			<Box className={classes.box}>
				<Typography variant="body1" className={classes.text}>
					Напишите нам в Телеграм-бот{" "}
					<a href={telegramLink} className={classes.link}>
						@totravelrubot
					</a>
				</Typography>
			</Box>
		</div>
	);
};

export default ErrorPage;
