import React from "react";
import { makeStyles } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";

const useStyles = makeStyles((theme) => ({
	starIcons: {
		display: "flex",
		justifyContent: "center",
	},
}));

const StarRating = ({ rating }) => {
	const classes = useStyles();
	const fullStars = Math.floor(rating);
	const hasHalfStar = rating - fullStars >= 0.5;
	const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

	const starStyle = {
		width: "18px",
		height: "18px",
		fill: "rgb(255,199,65)",
	};

	return (
		<div className={classes.starIcons}>
			{[...Array(fullStars)].map((_, index) => (
				<StarIcon key={index} style={starStyle} />
			))}
			{hasHalfStar && <StarHalfIcon style={starStyle} />}
			{[...Array(emptyStars)].map((_, index) => (
				<StarBorderIcon key={index} style={starStyle} />
			))}
		</div>
	);
};

export default StarRating;
