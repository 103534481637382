import React from "react";
import { Placemark } from "@pbe/react-yandex-maps";

const PlacemarkMemo = React.memo(
	({ geometry, options, properties, onClick }) => {
		return (
			<Placemark
				geometry={geometry}
				options={options}
				properties={properties}
				onClick={onClick}
			/>
		);
	}
);

export default PlacemarkMemo;
