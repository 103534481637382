import React, { useState, useRef, useEffect } from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";

import useStyles from "./styles";
import {
  commentPost,
  likeCommentReport,
  sendResponseCommentReport,
} from "../../actions/posts";

const CommentSection = ({ post }) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const initialComments = post ? post?.comments : "";
  const [comments, setComments] = useState(
    initialComments === null ? null : initialComments
  );
  const [response, setResponse] = useState("");
  const [isResponseToResponse, setIsResponseToResponse] = useState(false);
  const [numberResponsePost, setNumberResponsePost] = useState("");
  const [numberResponseResponse, setNumberResponseResponse] = useState("");

  useEffect(() => {
    setComments(initialComments);
  }, [initialComments]);

  const user = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const commentsRef = useRef();

  const handleClick = async () => {
    const finalComment = {
      userId: user.result._id,
      userLogin: user.result.login,
      userName: user.result.name,
      userType: user?.result?.userType,
      text: comment,
      createdAt: new Date().toISOString(),
      likes: [],
      subcomments: [],
    };

    const newComments = await dispatch(commentPost(finalComment, post._id));
    setComments(newComments);
    setComment("");

    //commentsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleLike = async (value, number) => {
    const likeInfo = {
      reportId: post?._id,
      commentNumber: number,
      user: user?.result?._id,
    };
    await dispatch(likeCommentReport(likeInfo));
    if (value?.likes.find((like) => like === user?.result?._id)) {
      const commentsArrayFilter = comments;
      commentsArrayFilter[number].likes.filter(
        (id) => id !== (user?.result?.googleId || user?.result?._id)
      );
      setComments(commentsArrayFilter);
    } else {
      const commentsArrayPush = comments;
      commentsArrayPush[number].likes.push(likeInfo.user);
      setComments(commentsArrayPush);
    }
  };

  const toggleCommentResponse = async (value, number, numberResponse) => {
    setNumberResponsePost(number);
    value.addresseeId
      ? setIsResponseToResponse(true)
      : setIsResponseToResponse(false);
    value.addresseeId
      ? setNumberResponseResponse(numberResponse)
      : setNumberResponseResponse("");
    setResponse(`${value?.userName.split(" ")[0]}, `);
  };

  const handleResponse = async (value, number) => {
    const finalResponse = {
      userId: user.result._id,
      userLogin: user.result.login,
      userName: user.result.name,
      userType: user?.result?.userType,
      text: response,
      addresseeId: value?.userId,
      addresseeLogin: value?.userLogin,
      createdAt: new Date().toISOString(),
      likes: [],
      initialCommentNumber: number,
    };

    const newComments = await dispatch(
      sendResponseCommentReport(finalResponse, post._id)
    );

    setComments(newComments);
    setResponse("");
    setNumberResponsePost("");

    //commentsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={classes.commentsOuterContainer}>
      <div className={classes.commentsInnerContainer}>
        <Typography gutterBottom variant="h6">
          Комментарии
        </Typography>
        {comments ? (
          comments.length === 0 ? (
            <Typography>Комментариев пока нет</Typography>
          ) : (
            comments.map((c, i) => (
              <div>
                <div>
                  <div
                    className={`${classes.flex} ${classes.alignCenter} ${classes.mobileComment} ${classes.marginBottom}`}
                  >
                    <div className={classes.profileTimeBox}>
                      {c.userType === undefined ? (
                        <Link
                          className={classes.nameBox}
                          to={`/user/${c.userLogin}`}
                        >
                          <Typography
                            key={i}
                            variant="subtitle1"
                            className={classes.userName}
                          >
                            {c.userName}
                          </Typography>
                          <Hidden smDown>
                            <Typography
                              key={i}
                              variant="subtitle1"
                              className={classes.userLogin}
                            >
                              @{c.userLogin}
                            </Typography>
                          </Hidden>
                        </Link>
                      ) : (
                        <Link
                          className={classes.nameBoxSpecial}
                          to={`/user/${c.userLogin}`}
                        >
                          <Typography
                            key={i}
                            variant="subtitle1"
                            className={classes.userName}
                          >
                            {c.userName}
                          </Typography>
                          <Hidden smDown>
                            <Typography
                              key={i}
                              variant="subtitle1"
                              className={classes.userLogin}
                            >
                              @{c.userLogin}
                            </Typography>
                          </Hidden>
                        </Link>
                      )}
                      <Typography className={classes.momentComment}>
                        {moment(c.createdAt).fromNow()}
                      </Typography>
                    </div>
                    <div className={classes.commentTextBox}>
                      <Typography key={i} className={classes.commentText}>
                        {c.text}
                      </Typography>
                    </div>
                    <div className={classes.commentButtons}>
                      <Button
                        className={`${classes.flex} ${classes.likesBox}`}
                        onClick={() => handleLike(c, i)}
                      >
                        <Typography className={classes.likesNumber}>
                          {c?.likes ? c?.likes.length : 0}
                        </Typography>
                        {c.likes.find((like) => like === user?.result?._id) ? (
                          <FavoriteIcon />
                        ) : (
                          <FavoriteBorderIcon />
                        )}
                      </Button>
                      <Button
                        className={`${classes.flex} ${classes.likesBox}`}
                        onClick={() => toggleCommentResponse(c, i)}
                      >
                        <ReplyOutlinedIcon />
                      </Button>
                    </div>
                  </div>
                </div>
                {comments[i].subcomments
                  ? comments[i].subcomments.map((c, a) => (
                      <>
                        <div
                          className={`${classes.flex} ${classes.alignCenter} ${classes.marginBottom}`}
                        >
                          <div className={classes.emptySpaceResponse}></div>

                          <div
                            className={`${classes.responseContainer} ${classes.commentTextBox}`}
                          >
                            <div className={classes.profileTimeBoxResponse}>
                              {c.userType === undefined ? (
                                <Link
                                  className={classes.nameBoxResponse}
                                  to={`/user/${c.userLogin}`}
                                >
                                  <Typography
                                    key={a}
                                    variant="subtitle1"
                                    className={classes.userName}
                                  >
                                    {c.userName}
                                  </Typography>
                                </Link>
                              ) : (
                                <Link
                                  className={classes.nameBoxSpecialResponse}
                                  to={`/user/${c.userLogin}`}
                                >
                                  <Typography
                                    key={a}
                                    variant="subtitle1"
                                    className={classes.userName}
                                  >
                                    {c.userName}
                                  </Typography>
                                </Link>
                              )}
                              <Typography
                                className={classes.momentCommentResponse}
                              >
                                {moment(c.createdAt).fromNow()}
                              </Typography>
                            </div>
                            <Typography
                              key={a}
                              className={classes.commentTextResponse}
                            >
                              {c.text}
                            </Typography>
                          </div>
                          <div className={classes.commentButtons}>
                            <Button
                              className={`${classes.flex} ${classes.likesBox}`}
                              onClick={() => toggleCommentResponse(c, i, a)}
                            >
                              <ReplyOutlinedIcon />
                            </Button>
                          </div>
                        </div>
                        {numberResponsePost === i &&
                        numberResponseResponse === a &&
                        isResponseToResponse === true ? (
                          <div className={classes.responseBox}>
                            <Typography gutterBottom variant="h6">
                              Написать ответ @{c.userLogin}
                            </Typography>
                            <TextField
                              fullWidth
                              rows={4}
                              variant="outlined"
                              label="Ответ"
                              multiline
                              value={response}
                              onChange={(e) => setResponse(e.target.value)}
                              inputProps={{ maxLength: 3500 }}
                            />
                            <Button
                              style={{ marginTop: "10px" }}
                              fullWidth
                              disabled={!response}
                              variant="contained"
                              className={classes.buttonPrimary}
                              onClick={() => handleResponse(c, i)}
                            >
                              Опубликовать
                            </Button>
                          </div>
                        ) : null}
                      </>
                    ))
                  : null}
                {numberResponsePost === i && isResponseToResponse === false ? (
                  <div className={classes.responseBox}>
                    <Typography gutterBottom variant="h6">
                      Написать ответ @{c.userLogin}
                    </Typography>
                    <TextField
                      fullWidth
                      rows={4}
                      variant="outlined"
                      label="Ответ"
                      multiline
                      value={response}
                      onChange={(e) => setResponse(e.target.value)}
                      inputProps={{ maxLength: 3500 }}
                    />
                    <Button
                      style={{ marginTop: "10px" }}
                      fullWidth
                      disabled={!response}
                      variant="contained"
                      className={classes.buttonPrimary}
                      onClick={() => handleResponse(c, i)}
                    >
                      Опубликовать
                    </Button>
                  </div>
                ) : null}
              </div>
            ))
          )
        ) : (
          <CircularProgress color="secondary" size="3rem" />
        )}
        <div ref={commentsRef} />
      </div>
      {user?.result?.name && (
        <div style={{ width: "100%" }}>
          <Typography gutterBottom variant="h6">
            Написать комментарий
          </Typography>
          <TextField
            fullWidth
            rows={4}
            variant="outlined"
            label="Комментарий"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            inputProps={{ maxLength: 3500 }}
          />
          <Button
            style={{ marginTop: "10px" }}
            fullWidth
            disabled={!comment}
            variant="contained"
            className={classes.buttonPrimary}
            onClick={handleClick}
          >
            Опубликовать
          </Button>
        </div>
      )}
    </div>
    /*<div>
      <div className={classes.commentsOuterContainer}>
        <div className={classes.commentsInnerContainer}>
          <Typography gutterBottom variant="h6">
            Комментарии
          </Typography>
          {comments.map((c, i) => (
            <Typography key={i} gutterBottom variant="subtitle1">
              {c}
            </Typography>
          ))}
          <div ref={commentsRef} />
        </div>
        {user?.result?.name && (
          <div style={{ width: "50%" }}>
            <Typography gutterBottom variant="h6">
              Написать комментарий
            </Typography>
            <TextField
              fullWidth
              rows={4}
              variant="outlined"
              label="Комментарий"
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              style={{ marginTop: "10px" }}
              fullWidth
              className={classes.buttonPrimary}
              disabled={!comment}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Опубликовать
            </Button>
          </div>
        )}
      </div>
    </div>*/
  );
};

export default CommentSection;
