import React, { useState, useMemo } from "react";
import useStyles from "./styles.js";
import {
	TextField,
	Button,
	Typography,
	Paper,
	CircularProgress,
} from "@material-ui/core";
import Select from "react-select";
import countryList from "react-select-country-list";
import PublishIcon from "@material-ui/icons/Publish";
import Resizer from "react-image-file-resizer";

const CreateAlbum = () => {
	const classes = useStyles();
	const [albumData, setAlbumData] = useState({
		title: "",
		description: "",
		countries: [],
		travelStartDate: "",
		travelEndDate: "",
		mainPhoto: "",
		visibility: "all", // Initial value for visibility
	});
	const options = useMemo(() => countryList().getData(), []);
	const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
	const [fileName, setFileName] = useState("");

	const visibilityOptions = [
		{ value: "all", label: "Все" },
		{ value: "friends", label: "Друзья" },
		{ value: "me", label: "Только я" },
	];

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				70,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const handleCountriesChange = (selectedOptions) => {
		const countries = selectedOptions
			? selectedOptions.map((opt) => opt.label)
			: [];
		setAlbumData({ ...albumData, countries });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Handle form submission logic here
		console.log(albumData);
		clear();
	};

	const clear = () => {
		setAlbumData({
			title: "",
			description: "",
			countries: [],
			travelStartDate: "",
			travelEndDate: "",
			mainPhoto: "",
		});
		setFileName("");
	};

	return (
		<Paper className={classes.paper} elevation={0}>
			<form
				autoComplete="off"
				noValidate
				className={`${classes.root} ${classes.form}`}
				onSubmit={handleSubmit}
			>
				<img
					width="40"
					height="40"
					src="https://img.icons8.com/sf-regular/EC6262/96/camera.png"
					alt="camera"
				/>
				<Typography variant="h6">Создать новый фотоальбом</Typography>
				<TextField
					name="title"
					variant="outlined"
					label="Название альбома"
					fullWidth
					value={albumData.title}
					className={classes.formType}
					inputProps={{
						maxLength: 100,
					}}
					onChange={(e) =>
						setAlbumData({ ...albumData, title: e.target.value })
					}
				/>
				<Typography>Выберите, кто может видеть альбом</Typography>
				<Select
					options={visibilityOptions}
					value={visibilityOptions.find(
						(option) => option.value === albumData.visibility
					)}
					onChange={(selectedOption) =>
						setAlbumData({ ...albumData, visibility: selectedOption.value })
					}
					styles={{
						container: (styles) => ({
							...styles,
							width: "100%",
							minHeight: "56px",
							marginTop: "8px",
						}),
						control: (styles) => ({
							...styles,
							fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							minHeight: "56px",
						}),
						option: (styles) => {
							return {
								...styles,
								fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							};
						},
					}}
					placeholder="Выберите, кто может видеть альбом"
				/>
				<Button
					variant="outlined"
					fullWidth
					onClick={() =>
						setAlbumData({
							...albumData,
							showAdditionalFields: !albumData.showAdditionalFields,
						})
					}
					style={{ marginBottom: "10px", marginTop: "10px" }}
				>
					{albumData.showAdditionalFields
						? "Скрыть дополнительную информацию"
						: "Добавить дополнительную информацию"}
				</Button>
				{albumData.showAdditionalFields && (
					<>
						<TextField
							name="description"
							variant="outlined"
							label="Описание"
							fullWidth
							multiline
							maxRows={4}
							value={albumData.description}
							className={classes.formType}
							inputProps={{
								maxLength: 500,
							}}
							onChange={(e) =>
								setAlbumData({ ...albumData, description: e.target.value })
							}
						/>

						<div
							style={{
								marginTop: "8px",
								display: "flex",
								gap: "8px",
								width: "100%",
							}}
						>
							<TextField
								name="travelStartDate"
								variant="outlined"
								label="Дата начала поездки"
								type="date"
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={albumData.travelStartDate}
								onChange={(e) =>
									setAlbumData({
										...albumData,
										travelStartDate: e.target.value,
									})
								}
							/>
							<TextField
								name="travelEndDate"
								variant="outlined"
								label="Дата окончания поездки"
								type="date"
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={albumData.travelEndDate}
								onChange={(e) =>
									setAlbumData({ ...albumData, travelEndDate: e.target.value })
								}
							/>
						</div>
						<Select
							options={options}
							value={options.filter((opt) =>
								albumData.countries.includes(opt.label)
							)}
							onChange={handleCountriesChange}
							styles={{
								container: (styles) => ({
									...styles,
									width: "100%",
									minHeight: "56px",
									marginTop: "8px",
								}),
								control: (styles) => ({
									...styles,
									fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
									minHeight: "56px",
								}),
								option: (styles) => {
									return {
										...styles,
										fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
									};
								},
							}}
							isMulti
							placeholder="Выберите страны"
						/>
						<div className={classes.fileInput}>
							<label className={classes.labelInput}>
								<input
									type="file"
									className={classes.imageInput}
									onChange={async (event) => {
										try {
											setIsLoadingPhoto(true);
											const file = event.target.files[0];
											const fileNameInput = event.target.files[0].name;
											const image = await resizeFile(file);
											//let yandexImage = await dispatch(addYandexImageAlbum(image));
											setAlbumData({ ...albumData, mainPhoto: image });
											setFileName(fileNameInput);
										} catch (err) {
											console.log(err);
										} finally {
											setIsLoadingPhoto(false);
										}
									}}
								/>
								{isLoadingPhoto ? null : <PublishIcon />}
								{isLoadingPhoto ? (
									<CircularProgress
										style={{ color: "white", margin: "0 auto" }}
										size="1rem"
									/>
								) : (
									<Typography className={classes.uploadText}>
										ЗАГРУЗИТЬ ОБЛОЖКУ
									</Typography>
								)}
							</label>
							{fileName && (
								<div className={classes.fileNameBox}>
									<Typography className={classes.fileText}>
										{fileName}
									</Typography>
								</div>
							)}
						</div>
					</>
				)}
				<Button
					className={classes.buttonPrimary}
					variant="contained"
					size="large"
					type="submit"
					fullWidth
				>
					Создать альбом
				</Button>
				<Button
					className={classes.buttonSecondary}
					variant="contained"
					size="small"
					onClick={clear}
					fullWidth
				>
					Очистить
				</Button>
			</form>
		</Paper>
	);
};

export default CreateAlbum;
