// src/utils/placeUtils.js

export const translatePlaceType = (placeType) => {
	switch (placeType) {
		case "city":
			return "Город";
		case "restaurant":
			return "Ресторан";
		case "hotel":
			return "Отель";
		case "site":
			return "Достопримечательность";
		case "park":
			return "Парк";
		case "theatre":
			return "Театр";
		case "transport":
			return "Прочий транспорт";
		case "culture":
			return "Культура";
		case "museum":
			return "Музей";
		case "airport":
			return "Аэропорт";
		case "railway":
			return "Вокзал";
		case "other":
			return "Другое";
		default:
			return "";
	}
};

export const switchIcons = (placeType) => {
	switch (placeType) {
		case "city":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png";
		case "restaurant":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/restaurant.png";
		case "hotel":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/bed.png";
		case "site":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/chichen-itza.png";
		case "park":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/forest.png";
		case "theatre":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/theatre-mask.png";
		case "transport":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/ground-transportation.png";
		case "culture":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/theatre-mask.png";
		case "museum":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/museum.png";
		case "airport":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/airport.png";
		case "railway":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/train.png";
		case "other":
			return "https://img.icons8.com/sf-regular/48/FFFFFF/more.png";
		default:
			return "";
	}
};

export const currencyChange = (placeType) => {
	switch (placeType) {
		case "RUB":
			return "₽";
		case "USD":
			return "$";
		case "EUR":
			return "€";
		case "CNY":
			return "¥";
		case "Other":
			return "у.е.";
		default:
			return "";
	}
};

export const translateRoomType = (placeType) => {
	switch (placeType) {
		case "single":
			return "Одноместный";
		case "double":
			return "Двухместный";
		case "family":
			return "Семейный";
		case "suit":
			return "Люкс";
		case "Other":
			return "Другой";
		default:
			return "";
	}
};

export const translatePlaceTypePlural = (placeType) => {
	switch (placeType) {
		case "city":
			return "Города";
		case "restaurant":
			return "Рестораны";
		case "hotel":
			return "Отели";
		case "site":
			return "Достопримечательности";
		case "park":
			return "Парки";
		case "theatre":
			return "Театры";
		case "transport":
			return "Прочий транспорт";
		case "culture":
			return "Культура";
		case "museum":
			return "Музеи";
		case "airport":
			return "Аэропорты";
		case "railway":
			return "Вокзалы";
		case "other":
			return "Другое";
		default:
			return "";
	}
};

export const translatePlaceTypePrepositionalCase = (placeType) => {
	switch (placeType) {
		case "city":
			return "Городе";
		case "restaurant":
			return "Ресторане";
		case "hotel":
			return "Отеле";
		case "site":
			return "Достопримечательности";
		case "park":
			return "Парке";
		case "theatre":
			return "Театре";
		case "transport":
			return "Прочем транспорте";
		case "culture":
			return "Культуре";
		case "museum":
			return "Музее";
		case "airport":
			return "Аэропорте";
		case "railway":
			return "Вокзале";
		case "other":
			return "Другом";
		default:
			return "";
	}
};

export const translateDayToRussian = (day) => {
	const daysTranslation = {
		monday: "Понедельник",
		tuesday: "Вторник",
		wednesday: "Среда",
		thursday: "Четверг",
		friday: "Пятница",
		saturday: "Суббота",
		sunday: "Воскресенье",
	};

	return daysTranslation[day.toLowerCase()] || day;
};
