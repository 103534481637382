import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { sendChangePassword } from "../../actions/auth.js";

import useStyles from "./styles";

const ChangePasswordAsk = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const isEmailSent = dispatch(sendChangePassword({ email: email }));
    console.log(isEmailSent);
  };

  const emailSent = useSelector((state) => state.auth?.msg);

  const isEmailSent = emailSent?.status === 200;

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Восстановить пароль</Typography>

        {isEmailSent ? (
          <Typography>Письмо отправлено</Typography>
        ) : (
          <>
            <Typography className={classes.textEmail} variant="subtitle1">
              Напишите свою почту:
            </Typography>
            <TextField
              name="email"
              label="Email"
              type="email"
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth
            />
            <Button onClick={handleSubmit} className={classes.buttonPrimary}>
              Восстановить пароль
            </Button>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ChangePasswordAsk;
