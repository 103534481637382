import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	flexHead: {
		display: "flex",
		alignItems: "center",
	},
	mediaBox: {
		width: "27%",
		marginRight: "3%",
		display: "flex",
		justifyContent: "center",
	},
	media: {
		width: "100%", // Ensures the image takes up the full width of the container
		height: 0, // To help with aspect ratio
		paddingTop: "56.25%", // This is for 16:9 aspect ratio; adjust if necessary
		objectFit: "cover", // Ensures the image is scaled to cover the container
		display: "block",
	},
	title: {
		width: "50%",
		lineHeight: "1.1",
		textDecoration: "none",
		color: "rgb(0,0,0)",
	},
	titleFull: {
		lineHeight: "1.1",
		textDecoration: "none",
		color: "rgb(0,0,0)",
	},
	rating: {
		width: "30%",
	},
	boxType: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "10px",
		marginRight: "5px",
	},
	boxTypeText: {
		color: "rgb(255,255,255)",
		marginRight: "10px",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 10px",
		color: "rgb(255,255,255)",
	},
	card: {
		margin: "5px 0",
	},
	buttonPrimarySmall: {
		borderRadius: "10px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "5px 0",
		padding: "2px 0",
		width: "100%",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	CardContent: {
		padding: "10px",
	},
}));
