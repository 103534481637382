import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
		width: "calc(100% - 10px)",
	},
	title: {
		textAlign: "center",
		marginBottom: theme.spacing(1),
	},
	avatar: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		margin: "0 auto",
	},
	rootNoFriends: {
		marginBottom: 0,
	},
	titleNoFriends: {
		marginBottom: 0,
	},
	subscriberLink: {
		textDecoration: "none",
		color: "rgb(0,0,0)",
	},
	subscriberName: {
		textAlign: "center",
		fontSize: "0.9rem",
	},
	keyboardIcon: {
		color: "rgb(256,256,256)",
	},
	boxKeyboardIcon: {
		width: "25px",
		height: "25px",
		backgroundColor: "rgb(238,98,98)",
		borderRadius: "12.5px",
		position: "absolute",
		right: "0",
		top: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	titleDiv: {
		position: "relative",
	},
}));
