import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	paper: {
		width: "calc(100%-theme.spacing(1))",
		maxWidth: "600px", // Restrict the maximum width on larger screens
		margin: "auto", // Center the form horizontally
		borderRadius: "15px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		padding: theme.spacing(1),
		backgroundColor: "rgba(255,255,255,0.9)",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
		backdropFilter: "blur(5px)",
	},
	form: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	formType: {
		margin: "8px 0",
	},
	fileInput: {
		width: "100%",
		margin: "8px 0",
	},
	buttonPrimary: {
		marginBottom: 10,
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		marginBottom: 10,
		backgroundColor: "rgba(70,70,70,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(100,100,100,1)",
		},
	},
	imageInput: {
		display: "none",
	},
	labelInput: {
		border: "1.5px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "6px 12px",
		cursor: "pointer",
		margin: "0",
		height: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	fileNameBox: {
		border: "1.5px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "5px",
		minHeight: "25px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "8px",
		padding: "6px 12px",
	},
	fileText: {
		textAlign: "center",
		fontSize: "12px",
	},
	uploadText: {
		textAlign: "center",
		fontSize: "12px",
		color: "rgba(255,255,255,1)",
	},
}));
