import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	postsOuterContainer: {},
	commentsInnerContainer: {
		display: "flex",
		marginBottom: "0.3rem",
	},

	gridContainer: {
		[theme.breakpoints.down("s")]: {
			flexDirection: "column",
		},
	},
	profileCard: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		padding: "10px",
	},
	divider: {
		backgroundColor: "rgb(182,182,182)",
		border: "none",
		height: "1px",
		width: "70%",
	},
	logo: {
		maxWidth: "1.2rem",
		color: "rgb(236,98,98)",
		marginRight: "8px",
	},
	notificationsLink: {
		display: "flex",
		justifyContent: "start",
	},
	notificationsTitle: {
		margin: "0 auto 10px auto",
	},
	notificationsType: {
		lineHeight: "1.1",
	},
	notificationsWrap: {
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "0 0 0 auto",
		minWidth: "20px",
		height: "20px",
		padding: "2px",
		borderRadius: "12px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	notificationsWrapGrey: {
		backgroundColor: "rgb(182,182,182)",
		color: "rgba(255,255,255,1)",
		margin: "0 0 0 auto",
		minWidth: "20px",
		height: "20px",
		padding: "2px",
		borderRadius: "12px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	cardNotification: {
		display: "flex",
		alignItems: "center",
		minHeight: "40px",
		padding: "5px",
		borderRadius: "10px",
		marginBottom: "10px",
		"@media (max-width: 960px)": {
			flexDirection: "column",
			alignItems: "start",
			position: "relative",
		},
	},
	viewedBox: {
		width: "5px",
		height: "5px",
		borderRadius: "2px",
		backgroundColor: "rgba(236,98,98,1)",
		"@media (max-width: 960px)": {
			position: "absolute",
		},
	},
	searchButton: {
		borderRadius: "20px",
		display: "flex",
		justifyContent: "space-evenly",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		margin: "0 0 0 auto",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
		"@media (max-width: 600px)": {
			margin: "0 auto",
			width: "100%",
		},
	},
	declineButton: {
		borderRadius: "20px",
		display: "flex",
		justifyContent: "space-evenly",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		margin: "0 0 0 auto",
		backgroundColor: "rgb(9,9,121)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
		"@media (max-width: 600px)": {
			margin: "0 auto",
			width: "100%",
		},
	},
	moment: {
		marginLeft: "5px",
	},
	gotComments: {
		marginLeft: "10px",
		"@media (max-width: 960px)": {
			marginLeft: "5px",
		},
	},
	gotCommentsUnviewed: {
		marginLeft: "5px",
		"@media (max-width: 600px)": {
			marginLeft: "5px",
		},
	},
	cardLoading: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		borderRadius: "15px",
		minHeight: "10vh",
		position: "relative",
		alignItems: "center",
	},
	pagination: {
		borderRadius: "15px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		marginTop: "10px",
		padding: "8px",
	},
	viewedButton: {
		marginTop: "10px",
		padding: "3px 0",
		width: "100%",
	},
	viewedText: {
		lineHeight: "1.3",
	},
	deleteButton: {
		backgroundColor: "rgb(9,9,121)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	deleteOneButton: {
		color: "rgba(236,98,98,1)",
		minWidth: "30px",
	},
}));
