import {
  FETCH_RANDOM,
  START_LOADING_MAIN,
  END_LOADING_MAIN,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const getRandomPost = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_MAIN });
    const { data } = await api.fetchRandomPost();
    dispatch({ type: FETCH_RANDOM, payload: data });
    dispatch({ type: END_LOADING_MAIN });
  } catch (error) {
    console.log(error.message);
  }
};
