import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	media: {
		borderRadius: "20px",
		objectFit: "cover",
		width: "100%",
		maxHeight: "600px",
	},
	card: {
		display: "flex",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexWrap: "wrap",
			flexDirection: "column",
		},
	},
	section: {
		borderRadius: "20px",
		flex: 1,
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
	},
	imageSection: {
		maxWidth: "50%",
		marginLeft: "20px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
			maxWidth: "100%",
		},
	},
	recommendedPosts: {
		display: "flex",
		flexWrap: "wrap",
	},
	loadingPaper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "20px",
		borderRadius: "15px",
		height: "39vh",
	},
	writePost: {
		margin: "5px 0 0 0",
		[theme.breakpoints.down("sm")]: {
			margin: "0 auto",
		},
	},
	deletePost: {
		margin: "10px 0 0 0",
	},
	CircularPost: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "70vh",
	},
	CircularProfile: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "15vh",
	},
	postCard: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "20px",
	},
	postCardDelete: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "10px",
		display: "flex",
		flexDirection: "column",
		marginTop: "10px",
	},
	yesNo: {
		display: "flex",
		justifyContent: "space-evenly",
	},
	gridContainer: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	paperPost: {
		borderRadius: "15px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		padding: "20px",
		marginBottom: "20px",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: "10px",
			marginBottom: "10px",
		},
		"@media (max-width: 600px)": {
			padding: "8px",
		},
	},
	writePostText: {
		fontSize: "14px",
	},
	writePostImg: {
		marginRight: "5px",
	},
	likeButtonClicked: {
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "15px",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
		width: "65%",
	},
	likeButtonNotClicked: {
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		color: "rgba(236,98,98,1)",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
			color: "rgba(255,255,255,1)",
		},
		width: "65%",
	},
	geographyButton: {
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		color: "rgba(236,98,98,1)",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
			color: "rgba(255,255,255,1)",
		},
	},
	calendarOuterContainer: {
		width: "30%",
		boxSizing: "border-box",
	},
	calendarLike: {
		display: "flex",
		justifyContent: "space-between",
	},
	commentsOuterContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "20px",
		marginTop: "10px",
		marginBottom: "20px",
		"@media (max-width: 600px)": {
			padding: "0",
			border: "none",
		},
	},
	commentsInnerContainer: {
		minHeight: "80px",
	},
	buttonPrimary: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		height: "40px",
		width: "100%",
		borderRadius: "20px",
		backgroundColor: "rgb(9,9,121)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	buttonYes: {
		height: "40px",
		width: "45%",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonNo: {
		height: "40px",
		width: "45%",
		borderRadius: "20px",
		backgroundColor: "rgb(9,9,121)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	nameBox: {
		height: "33px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 5px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "90%",
		justifyContent: "space-evenly",
		textDecoration: "none",
		"@media (max-width: 600px)": {
			//margin: "0px 5px",
			//position: "absolute",
			//top: "5px",
			//left: "0",
			height: "20px",
			width: "40%",
		},
	},
	nameBoxSpecial: {
		height: "33px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 5px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "90%",
		justifyContent: "space-evenly",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
		"@media (max-width: 600px)": {
			//margin: "0px 5px",
			//position: "absolute",
			//top: "5px",
			//left: "0",
			height: "20px",
			width: "40%",
		},
	},
	userName: {
		fontWeight: 700,
		fontSize: "14px",
		lineHeight: 1,
		textAlign: "center",
	},
	userLogin: {
		fontSize: "12px",
		lineHeight: 1,
	},
	flex: {
		display: "flex",
	},
	alignCenter: {
		alignItems: "center",
	},
	commentTextBox: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		width: "75%",
		padding: "3px 5px",
		minHeight: "40px",
		display: "flex",
		alignItems: "center",
		"@media (max-width: 600px)": {
			width: "80%",
			padding: "28px 5px 3px 5px",
		},
	},
	likesBox: {
		color: "rgba(236,98,98,1)",
		minWidth: "50px",
		"@media (max-width: 600px)": {
			padding: "0 4px",
			minWidth: "50px",
		},
	},
	likesNumber: {
		fontSize: "18px",
	},
	mobileComment: {
		"@media (max-width: 600px)": {
			position: "relative",
		},
	},
	marginBottom: {
		marginBottom: "10px",
	},
	commentText: {
		marginLeft: "5px",
		lineHeight: "1.1",
		"@media (max-width: 600px)": {
			fontSize: "0.9rem",
			lineHeight: "1.05",
		},
	},
	tagBox: {
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 7px",
		borderRadius: "10px",
		margin: "2px 5px 2px 0",
	},
	recommendedPostsBox: {
		margin: "10px",
		cursor: "pointer",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		width: "calc(25% - 32px)",
		padding: "5px",
		"@media (max-width: 900px)": {
			width: "calc(50% - 32px)",
		},
		"@media (max-width: 600px)": {
			width: "calc(100% - 32px)",
		},
	},
	recommendedImg: {
		borderRadius: "14px",
	},
	recommendedLikes: {
		display: "flex",
		alignItems: "center",
		color: "rgba(236,98,98,1)",
	},
	recommendedLikesImg: {
		marginRight: "5px",
	},
	sectionTitle: {
		"@media (max-width: 600px)": {
			fontSize: "1.6rem",
		},
	},
	sectionMessage: {
		"@media (max-width: 600px)": {
			fontSize: "0.95rem",
			lineHeight: "1.3",
		},
	},
	writePostText: {
		fontSize: "14px",
	},
	buttonPrimaryWidth: {
		marginTop: "10px",
		width: "100%",
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	flexTagBox: {
		display: "flex",
		flexWrap: "wrap",
	},
	commentButtons: {
		display: "flex",
		"@media (max-width: 600px)": {
			flexDirection: "column",
		},
	},
	responseBox: {
		marginBottom: "10px",
	},
	profileTimeBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "19%",
		marginRight: "5px",
		"@media (max-width: 1200px)": {
			width: "24%",
		},
		"@media (max-width: 900px)": {
			width: "29%",
		},
		"@media (max-width: 600px)": {
			margin: "0px 5px",
			position: "absolute",
			top: "5px",
			left: "0",
			width: "100%",
			height: "20px",
			display: "flex",
			flexDirection: "row",
		},
	},
	momentComment: {
		fontSize: "12px",
		textAlign: "center",
		"@media (max-width: 600px)": {
			marginLeft: "5px",
			textAlign: "left",
		},
	},
	responseContainer: {
		position: "relative",
	},
	profileTimeBoxResponse: {
		margin: "0px 5px",
		position: "absolute",
		top: "5px",
		left: "0",
		height: "20px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "60%",
		marginRight: "5px",
		"@media (max-width: 1200px)": {
			width: "70%",
		},
		"@media (max-width: 900px)": {
			width: "90%",
		},
		"@media (max-width: 600px)": {
			margin: "0px 5px",
			position: "absolute",
			top: "5px",
			left: "0",
			width: "100%",
			height: "20px",
			display: "flex",
			flexDirection: "row",
		},
	},
	nameBoxResponse: {
		height: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 5px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "40%",
		justifyContent: "space-evenly",
		marginRight: "5px",
		textDecoration: "none",
		"@media (max-width: 600px)": {
			width: "40%",
		},
	},
	nameBoxSpecialResponse: {
		height: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 5px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "40%",
		justifyContent: "space-evenly",
		textDecoration: "none",
		marginRight: "5px",
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
		"@media (max-width: 600px)": {
			width: "40%",
		},
	},
	emptySpaceResponse: {
		width: "19%",
		"@media (max-width: 1200px)": {
			width: "25%",
		},
		"@media (max-width: 900px)": {
			width: "30%",
		},
		"@media (max-width: 600px)": {
			width: "5%",
		},
	},
	momentCommentResponse: {
		fontSize: "12px",
	},
	commentTextResponse: {
		padding: "28px 5px 3px 5px",
		lineHeight: "1.1",
		"@media (max-width: 600px)": {
			padding: "0px 5px 3px 5px",
			fontSize: "0.9rem",
			lineHeight: "1.05",
		},
	},
	geographyButton: {
		width: "100%",
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		color: "rgba(236,98,98,1)",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
			color: "rgba(255,255,255,1)",
		},
	},
}));
