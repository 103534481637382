import React from "react";
import { Typography, Card, Avatar, ButtonBase } from "@material-ui/core";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";

const PostProfile = (profile) => {
  const classes = useStyles();
  const history = useHistory();
  const openPost = () =>
    (window.location.href = `/user/${profile?.profile.login}`);

  return (
    <Card className={classes.profileCard}>
      <ButtonBase
        component="span"
        name="test"
        onClick={openPost}
        className={classes.profileLink}
      >
        <Avatar
          className={classes.profilePicture}
          alt={profile?.profile.name}
          src={profile?.profile.picture}
        >
          {profile.profile.name ? profile?.profile.name.charAt(0) : ""}
        </Avatar>
        <div className={classes.profileName}>
          <Typography variant="h6" className={classes.profileNameText}>
            {profile?.profile.name}
          </Typography>
          <Typography className={classes.profileNameLogin}>
            @{profile?.profile.login}
          </Typography>
        </div>
        {profile?.profile?.userType === "admin" ? (
          <Typography className={classes.adminSponsorLabel}>
            Администратор
          </Typography>
        ) : profile?.profile?.userType === "sponsor" ? (
          <Typography className={classes.adminSponsorLabel}>Спонсор</Typography>
        ) : profile?.profile?.userType === "traveler" ? (
          <Typography className={classes.adminSponsorLabel}>
            Путешественник
          </Typography>
        ) : profile?.profile?.userType === "author" ? (
          <Typography className={classes.adminSponsorLabel}>Автор</Typography>
        ) : null}
      </ButtonBase>
    </Card>
  );
};

export default PostProfile;
