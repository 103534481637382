import {
	ADD_PLACE,
	GET_PLACE,
	GET_PLACES,
	GET_PLACES_BY_CITY,
	START_LOADING_PLACES,
	END_LOADING_PLACES,
	CHECK_PLACES_EXIST,
	SEARCH_PLACES,
	START_LOADING_PLACES_SEARCH,
	END_LOADING_PLACES_SEARCH,
	ADD_POST_TO_PLACE,
	ADD_PLACE_DESCRIPTION,
	GET_PLACE_DESCRIPTION,
	VERIFY_PLACE_DESCRIPTION,
	ADD_CITY_TO_PLACE,
} from "../constants/actionTypes";

const placeReducer = (
	state = {
		place: null,
		places: [],
		placesSearch: [],
		placesByCity: [],
		isLoading: true,
		isLoadingSearch: true,
		placesExist: false,
		boolean: false,
		placeDescription: [],
	},
	action
) => {
	switch (action.type) {
		case START_LOADING_PLACES:
			return { ...state, isLoading: true };
		case END_LOADING_PLACES:
			return { ...state, isLoading: false };
		case START_LOADING_PLACES_SEARCH:
			return { ...state, isLoadingSearch: true };
		case END_LOADING_PLACES_SEARCH:
			return { ...state, isLoadingSearch: false };
		case ADD_PLACE:
		case ADD_POST_TO_PLACE:
		case ADD_CITY_TO_PLACE:
		case GET_PLACE:
			return { ...state, place: action.payload, placesByCity: [] };
		case GET_PLACES:
			return { ...state, places: action.payload };
		case GET_PLACES_BY_CITY:
			return {
				...state,
				placesByCity: [...state.placesByCity, ...action.payload.data],
				numberOfPages: action.payload.numberOfPages,
			};
		case SEARCH_PLACES:
			return { ...state, placesSearch: action.payload };
		case CHECK_PLACES_EXIST:
			return { ...state, placesExist: action.payload?.placesExist };
		case ADD_PLACE_DESCRIPTION:
			return { ...state, boolean: action.payload };
		case GET_PLACE_DESCRIPTION:
			return { ...state, placeDescription: action.payload };
		case VERIFY_PLACE_DESCRIPTION:
			const updatedPlaceDescription = state.placeDescription.filter(
				(description) => description._id !== action.payload
			);
			return { ...state, placeDescription: updatedPlaceDescription };
		default:
			return state;
	}
};

export default placeReducer;
