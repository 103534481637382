import React from "react";
import { Helmet } from "react-helmet";

const Agreement = () => {
	return (
		<>
			<Helmet>
				<title>Пользовательское соглашение - ToTravelRu</title>
			</Helmet>
			<div
				style={{
					textAlign: "start",
					color: "rgb(33, 37, 41)",
					backgroundColor: "rgb(254, 254, 254)",
					fontSize: 16,
				}}
			>
				<div style={{ color: "rgb(33, 37, 41)" }}>
					<h4 style={{ color: "rgb(33, 37, 41)", fontSize: 25 }}>
						<strong style={{ color: "rgb(33, 37, 41)" }}>
							Пользовательское соглашение
						</strong>
					</h4>
				</div>
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				Пользовательское соглашение (далее — «Соглашение» или «ПС») вступает в
				силу с момента Вашего согласия с его условиями при регистрации на
				Totravel.
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>1. Предмет&nbsp;Соглашения</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				Пользовательское Соглашение является юридически обязывающим договором и
				регламентирует отношения между Администрацией интернет-ресурса Totravel,
				расположенного по адресу{" "}
				<u>
					<a
						href="http://www.totravelru.ru/"
						style={{ color: "var(--theme-link)" }}
					>
						http://
					</a>
					totravelru.ru
				</u>
				, (Далее — «Администрация» или «Totravel») и физическим лицом (далее —
				«Пользователь»), использующим сайт для получения, публикации или поиска
				информации.
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>2. Описание&nbsp;услуг</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				2.1. Totravel является публичным информационно-коммуникационным сервисом
				и предоставляет свои услуги на условиях, являющихся предметом настоящего
				Пользовательского Соглашения. Соглашение может быть изменено нами без
				какого-либо специального уведомления, новая редакция Соглашения вступает
				в силу по истечение 3 (трёх) дней с момента её размещения, если иное не
				предусмотрено новой редакцией Соглашения. Действующая редакция ПС
				располагается по адресу{" "}
				<u>
					<a
						href="http://www.totravelru.ru/agreement/"
						style={{ color: "var(--theme-link)" }}
					>
						http://www.totravelru.ru/agreement/
					</a>
				</u>
				.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				2.2. После регистрации Вы&nbsp;получите в&nbsp;личное распоряжение
				дисковое пространство для размещения текстов, фотоматериалов, сообщений
				или информации, касающейся или ссылающейся на&nbsp;программы, музыку,
				звуки, фотографии, графические изображения или видео (далее&nbsp;—
				«Содержание»). Содержание размещается
				на&nbsp;серверах&nbsp;Администрации.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				2.3. Вы понимаете и соглашаетесь с тем, что все услуги предоставляются
				«как есть» и что Totravel не несёт ответственности ни за какие задержки,
				сбои, неверную или несвоевременную доставку, удаление или несохранность
				какой-либо пользовательской персональной информации.&nbsp;
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>3. Ваши обязательства&nbsp;по&nbsp;регистрации</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				3.1. Для того чтобы воспользоваться услугами ресурса Totravel, Вы
				соглашаетесь предоставить правдивую, точную и полную информацию о себе
				по вопросам, предлагаемым в Регистрационной форме, и поддерживать эту
				информацию в актуальном состоянии.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				3.2. Если Вы предоставляете неверную информацию или у Администрации
				имеются серьёзные основания полагать, что предоставленная Вами
				информация неверна, неполна или неточна, Администрация имеет право
				приостановить либо отменить Вашу регистрацию и отказать Вам в
				использовании интернет-ресурса Totravel (либо его части) без
				предварительного уведомления и объяснения причин.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				3.3. В процессе регистрации Вы указываете логин и пароль для доступа к
				персонализированной части Totravel. Вы несёте ответственность за
				безопасность Вашего логина и пароля, а также за всё, что будет сделано
				на Totravel под Вашим логином и паролем. Администрация имеет право
				запретить использование опредёленных логинов и/или изъять их из
				обращения. Вы соглашаетесь с тем, что Вы обязаны немедленно уведомить
				Администрацию о любом случае неавторизованного (не разрешенного Вами)
				доступа с Вашим логином и паролем и/или о любом нарушении безопасности,
				а также с тем, что Вы самостоятельно осуществляете завершение работы под
				своим паролем (кнопка «Выход») по окончании каждой сессии работы с
				сайтом. Администрация не отвечает за возможную потерю или порчу данных,
				которая может произойти из-за нарушения Вами положений этой части
				ПС.&nbsp;
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>4. Положение о&nbsp;поведении&nbsp;Пользователя</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.1. Вы понимаете и принимаете, что за всю информацию (Содержание) —
				данные, текст, программы, музыку, звуки, фотографии, графику, видео,
				сообщения и другие материалы, размещённые для общего доступа или
				переданные в частном порядке, — ответственно то лицо, которое это
				Содержание произвело. Это означает, что Вы, а не Администрация,
				полностью отвечаете за всё Содержание, который Вы загружаете, посылаете,
				передаёте или каким-либо другим способом делаете доступным с помощью
				Totravel.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.2. Пользователь соглашается с тем, что Содержание, размещаемое им на
				Totravel, будет доступно для всех других пользователей, как
				непосредственно на Сайте, так и путём трансляции различными техническими
				средствами со ссылкой на первоначальный источник (с помощью почтовой
				рассылки, RSS-трансляции, использования в качестве электронной открытки,
				скринсейвера, записи в блоге и т.д.).
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.3. Администрация не контролирует Содержание, передаваемое через
				сервисы Totravel, и, следовательно, не гарантирует точность, полноту или
				качество этого Содержания. Вы понимаете, что, используя сервисы
				Totravel, Вы можете увидеть Содержание, которое является оскорбительным,
				недостойным или спорным. Ни при каких обстоятельствах Администрация не
				несёт ответственности за Содержание, созданное пользователями Totravel.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.4. Права на всё размещенное на Totravel Содержание считаются
				принадлежащими разместившим их Пользователям до тех пор, пока не будет
				оснований считать иначе. Пользователи не получают никаких прав на
				использование Содержания других Пользователей вне рамок возможностей,
				предоставляемых Totravel, и несут полную ответственность перед автором
				за неправомерное использование Содержания.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.5. Пользователь самостоятельно несёт ответственность перед третьими
				лицами за свои действия, связанные с созданием и размещением Содержания
				на Totravel, в том числе за то, что Cодержание соответствует требованиям
				законодательства РФ и не нарушает права и законные интересы третьих лиц.
				Содержание в целом и каждый из его элементов не должны нарушать
				авторские права, права на товарные знаки, средства индивидуализации
				и/или права на иные объекты интеллектуальной собственности,
				принадлежащие третьим лицам. Пользователь самостоятельно и за свой счёт
				обязуется урегулировать все претензии третьих лиц, связанные с созданием
				и размещением Содержания на интернет-ресурсе Totravel.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.6. Вы обязуетесь не использовать Totravel для:&nbsp;
			</div>
			<ul
				style={{
					textAlign: "start",
					color: "rgb(51, 51, 51)",
					backgroundColor: "rgb(255, 255, 255)",
					fontSize: 17,
				}}
			>
				<li>
					загрузки, посылки, передачи или любого другого способа размещения
					Содержания, которое является незаконным, вредоносным, угрожающим,
					клеветническим, оскорбляет нравственность, нарушает авторские права,
					пропагандирует ненависть и/или дискриминацию людей по&nbsp;расовому,
					этническому, половому, религиозному, социальному признакам, содержит
					оскорбления в&nbsp;адрес конкретных лиц или&nbsp;организаций;
				</li>
				<li>
					нарушения прав несовершеннолетних лиц и/или причинение им&nbsp;вреда
					в&nbsp;любой&nbsp;форме;
				</li>
				<li>ущемления прав&nbsp;меньшинств;</li>
				<li>
					выдавания себя за другого человека или представителя организации и/или
					сообщества без достаточных на то прав, в том числе за сотрудников
					Администрации, за владельца сайта, а также введения в заблуждение
					относительно свойств и характеристик каких-либо субъектов или
					объектов;
				</li>
				<li>
					загрузки, посылки, передачи или любого другого способа размещения
					Содержания, которое Вы не имеете права делать доступным по закону РФ
					или согласно каким-либо контрактным отношениям;
				</li>
				<li>
					загрузки, посылки, передачи или любого другого способа размещения
					Содержания, которое затрагивает какой-либо патент, торговую марку,
					коммерческую тайну, копирайт или прочие права собственности и/или
					авторские и смежные с ним права третьей стороны;
				</li>
				<li>
					загрузки, посылки, передачи или любого другого способа размещения не
					разрешенной специальным образом рекламной информации, спама (в том
					числе и поискового), списков чужих адресов электронной почты, схем
					«пирамид», многоуровневого (сетевого) маркетинга (MLM), систем
					интернет-заработка и e-mail-бизнесов, а также для участия в этих
					мероприятиях (отправка несогласованных писем со ссылками на
					предоставляемые Администрацией сервисы) может считаться участием в
					запрещённых настоящим пунктом мероприятиях, даже если отправка
					производилась без непосредственного использования серверов Totravel);
				</li>
				<li>
					загрузки, посылки, передачи или любого другого способа размещения
					каких-либо материалов, содержащих вирусы или другие компьютерные коды,
					файлы или программы, предназначенные для нарушения, уничтожения либо
					ограничения функциональности любого компьютерного или
					телекоммуникационного оборудования или программ, для осуществления
					несанкционированного доступа, а также серийные номера к коммерческим
					программным продуктам и программы для их генерации, логины, пароли и
					прочие средства для получения несанкционированного доступа к платным
					ресурсам в Интернете, а также размещения ссылок на вышеуказанную
					информацию;
				</li>
				<li>умышленного нарушения местного и российского законодательства;</li>
				<li>сбора и&nbsp;хранения персональных данных других&nbsp;лиц;</li>
				<li>нарушения нормальной работы веб-сайта Totravel;</li>
				<li>
					применения любых форм и&nbsp;способов незаконного представительства
					других лиц&nbsp;в&nbsp;Сети;
				</li>
				<li>
					размещения ссылок на&nbsp;ресурсы Сети, содержание которых
					противоречит действующему законодательству&nbsp;РФ;
				</li>
				<li>
					содействия действиям, направленным на&nbsp;нарушение ограничений
					и&nbsp;запретов, налагаемых&nbsp;Соглашением.
				</li>
			</ul>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.7. Вы признаёте, что Администрация не обязана просматривать Содержание
				перед размещением, а также то, что Администрация имеет право (но не
				обязанность) по своему усмотрению отказать в размещении или удалить
				любое Содержание, которое доступно через сайт Totravel. Вы согласны с
				тем, что Вы должны самостоятельно оценивать все риски, связанные с
				использованием Содержания, включая оценку надёжности, полноты или
				полезности этого Содержания.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.8. Содержание интернет-ресурса Totravel может содержать ссылки на
				другие ресурсы. Вы признаёте и соглашаетесь с тем, что Администрация не
				несёт никакой ответственности за доступность этих ресурсов и за их
				содержание, а также за любые последствия, связанные с использованием
				этих ресурсов.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.9. Вы понимаете, что технология работы служб может потребовать
				передачи Вашего Содержания по компьютерным сетям, а также изменения его
				для соответствия техническим требованиям.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.10. Вы соглашаетесь не воспроизводить, не повторять и не копировать,
				не продавать и не перепродавать, а также не использовать для каких-либо
				коммерческих целей какие-либо части сайта Totravel, кроме тех случаев,
				когда такое разрешение дано Вам Администрацией в письменной форме.
				Зарегистрировавшись, Вы получаете непередаваемое право пользоваться
				своей регистрацией (логином и паролем) для доступа к своей персональной
				информации. Вы не имеете права передавать свою регистрацию (логин и
				пароль) третьему лицу, а также не имеете права получать его от третьего
				лица иначе, чем с письменного согласия Администрации. Администрация не
				несёт никакой ответственности ни по каким договорам между Вами и
				третьими лицами.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.11. Вы соглашаетесь с тем, что всё Содержание, размещённое Вами на
				Totravel, включая тексты, фотоматериалы, комментарии, сообщения в
				форумах и других коммуникационных службах сохраняется в базах данных (в
				том числе в публичном доступе) и не удаляется по требованию
				Пользователя. Полное удаление информации с сайта осуществляется в
				исключительных случаях и только по решению Администрации.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.12. Вы обязуетесь не использовать никакие искусственные методы по
				имитации, ускорению или изменению пользовательских действий на веб-сайте
				или в мобильных приложениях Totravel. Нарушение этого пункта Соглашения
				приведёт к немедленному удалению учётной записи без предупреждения и
				возможности восстановления.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				4.13. Заключая настоящее Соглашение, вы безвозмездно предоставляете
				Администрации простую (неисключительную) лицензию на использование всего
				Содержания (включая публичные комментарии), опубликованного вами на
				Totravel, на территории всего мира в течение всего срока действия
				исключительных прав на Содержание с момента публикации, путём
				переработки, доведения до всеобщего сведения, распространения и
				включения в состав сложных объектов с правом сублицензирования и
				использования без указания имени автора или под псевдонимом. Ваше
				Содержание может быть использовано в оригинальной или изменённой форме
				на любых интернет-сайтах, в том числе в рекламных целях.
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>5. Права и&nbsp;обязанности&nbsp;Администрации</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				5.1. Администрация имеет право размещать на странице с Вашим Содержанием
				рекламную информацию или любую другую информацию для публичного
				распространения, однако без ущерба для Содержания Вашей веб-страницы.
				Форма, порядок и&nbsp;объёмы такой информации могут время
				от&nbsp;времени изменяться&nbsp;Администрацией.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				5.2. Вы согласны с тем, что Администрация оставляет за собой право
				прекратить действие Вашего логина и пароля и удалить любое Содержание по
				любой причине, в том числе при неиспользовании доступа или при нарушении
				Соглашения. Администрация не несёт никакой ответственности за
				прекращение доступа к Totravel.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				5.3. Администрация обязуется предпринимать комплекс мер по&nbsp;защите
				регистрационных данных Пользователя от&nbsp;неправомерного доступа,
				уничтожения или изменения размещённой Пользователем информации,
				а&nbsp;также от&nbsp;иных неправомерных действий личной информации
				имеющимися в&nbsp;её распоряжении техническими&nbsp;средствами;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				5.4. Администрация имеет право предоставить всю имеющуюся информацию
				о&nbsp;Пользователе уполномоченным на&nbsp;то&nbsp;органам
				государственной власти в&nbsp;случаях, предусмотренных действующим
				законодательством&nbsp;РФ.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				5.5. Любое размещённое Пользователями Содержание может использоваться
				Администрацией для дальнейшего распространения как с&nbsp;указанием
				их&nbsp;автора, так и&nbsp;без этого, без необходимости получения
				специального разрешения Пользователя и&nbsp;без выплаты авторского
				вознаграждения.
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>6. Права&nbsp;собственности</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				6.1. Вы признаёте и соглашаетесь с тем, что интернет-ресурс Totravel и
				все необходимые программы, связанные с ним, содержат конфиденциальную
				информацию, которая защищена законами об интеллектуальной собственности
				и прочими российскими и международными законами, а Содержание,
				предоставляемое Вами в процессе пользования услугами, защищено
				авторскими правами, торговыми марками, патентами и прочими
				соответствующими законами. Кроме случаев, оговоренных специально с
				Администрацией, Вы соглашаетесь не модифицировать, не продавать, не
				распространять этот контент и программы, целиком либо по частям.
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				6.2. Администрация предоставляет Вам личное неисключительное и
				непередаваемое право использовать программное обеспечение,
				предоставляемое в интернет-ресурсе Totravel, на одном компьютере, при
				условии, что ни Вы сами, ни любые иные лица при содействии с Вашей
				стороны не будут копировать или изменять программное обеспечение;
				создавать программы, производные от программного обеспечения; проникать
				в программное обеспечение с целью получения кодов программ; осуществлять
				продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной
				форме прав в отношении программного обеспечения служб, предоставленных
				Вам по ПС, а также модифицировать сервисы, в том числе с целью получения
				несанкционированного доступа к ним.&nbsp;
			</div>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>7. Освобождение от гарантий</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				7.1. Вы&nbsp;понимаете и&nbsp;соглашаетесь с&nbsp;тем, что:
			</div>
			<ul
				style={{
					textAlign: "start",
					color: "rgb(51, 51, 51)",
					backgroundColor: "rgb(255, 255, 255)",
					fontSize: 17,
				}}
			>
				<li>
					Вы используете интернет-ресурс Totravel на Ваш собственный риск.
					Услуги предоставляются «как есть». Администрация не принимает на себя
					никакой ответственности, в том числе и за соответствие услуг цели
					Пользователя;
				</li>
				<li>
					Администрация не гарантирует, что: услуги будут соответствовать Вашим
					требованиям; услуги будут предоставляться непрерывно, быстро, надёжно
					и без ошибок; результаты, которые могут быть получены с пользованием
					услуг, будут точными и надёжными; качество какого-либо продукта,
					услуги, информации и пр., полученного с использованием услуг, будет
					соответствовать Вашим ожиданиям; все ошибки в программах будут
					исправлены;
				</li>
				<li>
					любые материалы, полученные Вами с использованием Totravel, Вы можете
					использовать на свой собственный страх и риск, на Вас возлагается
					ответственность за любой ущерб, который может быть нанесен Вашему
					компьютеру и Вашим данным в результате загрузки этих материалов;
				</li>
				<li>
					Администрация не несёт ответственности за любые прямые или непрямые
					убытки, произошедшие из-за: пользования либо невозможности пользования
					услугами; несанкционированного доступа к Вашим коммуникациям;
					заявления или поведение любого третьего лица в службах.
				</li>
			</ul>

			<h5 style={{ color: "rgb(33, 37, 41)", fontSize: 20 }}>
				<strong>8. Общая информация</strong>
			</h5>

			<div style={{ color: "rgb(33, 37, 41)" }}>
				8.1. Ввиду безвозмездности услуг, оказываемых в рамках ПС, нормы о
				защите прав потребителей не могут быть к нему применимыми. В случае если
				ПС будет придан возмездный характер, в него будут внесены
				соответствующие изменения в целях соблюдения указанных норм.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				8.2. Ничто в ПС не может пониматься как установление между Вами и
				Администрацией агентских отношений, отношений товарищества, отношений по
				совместной деятельности, отношений личного найма, либо каких-то иных
				отношений, прямо не предусмотренных ПС.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				8.3. Признание судом какого-либо положения ПС недействительным или не
				подлежащим принудительному исполнению не влечёт недействительности или
				неисполнимости иных положений Соглашения.&nbsp;
			</div>
			<div style={{ color: "rgb(33, 37, 41)" }}>
				8.4. Бездействие со стороны Администрации в случае нарушения Вами либо
				иными пользователями положений ПС не лишает Администрацию права
				предпринять соответствующие действия в защиту своих интересов позднее, а
				также не означает отказа Администрации от своих прав в случае совершения
				в последующем подобных либо сходных нарушений.
			</div>
		</>
	);
};

export default Agreement;
