import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	Box,
	Grid,
	Grow,
	Typography,
	Paper,
	TextField,
} from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import ProfileSmall from "./ProfileSmall/ProfileSmall";
import {
	getFriends,
	getNumberFriends,
	getNumberSubscribers,
	getNumberUsers,
	getSubscribers,
	getUsers,
} from "../../actions/users.js";
import { useLocation } from "react-router-dom";
import PaginationProfileSmall from "./PaginationProfileSmall/PaginationProfileSmall.jsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ProfileList = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = useQuery();
	const page = query.get("page") || 1;
	const totalUsers = useSelector((state) => state.users.totalUsers);
	const users = useSelector((state) => state.users?.users);
	const [search, setSearch] = useState("");
	const [isTextFieldFocused, setIsTextFieldFocused] = useState(false); // Track if TextField is focused
	const handleSearch = async (query) => {
		if (urlType === "friends") {
			await dispatch(getFriends(page, query, urlLogin));
		} else if (urlType === "subscribers") {
			await dispatch(getSubscribers(page, query, urlLogin));
		} else {
			await dispatch(getUsers(page, query));
		}
	};

	const urlLogin = window.location.pathname.split("/")[2];
	const urlType = window.location.pathname.split("/")[3];

	useEffect(() => {
		if (urlType === "friends") {
			dispatch(getNumberFriends(urlLogin));
			dispatch(getFriends(page, search, urlLogin));
		} else if (urlType === "subscribers") {
			dispatch(getNumberSubscribers(urlLogin));
			dispatch(getSubscribers(page, search, urlLogin));
		} else {
			dispatch(getNumberUsers());
			dispatch(getUsers(page, search));
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>Список пользователей - ToTravelRu</title>
				<meta
					name="description"
					content="Список пользователей на сайте с отчетами о путешествиях по всему миру - ToTravelRu"
				/>
				<meta
					name="keywords"
					content="пользователи, список пользователей, отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu"
				/>
			</Helmet>
			<Typography variant="h5">
				{urlType === "friends"
					? "Друзья"
					: urlType === "subscribers"
					? "Подписчики"
					: "Список пользователей"}
			</Typography>
			<div className={classes.flex}>
				<Typography variant="h6">
					{urlType === "friends"
						? "Всего друзей: "
						: urlType === "subscribers"
						? "Всего подписчиков: "
						: "Всего пользователей: "}{" "}
					{totalUsers}
				</Typography>
			</div>
			<TextField
				size="small"
				name="search"
				variant="outlined"
				label="Поиск"
				value={search}
				onChange={(e) => {
					const query = e.target.value;
					setSearch((prev) => query);
					handleSearch(query);
				}}
				onFocus={() => setIsTextFieldFocused(true)} // Set isTextFieldFocused to true when focused
				onBlur={() => setIsTextFieldFocused(false)} // Set isTextFieldFocused to false when blurred
				fullWidth
				className={classes.searchContainerText}
			/>
			<Grow in>
				<Grid
					container
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					className={classes.gridContainer}
				>
					{users
						? users.map((profile, i) => (
								<Grid key={i} item xs={12} sm={12} md={6}>
									<ProfileSmall profile={profile} />
								</Grid>
						  ))
						: null}
				</Grid>
			</Grow>
			{users?.length ? (
				<Paper className={classes.pagination}>
					<PaginationProfileSmall
						page={page}
						search={search}
						urlType={urlType}
						urlLogin={urlLogin}
					/>
				</Paper>
			) : null}
		</div>
	);
};

export default ProfileList;
