import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ children, getHTMLElementId, chosenCoords }) => {
  const mount = useRef(null);
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    const mountNode = document.getElementById(getHTMLElementId);
    if (!mountNode) {
      console.error(`Element with id "${getHTMLElementId}" not found.`);
      return;
    }
    mount.current = mountNode;
    mountNode.appendChild(el.current);
    return () => {
      mountNode.removeChild(el.current);
    };
  }, [getHTMLElementId, chosenCoords]);

  if (!mount) return null;
  return createPortal(children, el.current);
};
