import {
	EDIT_PROFILE,
	FETCH_USER,
	START_LOADING_USER,
	END_LOADING_USER,
	EDIT_COUNTRIES,
	FETCH_USER_IMG,
	START_LOADING_AVATAR,
	END_LOADING_AVATAR,
	ADD_YANDEX_AVATAR,
	GET_NUMBER_USERS,
	FETCH_USERS,
	FETCH_USER_SUBSCRIBERS,
	FETCH_USER_FRIENDS,
} from "../constants/actionTypes";

const authReducer = (
	state = {
		isLoading: true,
		result: {},
		isLoadingAvatar: false,
		userImg: "",
		totalUsers: "",
	},
	action
) => {
	switch (action.type) {
		case START_LOADING_USER:
			return { ...state, isLoading: true };
		case END_LOADING_USER:
			return { ...state, isLoading: false };
		case START_LOADING_AVATAR:
			return { ...state, isLoadingAvatar: true };
		case END_LOADING_AVATAR:
			return { ...state, isLoadingAvatar: false };
		case EDIT_PROFILE:
			return {
				...state,
				result: [action.payload.result],
			};
		case EDIT_COUNTRIES:
			return {
				...state,
				result: { ...state.result, countriesList: action.payload.result },
			};
		case FETCH_USER:
			if (action.payload && action.payload.error) {
				return {
					...state,
					errorMessage: action.payload.error,
				};
			}
			return {
				...state,
				result: action.payload,
			};
		case FETCH_USER_IMG:
			return {
				...state,
				userImg: action.payload,
			};
		case ADD_YANDEX_AVATAR:
			return state;
		case GET_NUMBER_USERS:
			return { ...state, totalUsers: action.payload };
		case FETCH_USERS:
			return {
				...state,
				users: action.payload.data,
				currentPage: action.payload.currentPage,
				numberOfPages: action.payload.numberOfPages,
			};
		case FETCH_USER_SUBSCRIBERS:
			const newState = { ...state };
			const user = newState.result[0];
			user.subscribersInfo = action.payload;
			return newState;
		case FETCH_USER_FRIENDS:
			const newStateFriend = { ...state };
			const userFriend = newStateFriend.result[0];
			userFriend.friendsInfo = action.payload;
			return newStateFriend;
		default:
			return state;
	}
};

export default authReducer;
