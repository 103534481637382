import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { getLatestAlert } from "../../actions/admin.js";
import CloseIcon from "@material-ui/icons/Close";

const Alert = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [alertClose, setAlertClose] = useState(false);
	const alert = useSelector((state) => state.admin.alert);

	useEffect(() => {
		dispatch(getLatestAlert());
	}, []);

	const handleAlertClose = () => {
		setAlertClose(true);
		localStorage.setItem("alertClosed", alert?._id);
	};

	// Check if alert is closed in localStorage
	const alertClosedId = localStorage.getItem("alertClosed");

	return (
		<div>
			{alertClose || alert === null || alertClosedId === alert?._id ? null : (
				<div className={classes.betaTest}>
					<Typography>{alert?.alertText}</Typography>
					<Button className={classes.betaTestButton} onClick={handleAlertClose}>
						<CloseIcon />
					</Button>
				</div>
			)}
		</div>
	);
};

export default Alert;
