import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	appBarSearch: {
		borderRadius: "15px",
		border: "1px solid rgba(236,98,98,1)",
		marginBottom: "10px",
		display: "flex",
		padding: "16px",
		flexDirection: "row",
	},
	pagination: {
		borderRadius: "15px",
		border: "1px solid rgba(236,98,98,1)",
		marginTop: "10px",
		padding: "16px",
	},
	gridContainer: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column-reverse",
		},
	},
	searchContainer: {
		display: "flex",
		width: "50%",
		"@media (max-width: 900px)": {
			width: "100%",
		},
		"@media (max-width: 600px)": {
			width: "100%",
			flexDirection: "column",
		},
	},
	searchContainerText: {
		marginRight: "10px",
		"@media (max-width: 600px)": {
			marginRight: "0",
			marginBottom: "5px",
		},
	},
	searchButton: {
		borderRadius: "20px",
		display: "flex",
		justifyContent: "space-evenly",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	searchButtonText: {
		fontSize: "14px",
	},
	container: {
		padding: 0,
	},
	postGrid: {
		position: "relative",
	},
	sticky: {
		position: "sticky",
		zIndex: "1000",
		bottom: "10px",
		display: "flex",
		justifyContent: "right",
	},
	buttonWrite: {
		minWidth: "60px",
		minHeight: "60px",
		borderRadius: "30px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonWriteImg: {
		minWidth: "30px",
		minHeight: "30px",
	},
}));
