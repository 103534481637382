import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	media: {
		borderRadius: "15px",
		backdropFilter: "brightness(100%)",
		backgroundSize: "contain",
		width: "100%",
		minHeight: "60vh",
		cursor: "pointer",
		"@media (max-width: 600px)": {
			minHeight: "60vh",
		},
	},
	card: {
		display: "flex",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexWrap: "wrap",
			flexDirection: "column",
		},
	},
	section: {
		borderRadius: "20px",
		margin: "10px",
		flex: 1,
	},
	imageSection: {
		maxWidth: "50%",
		marginLeft: "20px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
		},
	},
	recommendedPosts: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	loadingPaper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "20px",
		borderRadius: "15px",
		height: "39vh",
	},
	postsOuterContainer: {},
	commentsInnerContainer: {
		display: "flex",
		marginBottom: "0.3rem",
	},
	commentsTitlePost: {
		width: "50%",
		marginBottom: "0",
	},
	postTitlePost: {
		width: "50%",
		marginBottom: "0",
		"@media (max-width: 600px)": {
			fontSize: "1.6rem",
		},
	},
	postContainer: {
		display: "flex",
	},
	postsLinkOuterContainer: {
		display: "flex",
	},
	postsLinkContainer: {
		display: "flex",
		alignItems: "center",
		margin: "0 15px",
	},
	numberContainer: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "30px",
		height: "30px",
		borderRadius: "15px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: "10px",
	},
	numberText: {
		color: "rgba(255,255,255,1)",
	},
	titleLinkPost: {
		fontSize: "18px",
		margin: "8px 0 8px 15px",
	},
	numberLink: {
		color: "rgba(0,0,0,1)",
		width: "25%",
		"&:hover, &:focus": {
			textDecoration: "none",
		},
	},
	titlePost: {
		fontSize: "18px",
	},
	buttonChangeType: {
		margin: "0 0 0 auto",
		backgroundColor: "rgba(236,98,98,1)",
		height: "40px",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonPrimary: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		lineHeight: "1.3",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
		"@media (max-width: 600px)": {
			marginBottom: "10px",
		},
	},
	buttonPrimaryWidth: {
		marginTop: "10px",
		width: "100%",
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 10px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	switchContainer: {
		display: "flex",
		alignItems: "center",
		margin: "0 0 0 auto",
	},
	switchChangeType: {
		color: "rgba(236,98,98,1)",
	},
	postContentButtons: {
		display: "flex",
		justifyContent: "space-evenly",
		marginTop: "5px",
		borderRadius: "15px",
		padding: "5px",
		"@media (max-width: 600px)": {
			flexDirection: "column",
		},
	},
	deletePost: {
		margin: "10px 0 0 0",
	},
	writePostText: {
		fontSize: "14px",
	},
	writePostImg: {
		marginRight: "5px",
	},
	buttonSecondary: {
		height: "40px",
		width: "100%",
		borderRadius: "20px",
		backgroundColor: "rgb(9,9,121)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	upperContainer: {
		display: "flex",
		position: "relative",
		marginBottom: "10px",
	},
	legendButtonOpen: {
		marginLeft: "auto",
		borderRadius: "20px",
	},
	legend: {
		position: "absolute",
		right: "0",
		top: "0",
		width: "30%",
		zIndex: "999",
		[theme.breakpoints.down("md")]: {
			width: "40%",
		},
		[theme.breakpoints.down("sm")]: {
			width: "70%",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	legendButton: {
		position: "absolute",
		right: "5px",
		top: "11px",
		height: "40px",
		width: "40px",
		textDecoration: "none",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
			[theme.breakpoints.down("sm")]: {
				width: "20px",
			},
		},
	},
	likeButtonClicked: {
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "15px",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
		width: "65%",
	},
	likeButtonNotClicked: {
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		color: "rgba(236,98,98,1)",
		borderRadius: "15px",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
		},
		width: "65%",
	},
	likeText: {
		marginLeft: "4px",
	},
	legendTitle: {
		fontSize: "18px",
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
	},
	imageBox: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		backgroundColor: "rgba(248,248,248,1)",
		borderRadius: "15px",
		padding: "10px",
		width: "60%",
		margin: "0 auto 10px auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			padding: "0",
		},
	},
	CircularPost: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "70vh",
	},
	CircularProfile: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "15vh",
	},
	postCard: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "20px",
		[theme.breakpoints.down("sm")]: {
			padding: "10px",
		},
		"@media (max-width: 600px)": {
			padding: "8px",
		},
	},
	profileCard: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
	},

	gridContainer: {
		[theme.breakpoints.down("s")]: {
			flexDirection: "column",
		},
	},
	calendarOuterContainer: {
		width: "30%",
		boxSizing: "border-box",
	},
	calendarLike: {
		display: "flex",
		justifyContent: "space-between",
	},
	postCardDelete: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "10px",
		display: "flex",
		flexDirection: "column",
		marginTop: "10px",
	},
	yesNo: {
		display: "flex",
		justifyContent: "space-evenly",
	},
	buttonYes: {
		height: "40px",
		width: "45%",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonNo: {
		height: "40px",
		width: "45%",
		borderRadius: "20px",
		backgroundColor: "rgb(9,9,121)",
		color: "rgba(255,255,255,1)",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
	},
	textWrap: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontSize: "1rem",
		"@media (max-width: 600px)": {
			fontSize: "0.9rem",
		},
	},
	mediaText: {
		fontSize: "1rem",
		marginBottom: "5px",
		"@media (max-width: 600px)": {
			fontSize: "0.9rem",
		},
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalContent: {
		position: "relative",
		maxWidth: "90%",
		maxHeight: "90%",
		overflow: "auto",
		backgroundColor: "rgba(0, 0, 0, 0.9)",
		borderRadius: theme.shape.borderRadius,
		outline: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	enlargedImage: {
		maxWidth: "100%",
		maxHeight: "100%",
	},
	closeButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		color: theme.palette.grey[300],
		cursor: "pointer",
		padding: theme.spacing(0.5),
		backgroundColor: "rgba(236, 98, 98, 1)",
		borderRadius: theme.spacing(1), // Adjust the border radius as needed
	},
	geographyButton: {
		width: "100%",
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		color: "rgba(236,98,98,1)",
		"&:hover": {
			backgroundColor: "rgba(213,72,72,1)",
			color: "rgba(255,255,255,1)",
		},
	},
	geographyButtonChange: {
		width: "100%",
		boxSizing: "border-box",
		marginTop: "10px",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
		borderRadius: "15px",
		color: "rgb(9,9,121)",
		"&:hover": {
			backgroundColor: "rgba(5,5,80,1)",
			color: "rgba(255,255,255,1)",
		},
	},
}));
