import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useStyles from "./styles.js";
import { YMaps, Map, Placemark, Clusterer } from "@pbe/react-yandex-maps";
import { getPlaces, getPlacesByPostsId } from "../../../actions/places.js";
import { PortalGeneral } from "../PortalGeneral/PortalGeneral";
import { Typography, Button } from "@material-ui/core";
import PlaceBox from "../ShowGeo/PlaceBox/PlaceBox.jsx";
import PlaceReportBox from "./PlaceReportBox/PlaceReportBox.jsx";

const MapReport = ({ geoShow, setGeoShow }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	let postsArray = useSelector((state) => {
		return state?.posts?.initialPosts;
	});

	const postsArrayIds = postsArray.map((post) => post._id);
	const queryParams = postsArrayIds.join(",");
	const [post, setPost] = useState("");

	const places = useSelector((state) => state.place.places);
	const [place, setPlace] = useState("");

	// Check if both places and postsArray have a length greater than 1
	if (places.length > 1 && postsArray.length > 1) {
		// Sort places array based on postsId
		places.sort((a, b) => {
			// Get the index of the first post in postsArray corresponding to a's postsId
			const indexA = postsArray.findIndex((post) => post._id === a.postsId[0]);
			// Get the index of the first post in postsArray corresponding to b's postsId
			const indexB = postsArray.findIndex((post) => post._id === b.postsId[0]);
			// Compare the indices to determine the order
			return indexA - indexB;
		});
	} else {
		console.log("Places and/or postsArray length is not greater than 1.");
	}

	const postsIds = places.map((place) => place.postsId).flat();
	postsArray = postsArray.filter((post) => postsIds.includes(post._id));

	places.forEach((place) => {
		let postsId = place.postsId.slice(1);

		postsId.forEach((id) => {
			postsArray = postsArray.filter((post) => post._id !== id);
		});
	});

	useEffect(() => {
		postsArrayIds.length > 0
			? dispatch(getPlacesByPostsId(queryParams))
			: console.log("Постов нет");
	}, []);

	const mapRef = React.useRef(null);
	const placeBoxRef = React.useRef(null); // Ref for PlaceBox
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const [activePortal, setActivePortal] = useState(false);
	const [numberPlace, setNumberPlace] = useState("");
	const [iconOffset, setIconOffset] = useState([0, 0]); // Initial offset
	const [parentWrapBoxHeight, setParentWrapBoxHeight] = useState(0);

	const ClusterLayout = ymaps?.templateLayoutFactory?.createClass(
		'<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}+</div>',
		{}
	);

	const placemarkLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-place" class="driver-card"></div>`,
		{}
	);

	useEffect(() => {
		if (placeBoxRef.current) {
			const placeBoxHeight = placeBoxRef.current.clientHeight;
			setIconOffset([0, -placeBoxHeight]);
		}
	}, [placeBoxRef.current]);

	const handleWrapBoxHeightChange = (height) => {
		setParentWrapBoxHeight(height);
	};

	return (
		<>
			<Button
				className={classes.betaTest}
				onClick={() => setGeoShow(!geoShow)}
				style={{ backgroundColor: "rgba(236,98,98,1)" }}
			>
				Закрыть карту
			</Button>
			<YMaps
				query={{
					load: "package.full",
					apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
				}}
			>
				<Map
					defaultState={{
						center: places ? places[0]?.geoposition : [55.75, 37.57],
						zoom: 9,
					}}
					width="100%"
					height="83vh"
					onClick={(event) => {
						const coords = event.get("coords");
					}}
					instanceRef={mapRef}
					onLoad={(ymaps) => setYmaps(ymaps)}
				>
					<Clusterer
						options={{
							groupByCoordinates: false,
							clusterIcons: [
								{
									href: "https://img.icons8.com/windows/32/EC6262/filled-circle.png",
									size: [40, 40],
									offset: [-20, -20],
								},
							],
							clusterIconContentLayout: ClusterLayout,
						}}
					>
						{places.map((n, i) => (
							<Placemark
								key={i}
								geometry={n.geoposition}
								options={{
									iconLayout:
										activePortal && numberPlace === i
											? placemarkLayout
											: ymaps?.templateLayoutFactory?.createClass(
													`<div style="color: #FFFFFF; font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; font-weight: 500; font-size: 14px; letter-spacing: 0.02857em;">
												<div style="background-color: rgba(236, 98, 98, 1); width: 30px; height: 30px; border-radius: 50%; display: flex; justify-content: center; align-items: center;">
														${i + 1}
												</div>
										</div>`,
													{}
											  ),
									iconOffset:
										activePortal && numberPlace === i ? iconOffset : [-15, -15], // Use dynamic iconOffset
									iconImageSize: [30, 30],
									iconImageHref: `https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png`,
									iconColor: "rgb(236,98,98)",
									interactivityModel: "default#opaque",
									pane: "balloon",
								}}
								properties={{
									hintContent: n.placeName,
								}}
								onClick={() => {
									setNumberPlace(i);
									setPlace(places ? places[i] : "");
									setPost(postsArray ? postsArray[i] : "");
									setActivePortal(true);
								}}
							/>
						))}
					</Clusterer>
				</Map>
			</YMaps>
			{activePortal && (
				<PortalGeneral
					getHTMLElementId={"placemark-place"}
					placemarkLayout={placemarkLayout}
				>
					<div ref={placeBoxRef}>
						<PlaceReportBox
							place={place}
							onWrapBoxHeightChange={handleWrapBoxHeightChange}
							post={post}
						/>
					</div>
				</PortalGeneral>
			)}
		</>
	);
};

export default MapReport;
