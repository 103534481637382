import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    border: "1px solid rgba(236,98,98,1)",
    borderRadius: "15px",
    alignItems: "center",
  },
  regionTitle: {
    marginBottom: "10px",
  },
  regionText: {
    marginTop: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
    width: "100%",
    borderRadius: "15px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px",
    position: "relative",
    borderColor: "rgba(236,98,98,1)",
    border: "solid 1px",
  },
  cardAdvert: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    minHeight: "15vh",
    borderColor: "rgba(236,98,98,1)",
    border: "solid 1px",
  },
  cardAbout: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderColor: "rgba(236,98,98,1)",
    border: "solid 1px",
    height: "calc(100% - 22px)",
  },
  cardStatistics: {
    borderRadius: "15px",
    display: "flex",
    height: "calc(100% - 22px)",
    flexDirection: "column",
    minHeight: "130px",
    padding: "10px",
    borderColor: "rgba(236,98,98,1)",
    border: "solid 1px",
  },
  cardPost: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "15vh",
    padding: "10px",
    borderColor: "rgba(236,98,98,1)",
    border: "solid 1px",
  },
  reportsBox: {
    //display: "flex",
  },
  gridContainer: {
    display: "flex",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  gridContainerPosts: {
    display: "flex",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  },
  gridContainerBox: {
    boxSizing: "border-box",
    flex: 1,
  },
  profilePicture: {
    margin: "20px 0px",
    width: "150px",
    height: "150px",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    fontSize: "3.5rem",
    "@media (max-width: 600px)": {
      width: "100px",
      height: "100px",
      fontSize: "2.5rem",
    },
  },
  photoName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 1)",
    border: "none",
    height: "1px",
    width: "50%",
  },
  buttonPrimary: {
    borderRadius: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    margin: "7px 0",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  infoProfile: {
    width: "50%",
    marginBottom: "10px",
    "@media (max-width: 800px)": {
      width: "80%",
    },
  },
  descWrap: {
    display: "flex",
    marginBottom: "5px",
  },
  logo: {
    maxWidth: "1.2rem",
    color: "rgb(236,98,98)",
  },
  descText: {
    margin: "auto 0 auto 5px",
  },
  statisticsFlex: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "@media (max-width: 800px)": {
      flexDirection: "column",
    },
  },
  reportsTitle: {
    marginBottom: "10px",
  },
  imageAbout: {
    margin: "10px auto",
    color: "rgba(236,98,98,1)",
  },
  userStatWrap: {
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    margin: "0 0 0 auto",
    minWidth: "20px",
    height: "20px",
    padding: "2px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexVisited: {
    display: "flex",
    "@media (max-width: 360px)": {
      flexDirection: "column",
    },
  },
  linearProgress: {
    width: "100%",
  },
  visitedCountriesText: {
    marginRight: "5px",
  },
  countriesNumberBox: {
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    margin: "0 5px 0 0",
    minWidth: "15px",
    height: "15px",
    padding: "3px",
    borderRadius: "9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  countriesNumberText: {
    lineHeight: 1,
  },
  emojiList: {
    lineHeight: 1,
    marginTop: "5px",
  },
  mapIcon: {
    marginRight: "5px",
  },
  flex: {
    display: "flex",
  },
  addCountriesText: {
    fontSize: "0.875rem",
  },
  mapIconNoCountries: {
    margin: "10px auto",
    color: "rgba(236,98,98,1)",
    fontSize: "40px",
  },
  pagination: {
    borderRadius: "15px",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    marginTop: "10px",
    padding: "16px",
  },
  circularProfile: {
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  circularOther: {
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  adminSponsorLabel: {
    borderRadius: "20px",
    backgroundColor: "rgba(9,9,121,1)",
    color: "rgba(255,255,255,1)",
    margin: "7px 0",
    padding: "0 30px",
  },
  buttonBoxAbout: {
    marginTop: "auto",
  },
}));
