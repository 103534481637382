import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	media: {
		borderRadius: "20px",
		backdropFilter: "brightness(100%)",
		maxWidth: "90%",
		height: 0,
		paddingTop: "56.25%",
	},
	imageBox: {
		alignItems: "center",
	},
	card: {
		display: "flex",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexWrap: "wrap",
			flexDirection: "column",
		},
	},
	section: {
		borderRadius: "20px",
		margin: "10px",
		flex: 1,
	},
	imageSection: {
		maxWidth: "50%",
		marginLeft: "20px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: 0,
		},
	},
	recommendedPosts: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	loadingPaper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "20px",
		borderRadius: "15px",
		height: "39vh",
	},
	postsOuterContainer: { width: "100%" },
	commentsInnerContainer: {
		overflowY: "auto",
		display: "flex",

		marginBottom: "0.3rem",
	},
	commentsTitlePost: {
		width: "50%",
		marginBottom: "0",
		"@media (max-width: 600px)": {
			fontSize: "1.6rem",
		},
	},
	postContainer: {
		display: "flex",
	},
	postsLinkOuterContainer: {
		display: "flex",
		flexWrap: "wrap",
	},
	postsLinkContainer: {
		display: "flex",
		alignItems: "center",
		margin: "0 0 10px 0",
		flexBasis: "100%",
	},
	numberContainer: {
		backgroundColor: "rgba(236,98,98,1)",
		width: "30px",
		height: "30px",
		borderRadius: "15px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: "10px",
		"@media (max-width: 600px)": {
			marginLeft: "5px",
			width: "26px",
			height: "26px",
			borderRadius: "13px",
		},
	},
	numberText: {
		color: "rgba(255,255,255,1)",
	},
	titleLinkPost: {
		fontSize: "1rem",
		margin: "8px 0 8px 15px",
		maxWidth: "85%",
		lineHeight: "1.3",
		[theme.breakpoints.down("sm")]: {
			width: "60%",
			margin: "6px 0 6px 10px",
			lineHeight: "1.2",
			fontSize: "0.9rem",
		},
	},
	numberLink: {
		display: "flex",
		alignItems: "center",
		color: "rgba(0,0,0,1)",
		width: "90%",
		"&:hover, &:focus": {
			textDecoration: "none",
		},
		[theme.breakpoints.down("sm")]: {
			width: "80%",
		},
	},
	buttonChangeType: {
		margin: "0 0 0 auto",
		backgroundColor: "rgba(236,98,98,1)",
		height: "40px",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	switchContainer: {
		display: "flex",
		alignItems: "center",
		margin: "0 0 0 auto",
	},
	switchChangeType: {
		color: "rgba(236,98,98,1)",
	},
	buttonsPost: {
		display: "flex",
		justifyContent: "space-evenly",
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	imageInput: {
		border: "1.5px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		display: "inline-block",
		padding: "6px 12px",
		cursor: "pointer",
		margin: "5px 0",
	},
	textInputPost: {
		margin: "5px 0",
	},
	imageWriting: {
		minHeight: "3vh",
		maxHeight: "10vh",
	},
	noPostsBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	likesBox: {
		color: "rgba(236,98,98,1)",
		marginLeft: "auto",
	},
	buttonDelete: {
		color: "rgba(236,98,98,1)",
		minWidth: "30px",
	},
	visibility: {
		display: "flex",
		alignItems: "center",
		color: "rgba(236,98,98,1)",
		marginRight: "5px",
	},
	visibilityCount: {
		marginRight: "5px",
	},
	paginationDiv: {
		marginBottom: "5px",
	},
}));
