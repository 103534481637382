import React, { useState, useRef, useEffect } from "react";
import {
	Typography,
	TextField,
	Button,
	Select,
	MenuItem,
	makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import StarRating from "../../ShowGeo/PlaceBox/StarRating"; // Adjust the path as needed
import marker from "../../../../images/marker.png";
import { translatePlaceType, switchIcons } from "../../../../utils/placeUtils";

const useStyles = makeStyles((theme) => ({
	wrapBox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	confirmBox: {
		backgroundColor: "#fff",
		borderRadius: 15,
		border: "1px solid",
		borderColor: "rgba(236, 98, 98, 1)",
		minWidth: "250px",
		padding: 5,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	confirmBoxButton: {
		backgroundColor: "rgba(236, 98, 98, 1)",
		borderRadius: 15,
		padding: "3px 10px",
		color: "#fff",
		textDecoration: "none",
		border: "none",
		cursor: "pointer",
	},
	input: {
		width: "100%",
		marginBottom: theme.spacing(1),
	},
	select: {
		width: "100%",
		marginBottom: theme.spacing(1),
	},
	imagePoint: {
		width: "24px",
		height: "24px",
	},
	starContainer: {
		display: "flex",
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		lineHeight: "1.1",
		paddingLeft: "30px",
		paddingRight: "30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	pointBox: {
		height: "26px",
		width: "26px",
		backgroundColor: "rgb(236,98,98)",
		borderRadius: "13px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	titleBox: {
		lineHeight: "1.1",
	},
}));

const PlaceReportBox = ({ place, post, postId, onWrapBoxHeightChange }) => {
	const classes = useStyles();
	const wrapBoxRef = useRef(null);
	const history = useHistory(); // Initialize useHistory hook
	const [wrapBoxHeight, setWrapBoxHeight] = useState(0);
	console.log(place);

	const handleButtonClick = () => {
		// Redirect user to post page
		if (post && post._id) {
			history.push(`/post/${post._id}`);
		}
	};

	{
		/*useEffect(() => {
		const intervalId = setInterval(() => {
			if (wrapBoxRef.current) {
				const height = wrapBoxRef.current.clientHeight;
				onWrapBoxHeightChange(height); // Call the parent function with height
				if (height > 0) {
					clearInterval(intervalId); // Stop the interval
				}
			}
		}, 700);

		// Clear interval on component unmount
		return () => clearInterval(intervalId);
	}, [onWrapBoxHeightChange]);*/
	}

	useEffect(() => {
		const observer = new MutationObserver((mutationsList) => {
			for (let mutation of mutationsList) {
				if (mutation.type === "childList") {
					const confirmBoxElement = document.getElementById(
						"confir-box-id-placebox"
					);
					if (confirmBoxElement) {
						const height = confirmBoxElement.clientHeight;
						onWrapBoxHeightChange(height);
						observer.disconnect(); // Disconnect observer once element is found
					}
				}
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect(); // Cleanup on unmount
		};
	}, [onWrapBoxHeightChange]);

	const rateAuthor = postId
		? place?.placeRate.find((rate) => rate.postId === postId)?.rate || "N/A"
		: null;

	const averageRate =
		place.placeRate.reduce((total, rate) => total + rate.rate, 0) /
			place.placeRate.length || "N/A";

	return (
		<div
			className={classes.wrapBox}
			ref={wrapBoxRef}
			id="confir-box-id-placebox"
		>
			<div className={classes.confirmBox}>
				<Typography variant="h6" className={classes.titleBox}>
					{post?.title}
				</Typography>
				<Typography variant="body1">{place?.placeName}</Typography>
				<Typography variant="body2">
					Средняя оценка: {averageRate.toFixed(1)}
				</Typography>
				<StarRating rating={averageRate} />
				{post ? (
					<Button className={classes.buttonPrimary} onClick={handleButtonClick}>
						Перейти в пост
					</Button>
				) : null}
			</div>
			<div className={classes.pointBox}>
				<img
					className={classes.imagePoint}
					src={switchIcons(place?.placeType)}
					alt="Point of interest"
				/>
			</div>
		</div>
	);
};

export default PlaceReportBox;
