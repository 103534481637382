import React, { useState } from "react";
import {
	TextField,
	Button,
	Grid,
	Typography,
	MenuItem,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { addPlaceDescription } from "../../../../actions/placeDescription";
import { useDispatch } from "react-redux";
import { translateDayToRussian } from "../../../../utils/placeUtils";

const SuggestPlaceInfo = ({ place, userId, classes, setIsInfoFormOpen }) => {
	const dispatch = useDispatch();
	const [address, setAddress] = useState(place?.address ? place?.address : "");
	const [phone, setPhone] = useState(place?.phone ? place?.phone : "");
	const [website, setWebsite] = useState(place?.website ? place?.website : "");
	const [workingHours, setWorkingHours] = useState({
		monday: ["", ""],
		tuesday: ["", ""],
		wednesday: ["", ""],
		thursday: ["", ""],
		friday: ["", ""],
		saturday: ["", ""],
		sunday: ["", ""],
	});
	const [dayOff, setDayOff] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	});

	const [paymentMethod, setPaymentMethod] = useState(
		place?.paymentMethod ? place?.paymentMethod : ""
	);
	const [cuisine, setCuisine] = useState(place?.cuisine ? place?.cuisine : "");

	// Validation states
	const [phoneError, setPhoneError] = useState("");
	const [websiteError, setWebsiteError] = useState("");
	const [workingHoursErrors, setWorkingHoursErrors] = useState({
		monday: ["", ""],
		tuesday: ["", ""],
		wednesday: ["", ""],
		thursday: ["", ""],
		friday: ["", ""],
		saturday: ["", ""],
		sunday: ["", ""],
	});

	// Validate phone number format in real-time
	const handlePhoneChange = (e) => {
		const phoneValue = e.target.value;
		const phoneFormat = /^\+\d{1,15}$/;
		setPhone(phoneValue);

		if (phoneValue && !phoneFormat.test(phoneValue)) {
			setPhoneError(
				"Пожалуйста, введите телефон в формате +11111111111 (максимум 15 цифр)."
			);
		} else {
			setPhoneError(""); // Clear error if valid
		}
	};

	// Validate website length in real-time
	const handleWebsiteChange = (e) => {
		const websiteValue = e.target.value;
		setWebsite(websiteValue);

		if (websiteValue.length > 150) {
			setWebsiteError("Длина веб-сайта не должна превышать 150 символов.");
		} else {
			setWebsiteError(""); // Clear error if valid
		}
	};

	// Handle checkbox change for day off
	const handleDayOffChange = (day) => {
		setDayOff((prevDayOff) => ({
			...prevDayOff,
			[day]: !prevDayOff[day],
		}));

		// If it's checked, set the working hours to ["dayoff", "dayoff"]
		setWorkingHours((prevHours) => ({
			...prevHours,
			[day]: !dayOff[day] ? ["dayoff", "dayoff"] : ["", ""],
		}));

		// Clear errors if marked as day off
		setWorkingHoursErrors((prevErrors) => ({
			...prevErrors,
			[day]: ["", ""],
		}));
	};

	// Validate and update working hours if not day off
	const handleWorkingHoursChange = (day, index, value) => {
		const timeFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

		// Prevent editing if it's a day off
		if (dayOff[day]) return;

		setWorkingHours((prevHours) => ({
			...prevHours,
			[day]: prevHours[day].map((time, i) => (i === index ? value : time)),
		}));

		// Validate time format
		if (value && !timeFormat.test(value)) {
			setWorkingHoursErrors((prevErrors) => ({
				...prevErrors,
				[day]: prevErrors[day].map((err, i) =>
					i === index
						? "Пожалуйста, введите время в формате ЧЧ:ММ или Ч:ММ."
						: err
				),
			}));
		} else {
			// Clear error if valid
			setWorkingHoursErrors((prevErrors) => ({
				...prevErrors,
				[day]: prevErrors[day].map((err, i) => (i === index ? "" : err)),
			}));
		}
	};

	const handleSubmit = async () => {
		const description = {
			placeId: place?._id,
			userId: userId,
			address,
			phone,
			website,
			workingHours,
			paymentMethod,
			cuisine,
		};

		try {
			await dispatch(addPlaceDescription(description));
			setIsInfoFormOpen(false); // Close form after submit
		} catch (error) {
			console.error("Error while submitting place info:", error);
		}
	};

	return (
		<div>
			<Typography variant="h6">Добавить информацию о месте</Typography>

			{/* Address */}
			<TextField
				label="Адрес"
				variant="outlined"
				value={address}
				onChange={(e) => setAddress(e.target.value)}
				fullWidth
				margin="normal"
			/>

			{/* Phone */}
			<TextField
				label="Телефон (+11111111111)"
				variant="outlined"
				value={phone}
				onChange={handlePhoneChange}
				fullWidth
				margin="normal"
				error={!!phoneError}
				helperText={phoneError}
			/>

			{/* Website */}
			<TextField
				label="Веб-сайт (не более 150 символов)"
				variant="outlined"
				value={website}
				onChange={handleWebsiteChange}
				fullWidth
				margin="normal"
				error={!!websiteError}
				helperText={websiteError}
			/>

			{/* Working Hours */}
			{place?.placeType !== "city" && (
				<>
					<Typography variant="h6">Часы работы (ЧЧ:ММ)</Typography>
					{[
						"monday",
						"tuesday",
						"wednesday",
						"thursday",
						"friday",
						"saturday",
						"sunday",
					].map((day) => (
						<Grid container spacing={2} key={day} alignItems="center">
							<Typography>{translateDayToRussian(day)}</Typography>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={dayOff[day]}
											onChange={() => handleDayOffChange(day)}
											color="primary"
										/>
									}
									label="Выходной"
								/>
							</Grid>
							{!dayOff[day] && (
								<>
									<Grid item xs={6}>
										<TextField
											label={`${translateDayToRussian(day)}, начало (ЧЧ:ММ)`}
											variant="outlined"
											value={workingHours[day][0]}
											onChange={(e) =>
												handleWorkingHoursChange(day, 0, e.target.value)
											}
											fullWidth
											error={!!workingHoursErrors[day][0]}
											helperText={workingHoursErrors[day][0]}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={`${translateDayToRussian(day)}, конец (ЧЧ:ММ)`}
											variant="outlined"
											value={workingHours[day][1]}
											onChange={(e) =>
												handleWorkingHoursChange(day, 1, e.target.value)
											}
											fullWidth
											error={!!workingHoursErrors[day][1]}
											helperText={workingHoursErrors[day][1]}
										/>
									</Grid>
								</>
							)}
						</Grid>
					))}
				</>
			)}

			{/* Payment Method */}
			{place?.placeType === "restaurant" && (
				<TextField
					label="Способ оплаты"
					variant="outlined"
					select
					value={paymentMethod}
					onChange={(e) => setPaymentMethod(e.target.value)}
					fullWidth
					margin="normal"
				>
					<MenuItem value="Только наличные">Только наличные</MenuItem>
					<MenuItem value="Наличные и карты">Наличные и карты</MenuItem>
					<MenuItem value="Наличные, карты и QR-код">
						Наличные, карты и QR-код
					</MenuItem>
				</TextField>
			)}

			{/* Cuisine */}
			{place?.placeType === "restaurant" && (
				<TextField
					label="Кухня (через запятую)"
					variant="outlined"
					value={cuisine}
					onChange={(e) => setCuisine(e.target.value.split(","))}
					fullWidth
					margin="normal"
				/>
			)}

			<Button
				className={classes.buttonPrimary}
				onClick={handleSubmit}
				style={{ marginTop: "20px" }}
			>
				Отправить
			</Button>
		</div>
	);
};

export default SuggestPlaceInfo;
