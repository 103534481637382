import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  commentsOuterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "15px",
    padding: "20px",
    marginTop: "10px",
    "@media (max-width: 600px)": {
      padding: "0",
      border: "none",
    },
  },
  commentsInnerContainer: {
    minHeight: "80px",
  },
  buttonPrimary: {
    height: "40px",
    borderRadius: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    padding: "0 30px",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  nameBox: {
    height: "33px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    borderRadius: "20px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    justifyContent: "space-evenly",
    textDecoration: "none",
    "@media (max-width: 600px)": {
      //margin: "0px 5px",
      //position: "absolute",
      //top: "5px",
      //left: "0",
      height: "20px",
      width: "40%",
    },
  },
  nameBoxSpecial: {
    height: "33px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    borderRadius: "20px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    justifyContent: "space-evenly",
    textDecoration: "none",
    border: "1px solid",
    borderColor: "rgb(9,9,121)",
    "@media (max-width: 600px)": {
      //margin: "0px 5px",
      //position: "absolute",
      //top: "5px",
      //left: "0",
      height: "20px",
      width: "40%",
    },
  },
  userName: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: 1,
    textAlign: "center",
  },
  userLogin: {
    fontSize: "12px",
    lineHeight: 1,
  },
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  commentTextBox: {
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "15px",
    width: "75%",
    padding: "3px 5px",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "80%",
      padding: "28px 5px 3px 5px",
    },
  },
  likesBox: {
    color: "rgba(236,98,98,1)",
  },
  likesNumber: {
    fontSize: "18px",
  },
  mobileComment: {
    "@media (max-width: 600px)": {
      position: "relative",
    },
  },
  marginBottom: {
    marginBottom: "10px",
  },
  commentText: {
    marginLeft: "5px",
    lineHeight: "1.1",
    "@media (max-width: 600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.05",
    },
  },
  profileTimeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "19%",
    marginRight: "5px",
    "@media (max-width: 1200px)": {
      width: "24%",
    },
    "@media (max-width: 900px)": {
      width: "29%",
    },
    "@media (max-width: 600px)": {
      margin: "0px 5px",
      position: "absolute",
      top: "5px",
      left: "0",
      width: "100%",
      height: "20px",
      display: "flex",
      flexDirection: "row",
    },
  },
  momentComment: {
    fontSize: "12px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      marginLeft: "5px",
      textAlign: "left",
    },
  },
  commentButtons: {
    display: "flex",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  responseBox: {
    marginBottom: "10px",
  },
  responseContainer: {
    position: "relative",
  },
  profileTimeBoxResponse: {
    margin: "0px 5px",
    position: "absolute",
    top: "5px",
    left: "0",
    height: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "60%",
    marginRight: "5px",
    "@media (max-width: 1200px)": {
      width: "70%",
    },
    "@media (max-width: 900px)": {
      width: "90%",
    },
    "@media (max-width: 600px)": {
      margin: "0px 5px",
      position: "absolute",
      top: "5px",
      left: "0",
      width: "100%",
      height: "20px",
      display: "flex",
      flexDirection: "row",
    },
  },
  nameBoxResponse: {
    height: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    borderRadius: "20px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40%",
    justifyContent: "space-evenly",
    marginRight: "5px",
    textDecoration: "none",
    "@media (max-width: 600px)": {
      width: "40%",
    },
  },
  nameBoxSpecialResponse: {
    height: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    borderRadius: "20px",
    padding: "0px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40%",
    justifyContent: "space-evenly",
    textDecoration: "none",
    marginRight: "5px",
    border: "1px solid",
    borderColor: "rgb(9,9,121)",
    "@media (max-width: 600px)": {
      width: "40%",
    },
  },
  emptySpaceResponse: {
    width: "19%",
    "@media (max-width: 1200px)": {
      width: "25%",
    },
    "@media (max-width: 900px)": {
      width: "30%",
    },
    "@media (max-width: 600px)": {
      width: "5%",
    },
  },
  momentCommentResponse: {
    fontSize: "12px",
  },
  commentTextResponse: {
    padding: "28px 5px 3px 5px",
    lineHeight: "1.1",
    "@media (max-width: 600px)": {
      padding: "0px 5px 3px 5px",
      fontSize: "0.9rem",
      lineHeight: "1.05",
    },
  },
}));
