import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const PortalGeneral = ({
	children,
	getHTMLElementId,
	placemarkLayout,
}) => {
	const mount = useRef(null);
	const el = useRef(document.createElement("div"));

	useEffect(() => {
		const mountNode = document.getElementById(getHTMLElementId);
		console.log(mountNode);
		console.log("Kek");
		if (!mountNode) {
			console.error(`Element with id "${getHTMLElementId}" not found.`);
			return;
		}
		mount.current = mountNode;
		console.log(mount);
		mountNode.appendChild(el.current);
		return () => {
			mountNode.removeChild(el.current);
		};
	}, [getHTMLElementId, placemarkLayout]);

	if (!mount) return null;
	return createPortal(children, el.current);
};
