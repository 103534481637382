import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  calendarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: "10px",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "15px",
  },
  month: {
    width: "100%",
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    backgroundColor: "rgba(236,98,98,1)",
    fontSize: "0.8rem",
    lineHeight: "1.2",
  },
  date: {
    fontSize: "1.5rem",
    lineHeight: "1.2",
  },
  year: {
    fontSize: "0.8rem",
    lineHeight: "1.2",
  },
}));
