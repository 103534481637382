import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPostsByUser } from "../../../actions/posts.js";

import useStyles from "./styles";

const Paginate = ({ page, urlLogin }) => {
  let numberOfPages = useSelector((state) => state.posts?.post?.numberOfPages);
  numberOfPages = numberOfPages ? numberOfPages : 1;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (page) dispatch(getPostsByUser(urlLogin, page));
  }, [page]);

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="secondary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          to={`/user/${urlLogin}?page=${item.page}`}
        />
      )}
    />
  );
};

export default Paginate;
