import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	footer: {
		padding: "1rem 0",
	},
	divider: {
		margin: "10px 0",
		backgroundColor: "rgba(236,98,98,1)",
	},
	brandContainer: {
		display: "flex",
		alignItems: "center",
		textDecoration: "none",
		maxWidth: "220px",
	},
	footerContainer: {
		padding: 0,
	},
	gridElement: {
		"@media (max-width: 600px)": {
			padding: "10px 20px",
		},
	},
	brandContainerSupport: {
		display: "flex",
		alignItems: "center",
		textDecoration: "none",
		borderRadius: "5px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		maxWidth: "220px",
		padding: "5px",
		marginTop: "10px",
		transition: "0.3s",
		"&:hover p": { color: "rgba(255,255,255,1)" },
		"&:hover, &:focus": {
			borderColor: "rgba(255,255,255,1)",
			background:
				"linear-gradient(90deg, rgba(236,98,98,1) 0%, rgba(9,9,121,1) 100%, rgba(0,212,255,1) 100%)",
		},
	},
	heading_black: {
		color: "rgba(0,0,0,1)",
		textDecoration: "none",
	},
	heading_support: {
		color: "rgba(236,98,98,1)",
		textDecoration: "none",
		marginLeft: "10px",
		fontSize: "18px",
		transition: "0.3s",
	},
	heading: {
		color: "rgba(236,98,98,1)",
		textDecoration: "none",
	},
	image: {
		marginLeft: "10px",
		"@media (max-width: 600px)": {
			marginLeft: "0px",
		},
	},
	boostyImg: {
		maxHeight: "2rem",
		borderRadius: "5px",
		transition: "0.3s",
	},
	socialImg: {
		maxHeight: "2.5rem",
		color: "rgba(236,98,98,1)",
	},
	linkTelegram: {
		marginLeft: "5px",
	},
}));
