import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  profileCard: {
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
  },

  gridContainer: {
    [theme.breakpoints.down("s")]: {
      flexDirection: "column",
    },
  },
  profilePicture: {
    margin: "0 auto",
    width: "100px",
    height: "100px",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    fontSize: "2rem",
    "@media (max-width: 600px)": {
      width: "50px",
      height: "50px",
      fontSize: "1.2rem",
      margin: "0",
    },
    "@media (max-width: 400px)": {
      width: "40px",
      height: "40px",
      fontSize: "1.2rem",
      margin: "0",
    },
  },
  profileLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "20px auto",
    width: "100%",
    "@media (max-width: 600px)": {
      flexDirection: "row",
      justifyContent: "space-evenly",
      margin: "5px",
    },
  },
  profileName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  adminSponsorLabel: {
    borderRadius: "20px",
    backgroundColor: "rgba(9,9,121,1)",
    color: "rgba(255,255,255,1)",
    margin: "7px 0",
    padding: "0 10px",
    "@media (max-width: 400px)": {
      fontSize: "0.9rem",
    },
  },
  profileNameText: {
    "@media (max-width: 400px)": {
      fontSize: "1.1rem",
      lineHeight: "1.2",
    },
  },
  profileNameLogin: {
    "@media (max-width: 400px)": {
      fontSize: "1rem",
    },
  },
}));
