import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, CircularProgress, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PlaceImages from "../PlaceImages/PlaceImages";
import ShowGeo from "../../Maps/ShowGeo/ShowGeo";
import { addCityToPlace, getPlace } from "../../../actions/place.js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import StarRating from "../../Maps/ShowGeo/PlaceBox/StarRating.jsx";
import SuggestPlace from "./SuggestPlace/SuggestPlace.jsx";
import useStyles from "./styles.js";
import PostInPlace from "./PostInPlace/PostInPlace.jsx";
import PlaceReviewList from "./PlaceReviewList/PlaceReviewList.jsx";
import {
	translatePlaceType,
	switchIcons,
	translatePlaceTypePrepositionalCase,
} from "../../../utils/placeUtils";
import PlaceCityToDo from "./PlaceCityToDo/PlaceCityToDo.jsx";
import SearchGeo from "../../Maps/SelectGeo/SearchGeo/SearchGeo.jsx";
import PlaceInformation from "./PlaceInformation/PlaceInformation.jsx";
import SuggestPlaceInfo from "./SuggestPlaceInfo/SuggestPlaceInfo.jsx";

const LocationDetails = () => {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const place = useSelector((state) => state.place.place);
	const [isSuggestedText, setIsSuggestedText] = useState(false);
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState("");
	const [isAddCity, setIsAddCity] = useState(false);
	const [selectedCity, setSelectedCity] = useState(null);
	const [isInfoFormOpen, setIsInfoFormOpen] = useState(false);
	let user = JSON.parse(localStorage.getItem("profile"));
	user = user?.result;
	let userId = user?._id;
	const dispatchResult = useSelector((state) => state.place.boolean);
	const isLoading = useSelector((state) => state.place.isLoading);

	const switchSuggestText = () => {
		setIsSuggestedText((prevIs) => !prevIs);
	};

	const handleSelectedCity = async (selectedPlace) => {
		setSelectedCity(() => selectedPlace);
		await dispatch(addCityToPlace(place._id, selectedPlace._id));
		setIsAddCity((prev) => !prev);
	};

	const handleOpenMap = () => {
		history.push("/map");
	};

	useEffect(() => {
		dispatch(getPlace(id));
	}, []);

	const averageRate = place
		? place.placeRate.reduce((total, rate) => total + rate.rate, 0) /
				place.placeRate.length || "N/A"
		: null;

	const averageHotelRate = place ? place?.averageRate : null;
	const averageCheck = place ? place?.averageCheck : null;
	const averageTicketAdult = place ? place?.averageTicketAdult : null;
	const averageTicketChild = place ? place?.averageTicketChild : null;

	const openCity = () => (window.location.href = `/place/${place?.city}`);

	return (
		<>
			<Helmet>
				<title>
					{`${place?.placeName}`} -{" "}
					{`${translatePlaceType(place?.placeType)?.toLowerCase()}`} на карте,
					ToTravelRu
				</title>
				<meta
					name="description"
					content={`${place?.placeName}, место на карте, ${place?.placeType}, координаты, как добраться до ${place?.placeName} - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${place?.placeName}, карта, место на карте, отчет, отчеты, путешествия, travel, totravel, ToTravelRu, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия`}
				/>
			</Helmet>
			<div className={classes.root}>
				<Grid container spacing={2}>
					{/* Левая часть */}
					<Grid item xs={12} sm={6}>
						{" "}
						{/* Apply grid item style */}
						<div className={classes.gridItem}>
							{isLoading ? (
								<CircularProgress
									style={{ color: "white", margin: "0 auto" }}
									size="1rem"
								/>
							) : (
								<Typography variant="h4">{place?.placeName}</Typography>
							)}
							<div className={classes.rating}>
								<div className={classes.ratingFlex}>
									<StarRating rating={averageRate} />
									<Typography className={classes.rateReports}>
										{place?.placeRate.length ? averageRate.toFixed(1) : "N/A"}
									</Typography>
								</div>
								<Typography className={classes.numberReports}>
									Отзывов: {place?.placeRate.length}
								</Typography>
							</div>
							<div className={classes.placeTypeBoxes}>
								<Box className={classes.boxType}>
									<img
										src={switchIcons(place?.placeType)}
										className={classes.boxTypeImg}
									/>
									<Typography
										variant="subtitle1"
										className={classes.boxTypeText}
									>
										{translatePlaceType(place?.placeType)}{" "}
									</Typography>
								</Box>
								{place?.placeType !== "city" && place?.cityPlaceName ? (
									<Box className={classes.boxType}>
										<Button
											component="span"
											name="test"
											onClick={openCity}
											className={classes.buttonCity}
										>
											<img
												src="https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png"
												className={classes.boxTypeImg}
											/>
											<Typography
												variant="subtitle1"
												className={classes.boxTypeText}
											>
												{place?.cityPlaceName}
											</Typography>
										</Button>
									</Box>
								) : null}
							</div>
							{place && place?.placeType !== "city" && !place?.city && user ? (
								<Button
									className={classes.buttonPrimary}
									onClick={() => {
										setIsAddCity((prev) => !prev);
									}}
								>
									{isAddCity ? "Закрыть" : "Указать город"}
								</Button>
							) : null}
							{isAddCity ? (
								<SearchGeo onSelectedPlace={handleSelectedCity} filter="city" />
							) : null}

							<div className={classes.mapContainer}>
								<div className={classes.mapButtonFlex}>
									<Typography variant="h5" gutterBottom>
										Карта
									</Typography>
									<Button
										className={classes.buttonPrimary}
										onClick={handleOpenMap}
									>
										Открыть карту
									</Button>
								</div>
								<ShowGeo place={place} placeComponent={true} />
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className={classes.gridItem}>
							<Typography variant="h5">
								Информация о{" "}
								{translatePlaceTypePrepositionalCase(
									place?.placeType
								)?.toLowerCase()}{" "}
								{place?.placeName}
							</Typography>
							{isLoading ? (
								<CircularProgress
									style={{ color: "white", margin: "0 auto" }}
									size="1rem"
								/>
							) : place?.description ? (
								<div className={classes.descriptionTextBox}>
									<Typography>{place?.description}</Typography>
								</div>
							) : dispatchResult ? (
								<Typography>
									Спасибо! Администраторы скоро рассмотрят Ваше предложение!
								</Typography>
							) : (
								<Button
									className={
										isSuggestedText
											? classes.buttonSecondarySuggestText
											: classes.buttonPrimarySuggestText
									}
									onClick={switchSuggestText}
								>
									{isSuggestedText ? "Закрыть" : "Предложить описание места"}
								</Button>
							)}
							<PlaceInformation
								place={place}
								averageHotelRate={averageHotelRate}
								averageCheck={averageCheck}
								averageTicketAdult={averageTicketAdult}
								averageTicketChild={averageTicketChild}
							/>
							{isSuggestedText ? (
								<SuggestPlace
									place={place}
									userId={userId}
									classes={classes}
									isSuggestedText={isSuggestedText}
									setIsSuggestedText={setIsSuggestedText}
								/>
							) : null}

							{isInfoFormOpen && (
								<SuggestPlaceInfo
									place={place}
									userId={userId}
									classes={classes}
									setIsInfoFormOpen={setIsInfoFormOpen}
								/>
							)}
							{user && (
								<Button
									className={classes.buttonPrimary}
									onClick={() => setIsInfoFormOpen(!isInfoFormOpen)}
									style={{ margin: "20px 0" }}
								>
									{isInfoFormOpen ? "Закрыть" : "Добавить информацию о месте"}
								</Button>
							)}
						</div>
					</Grid>
				</Grid>
				{place?.placeType === "city" ? (
					<div className={classes.postsContainer}>
						<PlaceCityToDo place={place} />
					</div>
				) : null}
				<div className={classes.postsContainer}>
					<PlaceReviewList place={place} user={user} />
				</div>
				<div className={classes.postsContainer}>
					{" "}
					<Typography variant="h6" gutterBottom>
						Подробные посты о месте
					</Typography>
					{place?.postsId[0] !== null ? (
						<Grid container spacing={2}>
							{place?.postsId.map((postId, index) => (
								<Grid key={index} item xs={12} sm={6} md={4} lg={4}>
									<PostInPlace postId={postId} className={classes.postItem} />
								</Grid>
							))}
						</Grid>
					) : (
						<Typography>Постов пока нет</Typography>
					)}
				</div>
			</div>
		</>
	);
};

export default LocationDetails;
