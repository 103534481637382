import { ADD_ALERT, DELETE_ALL_ALERTS } from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addAlert = (alertText, type, expireAt, id) => async (dispatch) => {
	try {
		const { data } = await api.addAlert(alertText, type, expireAt, id);
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLatestAlert = () => async (dispatch) => {
	try {
		const { data } = await api.getLatestAlert();
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteAllAlerts = (id) => async (dispatch) => {
	try {
		const { data } = await api.deleteAllAlerts(id);
		dispatch({ type: DELETE_ALL_ALERTS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
