import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	card: {
		padding: "10px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		borderRadius: "15px",
		position: "relative",
		border: "solid 1px",
		borderColor: "rgba(236,98,98,1)",
	},
	editTitle: {
		margin: "0 auto 0 0",
	},
	gridContainer: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
		},
	},
	profilePicture: {
		margin: "20px 0px",
		width: "150px",
		height: "150px",
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		fontSize: "3.5rem",
		"@media (max-width: 600px)": {
			width: "100px",
			height: "100px",
			fontSize: "2.5rem",
		},
	},
	photoName: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	divider: {
		backgroundColor: "rgba(0, 0, 0, 1)",
		border: "none",
		height: "1px",
		width: "50%",
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	fileInput: {
		color: "black",
		display: "flex",
		alignItems: "center",
		"@media (max-width: 600px)": {
			flexDirection: "column",
		},
	},
	form: {
		width: "100%",
		"@media (max-width: 800px)": {
			width: "100%",
		},
	},
	imageInput: {
		display: "none",
	},
	labelInput: {
		border: "1.5px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "6px 12px",
		cursor: "pointer",
		margin: "0",
		height: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		maxWidth: "200px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	fileNameBox: {
		border: "1.5px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "5px",
		minHeight: "25px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		padding: "0 5px",
		marginLeft: "5px",
		"@media (max-width: 600px)": {
			marginLeft: "0px",
			marginTop: "8px",
		},
	},
	flex: {
		display: "flex",
	},
	width100: {
		width: "100%",
	},
	buttonDelete: {
		color: "rgba(236,98,98,1)",
		minWidth: "30px",
	},
}));
