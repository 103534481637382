import React, { useEffect, useState } from "react";
import {
	Typography,
	Link,
	Card,
	CardMedia,
	CardActionArea,
	CardContent,
} from "@material-ui/core";
import useStyles from "./styles.js";
import StarRating from "../../../../../Maps/ShowGeo/PlaceBox/StarRating";

const PlaceCard = ({ p, cardType }) => {
	const classes = useStyles();
	return (
		<>
			<Link href={`/place/${p?._id}`} className={classes.link}>
				<Card className={classes.card}>
					<CardActionArea>
						<CardContent className={classes.CardContent}>
							<div className={classes.flexHead}>
								<div className={classes.mediaBox}>
									<CardMedia
										className={classes.media}
										image={
											p?.photoFromReview && p?.photoFromReview.length > 0
												? p?.photoFromReview[0]
												: "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
										}
										alt="Фото места"
									/>
								</div>
								<div className={classes.titleBox}>
									<Typography className={classes.title}>
										{p?.placeName}
									</Typography>
									<div className={classes.rating}>
										<div className={classes.ratingFlex}>
											<StarRating
												rating={
													p?.placeRate.reduce(
														(total, rate) => total + rate.rate,
														0
													) / p?.placeRate.length || 5
												}
											/>
											<Typography className={classes.rateReports}>
												{p?.placeRate.length
													? (
															p?.placeRate.reduce(
																(total, rate) => total + rate.rate,
																0
															) / p?.placeRate.length
													  ).toFixed(1)
													: "N/A"}
											</Typography>
										</div>
										<Typography className={classes.numberReports}>
											Отзывов: {p?.placeRate.length}
										</Typography>
									</div>
									<Typography className={classes.textDescription}>
										{p?.description?.length > 120
											? `${p.description.substring(0, 120)}...`
											: p?.description}
									</Typography>
								</div>
							</div>
						</CardContent>
					</CardActionArea>
				</Card>
			</Link>
		</>
	);
};

export default PlaceCard;
