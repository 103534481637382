import {
	ADD_PLACE,
	GET_PLACE,
	ADD_POST_TO_PLACE,
	ADD_PLACE_DESCRIPTION,
	GET_PLACE_DESCRIPTION,
	VERIFY_PLACE_DESCRIPTION,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addPlaceDescription = (description) => async (dispatch) => {
	try {
		const { data } = await api.addPlaceDescription(description);
		dispatch({ type: ADD_PLACE_DESCRIPTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getAllPlaceDescriptions = () => async (dispatch) => {
	try {
		const { data } = await api.getAllPlaceDescriptions();
		dispatch({ type: GET_PLACE_DESCRIPTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const deletePlaceDescriptionById = (descId) => async (dispatch) => {
	try {
		const { data } = await api.deletePlaceDescriptionById(descId);
		dispatch({ type: VERIFY_PLACE_DESCRIPTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const acceptPlaceDescription = (descId) => async (dispatch) => {
	try {
		const { data } = await api.acceptPlaceDescription(descId);
		dispatch({ type: VERIFY_PLACE_DESCRIPTION, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
