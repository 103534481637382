import React, { useState } from "react";
import {
	Avatar,
	Button,
	Paper,
	Grid,
	Typography,
	Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
//import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
//import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import PasswordChecklist from "react-password-checklist";

import useStyles from "./styles";
import Input from "./Input";
import { signin, signup } from "../../actions/auth";
import { Link } from "react-router-dom";

const initialState = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	confirmPassword: "",
};

const Auth = () => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const [isSignup, setIsSignup] = useState(false);
	const [formData, setFormData] = useState(initialState);
	const [passwordCheck, setPasswordCheck] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const errorMessage = useSelector((state) => {
		return state?.errorRed?.error;
	});

	const handleShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const switchMode = () => {
		setIsSignup((prevIsSignup) => !prevIsSignup);
	};

	const googleSuccess = async (res) => {
		const userObject = jwt_decode(res.credential);
		try {
			dispatch({ type: "AUTH", data: userObject });
			history.push("/");
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (isSignup) {
			if (passwordCheck) {
				dispatch(signup(formData, history));
			} else {
				alert("Пароль не соответствует критериям или пароли не совпадают");
			}
		} else {
			dispatch(signin(formData, history));
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return (
		<Container component="main" maxWidth="xs">
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography variant="h5">
					{isSignup ? "Регистрация" : "Вход"}
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						{isSignup && (
							<>
								<div className={classes.login}>
									<Typography className={classes.loginAt}>@</Typography>
									<Input
										name="login"
										label="Придумайте логин"
										handleChange={handleChange}
										autoFocus
									/>
								</div>
							</>
						)}
						{isSignup && (
							<>
								<Input
									name="firstName"
									label="Имя"
									handleChange={handleChange}
									autoFocus
									half
								/>
								<Input
									name="lastName"
									label="Фамилия"
									handleChange={handleChange}
									autoFocus
									half
								/>
							</>
						)}
						{isSignup ? (
							<Input
								name="email"
								label="Email"
								handleChange={handleChange}
								type="email"
							/>
						) : (
							<Input
								name="email"
								label="Email"
								handleChange={handleChange}
								type="email"
							/>
						)}
						<Input
							name="password"
							label="Пароль"
							handleChange={handleChange}
							type={showPassword ? "text" : "password"}
							handleShowPassword={handleShowPassword}
						/>
						{isSignup && (
							<>
								<Input
									name="confirmPassword"
									label="Повторите пароль"
									handleChange={handleChange}
									type="password"
								/>
								<PasswordChecklist
									className={classes.checkList}
									rules={["minLength", "number", "capital", "match"]}
									messages={{
										minLength: "Пароль должен содержать минимум 8 символов",
										number: "Пароль должен содержать хотя бы 1 цифру",
										capital:
											"Пароль должен содержать хотя бы 1 заглавную букву",
										match: "Пароли должны совпадать",
									}}
									minLength={8}
									value={formData.password}
									valueAgain={formData.confirmPassword}
									onChange={(isValid) => {
										setPasswordCheck(isValid);
									}}
									iconSize={14}
								/>
							</>
						)}
					</Grid>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						className={classes.submit}
					>
						{isSignup ? "Зарегистрироваться" : "Войти"}
					</Button>

					{isSignup ? (
						<>
							<Typography className={classes.privacyText}>
								Нажимая "Зарегистрироваться", вы принимаете{" "}
								<a className={classes.privacyRef} href="/agreement">
									пользовательское соглашение
								</a>{" "}
								и{" "}
								<a className={classes.privacyRef} href="/privacy">
									политику в отношении обработки персональных данных
								</a>
							</Typography>
						</>
					) : null}
					<div className={classes.errorBox}>
						<Typography align="center">{errorMessage}</Typography>
					</div>
					{/*<div style={{ display: "flex", justifyContent: "center" }}>
            <GoogleOAuthProvider clientId="588078474741-sr8um9n7unp6dgckv4h8fa554bihkucc.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={googleSuccess}
                onError={() => {
                  toast.error("Попробуйте зарегистрироваться");
                }}
              />
            </GoogleOAuthProvider>
              </div>*/}
					{isSignup ? null : (
						<Button
							component={Link}
							to="/changePasswordAsk"
							className={classes.buttonPassword}
						>
							Забыли пароль?
						</Button>
					)}
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Button onClick={switchMode}>
								{isSignup
									? "Уже есть аккаунт? Войти"
									: "Еще нет аккаунта? Зарегистрироваться"}
							</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default Auth;
