import React from "react";
import { Typography, Card, Box } from "@material-ui/core";
import useStyles from "./styles";

const Calendar = ({ createdAt }) => {
  const classes = useStyles();
  const splitDate = createdAt.split("-");
  const year = splitDate[0];
  const month = splitDate[1];
  const date = splitDate[2].split("T")[0];

  const monthArr = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  return (
    <Card className={classes.calendarContainer}>
      <Typography className={classes.month}>
        {monthArr[month - 1].slice(0, 3)}
      </Typography>
      <Typography className={classes.date}>{date}</Typography>

      <Typography className={classes.year}>{year}</Typography>
    </Card>
  );
};

export default Calendar;
