import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    backdropFilter: "brightness(100%)",
    width: "100%",
  },
  card: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    margin: "10px",
    flex: 1,
  },
  imageSection: {
    maxWidth: "50%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  postsOuterContainer: {},
  commentsInnerContainer: {
    overflowY: "auto",
    display: "flex",
    marginBottom: "0.3rem",
  },
  commentsTitlePost: {
    width: "50%",
    marginBottom: "0",
  },
  postContainer: {
    display: "flex",
  },
  postsLinkOuterContainer: {
    display: "flex",
  },
  postsLinkContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0 15px",
  },
  numberContainer: {
    backgroundColor: "rgba(236,98,98,1)",
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  numberText: {
    color: "rgba(255,255,255,1)",
  },
  titleLinkPost: {
    fontSize: "18px",
    margin: "8px 0 8px 15px",
  },
  numberLink: {
    color: "rgba(0,0,0,1)",
    width: "25%",
    "&:hover, &:focus": {
      textDecoration: "none",
    },
  },
  titlePost: {
    fontSize: "18px",
  },
  buttonChangeType: {
    margin: "0 0 0 auto",
    backgroundColor: "rgba(236,98,98,1)",
    height: "40px",
    color: "rgba(255,255,255,1)",
    borderRadius: "20px",
    padding: "0 30px",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 auto",
  },
  switchChangeType: {
    color: "rgba(236,98,98,1)",
  },
  buttonsPost: {
    display: "flex",
    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },
  buttonPrimary: {
    borderRadius: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    margin: "7px 10px 7px 0",
    lineHeight: "1.3",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  imageInput: {
    display: "none",
  },
  labelInput: {
    border: "1.5px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "6px 12px",
    cursor: "pointer",
    margin: "5px 0",
    width: "165px",
    height: "20px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
  },
  uploadText: {
    fontSize: "14px",
    marginLeft: "5px",
  },
  circularPhoto: {
    color: "rgb(256,256,256)",
  },
  fileText: {
    padding: "0 5px",
  },
  flexImg: {
    display: "flex",
    flexDirection: "column",
  },
  flexImgUpload: {
    display: "flex",
    alignItems: "center",
  },
  textInputPost: {
    margin: "5px 0",
  },
  fileNameBox: {
    border: "1.5px solid",
    borderColor: "rgba(236,98,98,1)",
    borderRadius: "5px",
    cursor: "pointer",
    height: "25px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    marginLeft: "5px",
  },
  numberLinkTitle: {
    textDecoration: "none",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    height: "40px",
    margin: "0 0 10px auto",
    borderRadius: "20px",
    lineHeight: "1.2",
    "&:hover, &:focus": {
      backgroundColor: "rgba(213,72,72,1)",
    },
  },
  flex: {
    display: "flex",
  },
  buttonEmoji: {
    borderRadius: "5px",
    width: "30px",
    minWidth: "30px",
    height: "30px",
    backgroundColor: "rgba(236,98,98,1)",
    color: "rgba(255,255,255,1)",
    marginBottom: "10px",
  },
  buttonEmojiClicked: {
    borderRadius: "5px",
    width: "30px",
    minWidth: "30px",
    height: "30px",
    marginBottom: "5px",
    backgroundColor: "rgba(255,255,255,1)",
    color: "rgba(236,98,98,1)",
    border: "1px solid",
    borderColor: "rgba(236,98,98,1)",
  },
  imageEmoji: {
    width: "20px",
  },
  textButton: {
    fontSize: "14px",
  },
  iconButton: {
    marginRight: "5px",
  },
  typographyWriteText: {
    marginTop: "10px",
  },
  writePostTitle: {
    lineHeight: "1.2",
  },
  mediaUpdate: {
    borderRadius: "5px",
    objectFit: "cover",
    maxHeight: "35px",
    margin: "0 5px",
  },
  buttonDelete: {
    color: "rgba(236,98,98,1)",
    minWidth: "30px",
    padding: "3px",
  },
  quillBox: {
    display: "flex",
  },
  quillWrap: {
    width: "100%",
  },
  buttonsFlex: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
  },
}));
