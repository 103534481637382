import React from "react";
import { Typography, Button, Box, Hidden } from "@material-ui/core";
import {
	translateDayToRussian,
	translatePlaceTypePrepositionalCase,
} from "../../../../utils/placeUtils.js";
import useStyles from "./styles.js";

const PlaceInformation = ({
	place,
	averageHotelRate,
	averageCheck,
	averageTicketAdult,
	averageTicketChild,
}) => {
	const classes = useStyles();

	const formatWorkingHours = (day) => {
		const hours = place?.workingHours?.[day];
		if (hours && hours[0] === "dayoff" && hours[1] === "dayoff") {
			return "Выходной";
		}
		return hours ? `${hours[0]} - ${hours[1]}` : "Не указано";
	};

	const hasNonEmptyHours = (workingHours) => {
		return Object.values(workingHours).some((hours) => hours.length > 0);
	};

	return (
		<div>
			{/* Address */}
			{place?.address && (
				<div>
					<div className={classes.flex}>
						<img
							width="24"
							height="24"
							src="https://img.icons8.com/sf-regular/ec6262/96/address.png"
							alt="address"
						/>
						<Typography className={classes.descriptionTitle}>Адрес</Typography>
					</div>
					<Typography>{place.address}</Typography>
				</div>
			)}

			{/* Contact details */}
			{place?.phone || place?.website ? (
				<div>
					<div className={classes.flex}>
						<img
							width="24"
							height="24"
							src="https://img.icons8.com/sf-regular/ec6262/96/phone.png"
							alt="phone"
						/>
						<Typography className={classes.descriptionTitle}>
							Контакты
						</Typography>
					</div>
				</div>
			) : null}

			<div className={classes.contactsBox}>
				{place?.phone && <Typography>Телефон: {place.phone}</Typography>}
				{place?.phone && place?.website ? (
					<Hidden xsDown>
						<Typography className={classes.contactsDivider}>|</Typography>
					</Hidden>
				) : null}
				{place?.website && (
					<Typography>
						Вебсайт:{" "}
						<a
							href={
								place.website.startsWith("http://") ||
								place.website.startsWith("https://")
									? place.website
									: `http://${place.website}`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							{place.website}
						</a>
					</Typography>
				)}
			</div>

			{/* Working hours */}
			{place?.workingHours &&
				place?.workingHours.monday.length !== 0 &&
				hasNonEmptyHours(place?.workingHours) && (
					<Box>
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/clock.png"
								alt="phone"
							/>
							<Typography className={classes.descriptionTitle}>
								Часы работы:
							</Typography>
						</div>
						{Object.keys(place.workingHours).map((day) => (
							<div className={classes.workingHoursBox}>
								<Typography key={day}>
									{translateDayToRussian(day).charAt(0).toUpperCase() +
										translateDayToRussian(day).slice(1)}
								</Typography>
								<Typography key={day}>{formatWorkingHours(day)}</Typography>
							</div>
						))}
					</Box>
				)}

			{/* Hotel Rate */}
			{place?.placeType === "hotel" && averageHotelRate > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена за ночь: {Math.round(averageHotelRate)} ₽
					</Typography>
				</div>
			)}

			{/* Restaurant Details */}
			{place?.placeType === "restaurant" && (
				<>
					{averageCheck > 0 || place?.paymentMethod ? (
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/price-tag.png"
								alt="price-tag"
							/>
							<Typography className={classes.descriptionTitle}>
								Цены и оплата
							</Typography>
						</div>
					) : null}
					{averageCheck > 0 && (
						<div className={classes.ratingBox}>
							<Typography className={classes.descriptionTextBefore}>
								Средний чек на человека:
							</Typography>
							<Typography>{Math.round(averageCheck)} ₽</Typography>
						</div>
					)}
					{/* Payment method */}
					{place?.paymentMethod && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Способ оплаты:{" "}
							</Typography>
							<Typography>{place.paymentMethod}</Typography>
						</div>
					)}
					{/* Cuisine */}
					{place?.cuisine?.length > 0 && (
						<div className={classes.flex}>
							<img
								width="24"
								height="24"
								src="https://img.icons8.com/sf-regular/ec6262/96/plus.png"
								alt="plus"
							/>
							<Typography className={classes.descriptionTitle}>
								Особенности
							</Typography>
						</div>
					)}
					{place?.cuisine?.length > 0 && (
						<div className={classes.flexWrap}>
							<Typography className={classes.descriptionTextBefore}>
								Кухня:{" "}
							</Typography>
							<Typography>{place.cuisine.join(", ")}</Typography>
						</div>
					)}
				</>
			)}

			{/* Museum Ticket Prices */}
			{place?.placeType === "museum" && averageTicketAdult > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена взрослого билета: {Math.round(averageTicketAdult)} ₽
					</Typography>
				</div>
			)}

			{place?.placeType === "museum" && averageTicketChild > 0 && (
				<div className={classes.ratingBox}>
					<Typography>
						Средняя цена льготного билета: {Math.round(averageTicketChild)} ₽
					</Typography>
				</div>
			)}
		</div>
	);
};

export default PlaceInformation;
