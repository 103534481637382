import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { addPlaceDescription } from "../../../../actions/placeDescription";
import { useDispatch, useSelector } from "react-redux";

const SuggestPlace = ({
	place,
	userId,
	classes,
	isSuggestedText,
	setIsSuggestedText,
}) => {
	const dispatch = useDispatch();
	const [suggestedText, setSuggestedText] = useState("");
	const description = {
		placeId: place?._id,
		description: suggestedText,
		userId: userId,
	};
	const dispatchResult = useSelector((state) => state.place.boolean);

	const handleTextChange = (event) => {
		setSuggestedText(event.target.value);
	};

	const handleTextSubmit = async (event) => {
		try {
			await dispatch(addPlaceDescription(description));
			setIsSuggestedText((prevSuggest) => !prevSuggest);
		} catch (error) {
			console.error("Error occurred while dispatching:", error);
		}
	};

	return (
		<>
			<TextField
				label="Предложите описание"
				variant="outlined"
				value={suggestedText}
				onChange={handleTextChange}
				multiline // Allow multiple lines
				rows={4} // Set number of rows
				fullWidth
				margin="normal"
				inputProps={{ maxLength: 2000 }} // Limit to 2000 characters
			/>
			<Button className={classes.buttonPrimary} onClick={handleTextSubmit}>
				Отправить
			</Button>
		</>
	);
};

export default SuggestPlace;
