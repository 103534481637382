import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	Typography,
	Button,
	CircularProgress,
	Box,
	Grid,
} from "@material-ui/core";
import { findPlacesByCity } from "../../../../actions/places";
import PlacesInCityList from "./PlacesInCityList/PlacesInCityList";

const PlaceCityToDo = ({ place }) => {
	const dispatch = useDispatch();
	const placesInCity = useSelector((state) => state.place.placesByCity);
	const museums = placesInCity
		? placesInCity.filter((place) => place.placeType === "museum")
		: null;
	const restaurants = placesInCity
		? placesInCity.filter((place) => place.placeType === "restaurant")
		: null;
	const sites = placesInCity
		? placesInCity.filter((place) => place.placeType === "site")
		: null;

	useEffect(() => {
		dispatch(findPlacesByCity(place?._id, 1, "museum"));
		dispatch(findPlacesByCity(place?._id, 1, "restaurant"));
		dispatch(findPlacesByCity(place?._id, 1, "site"));
		//dispatch(findPlacesByCity(place?._id, 1, "hotel"));
	}, []);
	console.log(placesInCity);

	return (
		<>
			<Typography variant="h6">
				Что посетить в городе {place?.placeName}?
			</Typography>
			<Grid container spacing={2}>
				<PlacesInCityList
					placesType="museum"
					placesForList={museums}
					place={place}
				/>
				<PlacesInCityList
					placesType="restaurant"
					placesForList={restaurants}
					place={place}
				/>
				<PlacesInCityList
					placesType="site"
					placesForList={sites}
					place={place}
				/>
			</Grid>
		</>
	);
};

export default PlaceCityToDo;
