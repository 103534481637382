import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	appBar: {
		borderRadius: 15,
		margin: "20px 0",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "5px 30px",
		"@media (max-width: 600px)": {
			padding: "5px 15px",
			margin: "10px 0",
		},
	},
	heading_black: {
		color: "rgba(0,0,0,1)",
		textDecoration: "none",
	},
	heading: {
		color: "rgba(236,98,98,1)",
		textDecoration: "none",
	},

	image: {
		marginLeft: "10px",
		"@media (max-width: 600px)": {
			marginLeft: "0px",
		},
	},
	toolbar: {
		display: "flex",
		justifyContent: "flex-end",
		width: "400px",
		paddingRight: "0px",
		"@media (max-width: 600px)": {
			paddingLeft: "8px",
		},
	},
	profile: {
		display: "flex",
		justifyContent: "space-between",
	},
	notificationLink: {
		color: "rgba(236,98,98,1)",
		display: "flex",
		alignItems: "center",
		textDecoration: "none",
		position: "relative",
		margin: "0 20px",
		"@media (max-width: 900px)": {
			margin: "0 5px",
		},
	},
	notificationBox: {
		backgroundColor: "rgba(236,98,98,1)",
		minWidth: "16px",
		display: "flex",
		justifyContent: "center",
		border: "1px solid",
		borderColor: "rgb(256,256,256)",
		borderRadius: "8px",
		position: "absolute",
		top: "2px",
		left: "-3px",
	},
	notificationNumber: {
		fontSize: "12px",
		padding: "0 2px",
		color: "rgba(256,256,256,1)",
	},
	profileLink: {
		textDecoration: "none",
	},
	userName: {
		fontWeight: 700,
		lineHeight: 1,
	},
	userLogin: {
		lineHeight: 1,
	},
	brandContainer: {
		display: "flex",
		alignItems: "center",
		textDecoration: "none",
	},
	buttonPrimary: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		marginLeft: "5px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "rgba(70,70,70,1)",
		color: "rgba(255,255,255,1)",
		marginLeft: "5px",
		padding: "0 30px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(100,100,100,1)",
		},
		"@media (max-width: 600px)": {
			padding: "0 15px",
		},
	},
	nameBox: {
		height: "40px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 15px",
		margin: "0px 20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		textDecoration: "none",
		boxSizing: "content-box",
	},
	nameBoxSpecial: {
		height: "40px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 15px",
		margin: "0px 20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		marginRight: "5px",
	},
	purpleSpecial: {
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		marginRight: "5px",
	},
	menuIconButton: {
		padding: "0px",
		color: "rgba(236,98,98,1)",
		marginLeft: "10px",
		"@media (max-width: 600px)": {
			marginLeft: "5px",
		},
	},
}));
