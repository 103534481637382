import { ADD_ALERT, DELETE_ALL_ALERTS } from "../constants/actionTypes";

const adminReducer = (state = { alert: null }, action) => {
	switch (action.type) {
		case ADD_ALERT:
			return { ...state, alert: action.payload };
		case DELETE_ALL_ALERTS:
			return { ...state, alert: null };
		default:
			return state;
	}
};

export default adminReducer;
