import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	Typography,
	Button,
	CardMedia,
	Grid,
	TextField,
	Select,
	MenuItem,
	Card,
} from "@material-ui/core";
import useStyles from "./styles.js";
import Resizer from "react-image-file-resizer";
import PublishIcon from "@material-ui/icons/Publish";
import { Convert } from "easy-currencies";
import {
	addYandexPlaceReview,
	createPlaceReview,
} from "../../../../../actions/placeReview.js";

const PlaceReviewWrite = ({ place, user, setIsWriteReview }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [editFormData, setEditFormData] = useState();
	const [uploadedImages, setUploadedImages] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [placeRate, setPlaceRate] = useState("");

	const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);

	// New state variables
	const [spentAmount, setSpentAmount] = useState("");
	const [spentTime, setSpentTime] = useState("");
	const [numberOfGuests, setNumberOfGuests] = useState("");
	const [numberOfNights, setNumberOfNights] = useState("");
	const [visitDate, setVisitDate] = useState("");
	const [currency, setCurrency] = useState("");
	const [roomType, setRoomType] = useState("");
	const [spentAmountRub, setSpentAmountRub] = useState("");
	const [ticketType, setTicketType] = useState("");

	async function convertCurrency(cur) {
		try {
			let value;
			(spentAmount > 0 && currency !== "Other") || ""
				? (value = await Convert(spentAmount).from(cur).to("RUB"))
				: (value = "");
			setSpentAmountRub(() => value);
			setEditFormData((prevData) => ({
				...prevData,
				spentAmountRub: value,
			}));
		} catch (error) {
			console.error("Error converting currency:", error);
		}
	}

	useEffect(() => {
		setEditFormData(place ? { placeId: place._id } : null);
	}, [place]);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				70,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const handleEditChange = (e) => {
		setEditFormData({
			...editFormData,
			[e.target.name]: e.target.value,
		});
	};

	const handleFileChange = async (event) => {
		try {
			const files = Array.from(event.target.files);
			if (files.length + uploadedImages.length > 10) {
				alert("Вы можете загрузить до 10 фотографий.");
				return;
			}

			const newImages = await Promise.all(
				files.map(async (file) => {
					if (file.size > 10000000) {
						alert("Выберите файл поменьше.");
						return null;
					} else {
						const resizedImage = await resizeFile(file);
						const uploadedImage = await dispatch(
							addYandexPlaceReview(resizedImage)
						);
						return uploadedImage;
					}
				})
			);

			const validNewImages = newImages.filter((image) => image !== null);
			setUploadedImages([...uploadedImages, ...validNewImages]);
			setFileNames([...fileNames, ...files.map((file) => file.name)]);

			setEditFormData((prevData) => ({
				...prevData,
				images: [...(prevData?.images || []), ...validNewImages],
			}));
		} catch (err) {
			console.log(err);
		}
	};

	const handleSubmitForb = (e) => {
		e.preventDefault();
		setEditFormData((prevData) => ({
			...prevData,
			placeRate: placeRate,
			visitDate: visitDate,
			spentAmount: spentAmount,
			numberOfGuests: numberOfGuests,
			numberOfNights: numberOfNights,
			currency: currency,
			roomType: roomType,
			spentAmountRub: spentAmountRub,
			spentTime: spentTime,
			ticketType: ticketType,
		}));
		console.log(editFormData);
		dispatch(createPlaceReview(editFormData));
		setIsWriteReview((prevIs) => !prevIs);
	};

	return (
		<form className={classes.form} onSubmit={handleSubmitForb}>
			<TextField
				name="text"
				variant="outlined"
				label="Написать отзыв"
				fullWidth
				multiline
				maxRows={6}
				inputProps={{ maxLength: 2000 }}
				value={editFormData?.text || ""}
				onChange={handleEditChange}
				className={classes.textInputBox}
			/>
			<Select
				value={placeRate}
				onChange={(e) => {
					setPlaceRate(e.target.value);
					setEditFormData((prevData) => ({
						...prevData,
						placeRate: e.target.value,
					}));
				}}
				displayEmpty
				variant="outlined"
				className={`${classes.select} custom-select`}
			>
				<MenuItem value="">Поставьте оценку</MenuItem>
				<MenuItem value={1}>1 *</MenuItem>
				<MenuItem value={2}>2 **</MenuItem>
				<MenuItem value={3}>3 ***</MenuItem>
				<MenuItem value={4}>4 ****</MenuItem>
				<MenuItem value={5}>5 *****</MenuItem>
			</Select>

			{place &&
				(place?.placeType === "restaurant" ||
					place?.placeType === "hotel" ||
					place?.placeType === "museum") && (
					<Button
						className={classes.buttonPrimaryNoLimit}
						onClick={() => {
							setIsAdditionalInfo((prev) => !prev);
						}}
					>
						Добавить дополнительную информацию
					</Button>
				)}

			{isAdditionalInfo ? (
				<>
					{place?.placeType === "museum" && (
						<>
							<TextField
								name="spentAmount"
								variant="outlined"
								label="Сколько стоил билет?"
								fullWidth
								value={spentAmount}
								onChange={(e) => {
									const moneyValue = e.target.value;
									setSpentAmount(moneyValue);
									setEditFormData((prevData) => ({
										...prevData,
										spentAmount: moneyValue,
									}));
								}}
								className={classes.textInputBox}
							/>
							<Select
								value={currency}
								onChange={(e) => {
									setCurrency(e.target.value);
									setEditFormData((prevData) => ({
										...prevData,
										currency: e.target.value,
									}));
									convertCurrency(e.target.value);
								}}
								displayEmpty
								variant="outlined"
								className={`${classes.select} custom-select`}
							>
								<MenuItem value="">Выберите валюту</MenuItem>
								<MenuItem value="RUB">Рубли</MenuItem>
								<MenuItem value="USD">Доллары США</MenuItem>
								<MenuItem value="EUR">Евро</MenuItem>
								<MenuItem value="CNY">Китайские юани</MenuItem>
								<MenuItem value="Other">Другая валюта</MenuItem>
							</Select>
							<Select
								value={ticketType}
								onChange={(e) => {
									setTicketType(e.target.value);
									setEditFormData((prevData) => ({
										...prevData,
										ticketType: e.target.value,
									}));
								}}
								displayEmpty
								variant="outlined"
								className={`${classes.select} custom-select`}
							>
								<MenuItem value="">Выберите тип билета</MenuItem>
								<MenuItem value="adult">Взрослый</MenuItem>
								<MenuItem value="child">Льготный</MenuItem>
							</Select>
							<TextField
								name="spentAmount"
								variant="outlined"
								label="Сколько часов вы пробыли в музее?"
								fullWidth
								value={spentTime}
								onChange={(e) => {
									const timeValue = e.target.value;
									setSpentTime(timeValue);
									setEditFormData((prevData) => ({
										...prevData,
										spentTime: timeValue,
									}));
								}}
								className={classes.textInputBox}
							/>
						</>
					)}
					{place &&
						(place?.placeType === "restaurant" ||
							place?.placeType === "hotel") && (
							<TextField
								name="visitDate"
								label={
									place?.placeType === "restaurant"
										? "Дата посещения"
										: "Дата заселения"
								}
								type="date"
								value={visitDate || ""}
								onChange={(e) => {
									const newValue = e.target.value;
									setVisitDate(newValue);
									setEditFormData((prevData) => ({
										...prevData,
										visitDate: newValue,
									}));
								}}
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								fullWidth
								className={classes.textInputBox}
							/>
						)}

					{place?.placeType === "restaurant" && (
						<>
							<TextField
								name="spentAmount"
								variant="outlined"
								label="Сколько вы потратили денег?"
								fullWidth
								value={spentAmount}
								onChange={(e) => {
									const moneyValue = e.target.value;
									setSpentAmount(moneyValue);
									setEditFormData((prevData) => ({
										...prevData,
										spentAmount: moneyValue,
									}));
								}}
								className={classes.textInputBox}
							/>
							<Select
								value={currency}
								onChange={(e) => {
									setCurrency(e.target.value);
									setEditFormData((prevData) => ({
										...prevData,
										currency: e.target.value,
									}));
									convertCurrency(e.target.value);
								}}
								displayEmpty
								variant="outlined"
								className={`${classes.select} custom-select`}
							>
								<MenuItem value="">Выберите валюту</MenuItem>
								<MenuItem value="RUB">Рубли</MenuItem>
								<MenuItem value="USD">Доллары США</MenuItem>
								<MenuItem value="EUR">Евро</MenuItem>
								<MenuItem value="CNY">Китайские юани</MenuItem>
								<MenuItem value="Other">Другая валюта</MenuItem>
							</Select>
							<TextField
								name="numberOfGuests"
								variant="outlined"
								label="Сколько было гостей?"
								fullWidth
								value={numberOfGuests}
								onChange={(e) => {
									const guestsValue = e.target.value;
									setNumberOfGuests(guestsValue);
									setEditFormData((prevData) => ({
										...prevData,
										numberOfGuests: guestsValue,
									}));
								}}
								className={classes.textInputBox}
							/>
						</>
					)}

					{place?.placeType === "hotel" && (
						<>
							<TextField
								name="spentAmount"
								variant="outlined"
								label="Сколько стоило проживание?"
								fullWidth
								value={spentAmount}
								onChange={(e) => {
									const moneyValue = e.target.value;
									setSpentAmount(moneyValue);
									setEditFormData((prevData) => ({
										...prevData,
										spentAmount: moneyValue,
									}));
								}}
								className={classes.textInputBox}
							/>
							<Select
								value={currency}
								onChange={(e) => {
									setCurrency(e.target.value);
									setEditFormData((prevData) => ({
										...prevData,
										currency: e.target.value,
									}));
									convertCurrency(e.target.value);
								}}
								displayEmpty
								variant="outlined"
								className={`${classes.select} custom-select`}
							>
								<MenuItem value="">Выберите валюту</MenuItem>
								<MenuItem value="RUB">Рубли</MenuItem>
								<MenuItem value="USD">Доллары США</MenuItem>
								<MenuItem value="EUR">Евро</MenuItem>
								<MenuItem value="CNY">Китайские юани</MenuItem>
								<MenuItem value="Other">Другая валюта</MenuItem>
							</Select>
							<TextField
								name="numberOfGuests"
								variant="outlined"
								label="Сколько было гостей?"
								fullWidth
								value={numberOfGuests}
								onChange={(e) => {
									const guestsValue = e.target.value;
									setNumberOfGuests(guestsValue);
									setEditFormData((prevData) => ({
										...prevData,
										numberOfGuests: guestsValue,
									}));
								}}
								className={classes.textInputBox}
							/>
							<TextField
								name="numberOfNights"
								variant="outlined"
								label="На сколько ночей вы бронировали отель?"
								fullWidth
								value={numberOfNights}
								onChange={(e) => {
									const nightsValue = e.target.value;
									setNumberOfNights(nightsValue);
									setEditFormData((prevData) => ({
										...prevData,
										numberOfNights: nightsValue,
									}));
								}}
								className={classes.textInputBox}
							/>
							<Select
								value={roomType}
								onChange={(e) => {
									setRoomType(e.target.value);
									setEditFormData((prevData) => ({
										...prevData,
										roomType: e.target.value,
									}));
								}}
								displayEmpty
								variant="outlined"
								className={`${classes.select} custom-select`}
							>
								<MenuItem value="">Выберите тип номера</MenuItem>
								<MenuItem value="single">Одноместный номер</MenuItem>
								<MenuItem value="double">Двухместный номер</MenuItem>
								<MenuItem value="family">Семейный номер</MenuItem>
								<MenuItem value="suit">Люкс</MenuItem>
								<MenuItem value="Other">Другой тип</MenuItem>
							</Select>
						</>
					)}
				</>
			) : null}

			<div className={classes.fileInput}>
				<label className={classes.labelInput}>
					<input
						type="file"
						className={classes.imageInput}
						multiple
						onChange={handleFileChange}
					/>
					<PublishIcon />
					<Typography className={classes.uploadText}>ЗАГРУЗИТЬ ФОТО</Typography>
				</label>
			</div>
			<Grid container spacing={1} className={classes.imagePreviewContainer}>
				{uploadedImages.map((image, index) => (
					<Grid item key={index}>
						<Card>
							<CardMedia
								component="img"
								className={classes.imagePreview}
								alt={`uploaded ${index}`}
								image={image}
								title={`uploaded ${index}`}
							/>
						</Card>
					</Grid>
				))}
			</Grid>
			<Button
				className={classes.buttonPrimary}
				type="submit"
				variant="contained"
				fullWidth
			>
				Опубликовать отзыв
			</Button>
		</form>
	);
};

export default PlaceReviewWrite;
