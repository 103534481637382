import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	cardFirst: {
		minHeight: "81vh",
		background:
			"linear-gradient(90deg, rgba(236,98,98,1) 0%, rgba(9,9,121,1) 100%, rgba(0,212,255,1) 100%)",
		padding: "1rem",
		justifyContent: "center",
		"@media (max-width: 600px)": {
			padding: "0.5rem",
			flexDirection: "column",
		},
	},
	cardSecond: {
		minHeight: "10rem",
		background: "rgb(236,98,98)",
		width: "100%",
		textAlign: "left",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	cardThird: {
		transition: "0.3s",
		minHeight: "5rem",
		background: "rgb(255,255,255)",
		width: "80%",
		"&:hover p": {
			color: "rgb(255,255,255)",
		},
		"&:hover, &:focus": {
			backgroundColor: "rgba(5,5,80,1)",
		},
		"@media (max-width: 600px)": {
			width: "90%",
		},
	},
	card: {
		display: "flex",
		alignItems: "left",
		borderRadius: "15px",
		position: "relative",
		color: "rgba(255,255,255,1)",
	},
	cardLoading: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		borderRadius: "15px",
		height: "50vh",
		position: "relative",
		alignItems: "center",
	},
	gridContainer: {
		marginTop: "18px",
		"@media (max-width: 600px)": {
			marginTop: "3px",
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
		},
	},
	profilePicture: {
		margin: "20px 0px",
		width: "150px",
		height: "150px",
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		fontSize: "3.5rem",
		"@media (max-width: 600px)": {
			width: "100px",
			height: "100px",
			fontSize: "2.5rem",
		},
	},
	welcome: {
		display: "flex",
		flexDirection: "column",
		alignItems: "right",
		justifyContent: "center",
		marginLeft: "1rem",
		width: "50%",
		"@media (max-width: 600px)": {
			width: "100%",
			alignItems: "center",
			textAlign: "center",
			margin: "0",
		},
	},
	welcomeTitle: {
		fontSize: "2rem",
		fontWeight: "bold",
		"@media (max-width: 600px)": {
			fontSize: "1.5rem",
			paddingBottom: "1rem",
			paddingTop: "1rem",
		},
		paddingTop: "2rem",
		paddingBottom: "2rem",
	},
	welcomeText: {
		fontSize: "1.4rem",
		"@media (max-width: 600px)": {
			fontSize: "1.1rem",
			paddingBottom: "1rem",
		},
		paddingBottom: "2rem",
	},
	samplePost: {
		width: "40%",
		margin: "auto 5%",
		"@media (max-width: 600px)": {
			width: "90%",
			margin: "5%",
		},
	},
	samplePostCard: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		borderRadius: "15px",
		height: "100%",
	},
	titleSupport: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		color: "rgb(0,0,0)",
		fontSize: "1.3rem",
		fontWeight: "bold",
		textAlign: "center",
		transition: "0.3s",
		"@media (max-width: 600px)": {
			fontSize: "1.1rem",
		},
	},
	divider: {
		backgroundColor: "rgba(0, 0, 0, 1)",
		border: "none",
		height: "1px",
		width: "50%",
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px 0",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	betaTest: {
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		padding: "15px",
		marginBottom: "20px",
		display: "flex",
		alignItems: "center",
		color: "white",
		"@media (max-width: 600px)": {
			marginBottom: "10px",
			width: "100%",
		},
	},
	betaTestButton: {
		color: "rgba(236,98,98,1)",
		margin: "0 0 0 auto",
		minWidth: "30px",
	},
	telegramBotLink: {
		textDecoration: "none",
		color: "rgb(0,0,0)",
		fontWeight: "700",
	},
	icons8: {
		color: "black",
	},
}));
