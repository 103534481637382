import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	image: {
		maxWidth: "100%",
		maxHeight: "100%",
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
	},
	addButton: {
		marginTop: theme.spacing(2),
	},
}));

const PlaceImages = () => {
	const classes = useStyles();

	// Initialize images array
	const [images, setImages] = React.useState([]);

	// Function to handle adding an image
	const handleAddImage = () => {
		// For demonstration, I'm just adding a placeholder URL
		const newImage = "https://via.placeholder.com/150";
		setImages((prevImages) => [...prevImages, newImage]);
	};

	// Generate image grid based on the number of images
	const renderImagesGrid = () => {
		const imageCount = images.length;

		switch (imageCount) {
			case 0:
				return (
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="primary"
								className={classes.addButton}
								startIcon={<AddIcon />}
								onClick={handleAddImage}
								fullWidth
							>
								Add Image
							</Button>
						</Grid>
						<Grid item xs={6} />
						<Grid item xs={6} />
						<Grid item xs={6} />
					</Grid>
				);
			case 1:
				return (
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<img src={images[0]} alt="Image 1" className={classes.image} />
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="primary"
								className={classes.addButton}
								startIcon={<AddIcon />}
								onClick={handleAddImage}
								fullWidth
							>
								Add Image
							</Button>
						</Grid>
						<Grid item xs={6} />
						<Grid item xs={6} />
					</Grid>
				);
			case 2:
			case 3:
			case 4:
				return (
					<Grid container spacing={1}>
						{images.map((image, index) => (
							<Grid item xs={6} key={index}>
								<img
									src={image}
									alt={`Image ${index + 1}`}
									className={classes.image}
								/>
							</Grid>
						))}
						{imageCount < 4 && (
							<Grid item xs={6}>
								<Button
									variant="outlined"
									color="primary"
									className={classes.addButton}
									startIcon={<AddIcon />}
									onClick={handleAddImage}
									fullWidth
								>
									Add Image
								</Button>
							</Grid>
						)}
					</Grid>
				);
			default:
				return null;
		}
	};

	return <div className={classes.root}>{renderImagesGrid()}</div>;
};

export default PlaceImages;
